import { SearchNormal1 } from "iconsax-react"
import { Helmet } from "react-helmet"
import { GHButton } from "../components/GHSystem"
import PhotoSection from "../components/PhotoSection"
import PhotoSearchResult from "../components/partials/Photo/PhotoSearchResult"
import CollectionSection from "../components/partials/Photo/CollectionSection"
import { useLoaderData, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import Layout from "../components/layout"
import axios from "axios"
import { SkeletonAllPhoto } from "../components/partials/Photo/SkeletonAllPhoto"

export const PhotoListLoader = async ({ request }) => {
  try {
    const reqUrl = new URL(request.url)
    const search = reqUrl.searchParams.get("search")
    const reqArray = [
      await axios.get(`${process.env.REACT_APP_BASE_URL}/apps/photos/event`, {
        timeout: 2000,
      }),
    ]
    if (search) {
      reqArray.push(
        await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/apps/photos/all?${reqUrl.searchParams.toString()}`,
          {
            timeout: 2000,
          }
        )
      )
    }
    const result = await Promise.all(reqArray)
    return {
      photos: result[0].data.data.row,
      next: result[0].data.data.next,
      ...(result[1]
        ? { searchResult: result[1].data.data }
        : {
            searchResult: {
              total: 0,
              current_page: 1,
              page_size: 20,
              last_page: 0,
              order_by: [["id", "asc"]],
              row: [],
            },
          }),
    }
  } catch (error) {
    return {
      photos: [],
      next: null,
      searchResult: {
        total: 0,
        current_page: 1,
        page_size: 20,
        last_page: 0,
        order_by: [["id", "asc"]],
        row: [],
      },
    }
  }
}

export const PhotoListPage = () => {
  const loaderData = useLoaderData()
  const { photos, next, searchResult } = loaderData
  const [search, setSearch] = useState(undefined)
  const [searchParams, setSearchParams] = useSearchParams()
  const [infiniteData, setInfiniteData] = useState([])
  const [infiniteSearchData, setInfiniteSearchData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [hasMore, setHasMore] = useState(null)
  const [hasMoreSearch, setHasMoreSearch] = useState(true)
  const [isSkeleton, setIsSkeleton] = useState(true)

  useEffect(() => {
    setInfiniteData(photos)
    setHasMore(next)
    if (photos) {
      setIsSkeleton(false)
    }
  }, [])
  useEffect(() => {
    setInfiniteSearchData(searchResult.row)
    setHasMoreSearch(searchResult.next)
  }, [searchParams])
  const onSearch = () => {
    if (search && search !== "") {
      setSearchParams((prev) => {
        prev.set("search", search)
        return prev
      })
    } else {
      setSearch(undefined)
      setSearchParams((prev) => {
        prev.delete("search")
        return prev
      })
    }
  }
  const fetchNext = async () => {
    const search = new URLSearchParams(hasMore)
    const photos = await axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/apps/photos/event?${search.toString()}`,
        {
          timeout: 2000,
        }
      )
      .catch((err) => err)
    if (photos.data) {
      setInfiniteData((old) => [...old, ...photos.data.data.row])
      setHasMore(photos.data.data.next)
    } else {
      setHasMore(false)
    }
  }
  const fetchNextSearch = async () => {
    const params = new URLSearchParams(hasMoreSearch)
    const photos = await axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/apps/photos/all?${params.toString()}`,
        {
          timeout: 2000,
        }
      )
      .catch((err) => err)
    if (photos.data) {
      setInfiniteSearchData((old) => [...old, ...photos.data.data.row])
      setHasMoreSearch(photos.data.data.next)
    } else {
      setHasMoreSearch(photos.data.data.next)
    }
  }

  return (
    <Layout>
      <div className="min-h-[70vh] w-full flex flex-col items-center">
        <Helmet>
          <title>Photos | Gue Hadir</title>
          <meta name="description" content={`Gue Hadir Photos`} />
          <meta property="og:title" content="Photos | Gue Hadir" />
          <meta
            property="og:description"
            content="guehadir.id memberikan pengalaman berbeda saat melakukan pembelian tiket. Selalu ada keuntungan di setiap pembeliannya"
          />
          <meta
            property="og:image"
            content="https://backend.guehadir.id/images/banner1.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Gue Hadir" />
          <meta property="og:url" content="https://guehadir.id" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="guehadir.id" />
          <meta property="twitter:url" content="https://guehadir.id" />
          <meta name="twitter:title" content="Photos | Gue Hadir" />
          <meta
            name="twitter:description"
            content="guehadir.id memberikan pengalaman berbeda saat melakukan pembelian tiket. Selalu ada keuntungan di setiap pembeliannya"
          />
          <meta
            name="twitter:image"
            content="https://backend.guehadir.id/images/banner1.png"
          />
        </Helmet>
        <div className="container py-[19px] space-y-8 px-4 lg:px-0">
          {}
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSearch()
            }}
            className="rounded-[6px] shadow-[3px_8px_19px_rgba(0,0,0,0.44)] p-[9.5px] lg:p-[20px] bg-background lg:bg-gh-black-200 w-full flex items-center space-x-3"
          >
            <SearchNormal1 size="14" className="text-secondary" />
            <input
              className="flex-1 bg-transparent text-secondary focus-visible:outline-none"
              placeholder="Search Jakarta Concert Week 2023"
              type="search"
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <GHButton className="hidden lg:block" onClick={onSearch}>
              Search
            </GHButton>
          </form>
          {isSkeleton ? (
            <SkeletonAllPhoto />
          ) : (
            <>
              {searchParams.get("search") && (
                <PhotoSearchResult
                  searchResult={infiniteSearchData}
                  search={JSON.stringify(searchParams.get("search"))}
                  hasMore={hasMoreSearch}
                  fetchNext={fetchNextSearch}
                />
              )}
              <CollectionSection />
              {infiniteData.map((photo, index) => {
                if (photo.photos.count <= 0) {
                  return null
                }
                return (
                  <PhotoSection
                    key={index}
                    photos={photo.photos.rows}
                    count={photo.photos.count}
                    leftText={photo.title}
                    rightText={"Lihat Semua Photo"}
                    redirectUrl={`/photos/${photo.id}/event`}
                  />
                )
              })}
              {hasMore && (
                <div className="flex justify-center">
                  <GHButton onClick={fetchNext}>LOAD MORE</GHButton>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}
