import { useStoreState } from "easy-peasy";

const Popover = ({ tag, className, children }) => {
  const popover = useStoreState((state) => state.popover);
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`${className ?? ""} ${tag === popover ? "" : "hidden"}`}
    >
      {children}
    </div>
  );
};

export default Popover;
