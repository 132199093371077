const getAxiosConfig = (withToken) => {
    const token = localStorage.getItem("token") || "";

    return {
        timeout: 2000,
        headers: {
            ...(withToken ? {Authorization: `Bearer ${token}`} : {}),
        },
    }
}

export default getAxiosConfig