/* eslint-disable react-hooks/exhaustive-deps */
import PhotoThumbnail from "../components/PhotoThumbnail"
import Layout from "../components/layout"
import { formatRupiah } from "../utils"
import { json, useLoaderData, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { useStoreActions, useStoreState } from "easy-peasy"
import { GHButton } from "../components/GHSystem"
import { toast } from "react-toastify"
import getPaymentMethodImage from "../utils/getPaymentMethodImage"
import { Add, ArrowLeft2, ArrowRight2, Image, Maximize4 } from "iconsax-react"

import { ArrowDown2 } from "iconsax-react"
import {
  DetailPhotoSection1,
  DetailPhotoSection2,
  DetailPhotoSection3,
  DetailPhotoSection4,
} from "../components/partials"

export const DetailPhotoLoader = async ({ request, params }) => {
  let photo_id = params.id
  try {
    const photo = await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/apps/photos?photo_id=${photo_id}`,
        {
          timeout: 2000,
        }
      )
      .catch((err) => err)
    if (!photo.data) {
      throw Error("failed to get photo data")
    }
    let tags = photo.data.data.photo.photo_tags.map((tags) => [
      "tags[]",
      tags.tag.name,
    ])
    let queryString = new URLSearchParams(tags)
    queryString.append("current_id", params.id)
    const similar = await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/apps/photos/similar?${queryString}`,
        {
          timeout: 2000,
        }
      )
      .catch((err) => err)
    if (!similar.data) {
      throw Error("failed to get similar photo data")
    }
    return {
      photo: photo.data.data.photo,
      next: null,
      similar: similar.data.data,
      currentId: Number(photo_id),
    }
  } catch (error) {
    throw json(
      {
        message: "Photo tidak ditemukan",
        redirectURL: "/photos",
      },
      { status: 404 }
    )
    // return {
    //   photo: {
    //     url_watermark: null,
    //     price: 0,
    //   },
    //   next: null,
    //   similar: {
    //     count: 0,
    //     row: [],
    //     next: null,
    //   },
    //   currentId: Number(photo_id),
    // };
  }
}

const BankImage = ({ bank }) => {
  const paymentMethod = useStoreState((state) => state.paymentMethod)
  const setPaymentMethod = useStoreActions((action) => action.setPaymentMethod)
  return (
    <img
      className={`h-[3rem] w-[8rem] object-cover cursor-pointer rounded-lg overflow-hidden box-border ${
        paymentMethod.bank_code === bank.bank_code
          ? "border-4 border-primary"
          : ""
      }`}
      id={bank.name}
      src={getPaymentMethodImage(bank.bank_code)}
      alt={bank.name}
      onClick={() => {
        setPaymentMethod(bank)
      }}
    />
  )
}

export const DetailPhoto = () => {
  const token = localStorage.getItem("token")
  const downloadPhoto = useStoreActions((action) => action.downloadPhoto)
  const loaderData = useLoaderData()
  const checkoutPhoto = useStoreActions((state) => state.oneCheckoutPhoto)
  const { photo, similar, currentId } = loaderData
  const [hasMoreSimilar, setHasMoreSimilar] = useState(null)
  const [similarPhotos, setSimilarPhotos] = useState([])
  const [dataPhoto, setDataPhoto] = useState([])
  const [isGambarZoom, setIsGambarzoom] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [tampungCurrentId, setTampungCurrentId] = useState(0)
  const navigate = useNavigate()
  const paymentMethod = useStoreState((state) => state.paymentMethod)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [adminFee, setAdminFee] = useState(0)
  const addCheckoutArray = useStoreActions((actions) => actions.oneCheckout)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const gallery = useStoreState((state) => state.gallery)
  const isModalBeli = useStoreState((state) => state.isModalPhoto)
  const onePhotoCart = useStoreState((state) => state.onePhotoCart)
  const photoCart = useStoreState((state) => state.photoCart)
  const savePhoto = useStoreActions((actions) => actions.savePhoto)
  const saveOnePhoto = useStoreActions((actions) => actions.saveOnePhoto)
  const [isSkeletonPhoto, setIsSkeletonPhoto] = useState(true)
  const [isSkeletonBank, setIsSkeletonBank] = useState(true)

  useEffect(() => {
    setHasMoreSimilar(similar.next)
    setSimilarPhotos(similar.row)
  }, [similar])
  const fetchNext = async () => {
    const similar = await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/apps/photos/similar${hasMoreSimilar}`,
        {
          timeout: 2000,
        }
      )
      .catch((err) => err)
    if (similar.data) {
      setSimilarPhotos((old) => [...old, ...similar.data.data.row])
      setHasMoreSimilar(similar.data.data.next)
    }
  }

  const handlePrev = (e) => {
    e.stopPropagation()
    let id = ""
    let cariindex = 0
    // mendapatkan index dari data id
    cariindex = dataPhoto.findIndex(
      (item) => Number(item.id) === tampungCurrentId + 1
    )
    dataPhoto.map((item, index) =>
      index === cariindex ? (id = item.id.toString()) : null
    )
    if (id) {
      navigate(`/photos/${id}/details`, { replace: true })
    }
    // index tidak ditemukan
    // mendapatkan id paling besar
    const maxdataphoto = dataPhoto.reduce((max, obj) => {
      return obj.id > max ? obj.id : max
    })
    // if tidak dapet index
    if (cariindex === -1) {
      for (let i = tampungCurrentId + 1; i <= maxdataphoto.id; i++) {
        cariindex = dataPhoto.findIndex((item) => Number(item.id) === i)
        // kalau dapet index di break
        if (cariindex !== -1) {
          break
        }
      }
      // mencari id photo berdasarkan index yang sudah didapat
      dataPhoto.map((item, index) =>
        index === cariindex ? (id = item.id.toString()) : null
      )
      if (id) {
        navigate(`/photos/${id}/details`, { replace: true })
      } else {
        toast("Photo tidak ada")
      }
    }
  }

  const handleNext = (e) => {
    e.stopPropagation()
    //TODO: prev
    let id = ""
    let cariindex = 0
    // mendapatkan index dari data id
    cariindex = dataPhoto.findIndex(
      (item) => Number(item.id) === tampungCurrentId - 1
    )
    dataPhoto.map((item, index) =>
      index === cariindex ? (id = item.id.toString()) : null
    )
    if (id) {
      navigate(`/photos/${id}/details`, { replace: true })
    }
    // index tidak ditemukan
    if (cariindex === -1) {
      for (let i = tampungCurrentId - 1; i >= 0; i--) {
        cariindex = dataPhoto.findIndex((item) => Number(item.id) === i)
        // kalau dapet index di break
        if (cariindex !== -1) {
          break
        }
      }
      // mencari id photo berdasarkan index yang sudah didapat
      dataPhoto.map((item, index) =>
        index === cariindex ? (id = item.id.toString()) : null
      )
      if (id) {
        navigate(`/photos/${id}/details`, { replace: true })
      } else {
        toast("Photo tidak ada")
      }
    }
  }

  const handleDownloadPhoto = async (id) => {
    if (token) {
      await downloadPhoto(id)
    } else {
      toast("Login diperlukan untuk download photo ini")
    }
  }

  useEffect(() => {
    const fetchData = () => {
      const dataPhoto = axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/apps/photos/all?current_page=1&page_size=9999999999999999999`
        )
        .then((data) => {
          setIsSkeletonPhoto(false)
          setDataPhoto(data.data.data.row)
        })

      const dataBank = axios
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/apps/payment-methods`)
        .then((data) => {
          setIsSkeletonBank(false)
          setPaymentMethods(data.data.payment_methods ?? [])
        })

      return { dataPhoto, dataBank }
    }
    setTampungCurrentId(currentId)
    fetchData()
    setAdminFee(() => {
      if (paymentMethod.bank_code === "GOPAY") {
        let feeBank =
          Math.round(paymentMethod.admin_fee + photo.price * 0.02) || 0
        return feeBank + feeBank * 0.11 || 0
      } else {
        return paymentMethod.admin_fee || 0
      }
    })
  }, [paymentMethod, currentId, onePhotoCart])

  useEffect(() => {
    addCheckoutArray(photo?.id)
  }, [onePhotoCart])

  const BankAccordion = ({ paymentMethods, text }) => {
    const [isShow, setIsShow] = useState(true)
    const toggleIsShow = () => setIsShow((old) => !old)
    return paymentMethods.length ? (
      <div>
        <span
          className="flex items-center flex-row cursor-pointer"
          onClick={toggleIsShow}
        >
          <p className=" text-white flex w-full">{text}</p>
          <ArrowDown2
            size={15}
            color="white"
            className={`h-5 transition-all ${isShow ? "rotate-180" : ""}`}
          />
        </span>
        <div
          className={`transition-all overflow-hidden ${
            isShow ? "max-h-[380px]" : "max-h-[0px]"
          }`}
        >
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 ml-5 items-center gap-1 py-4">
            {paymentMethods.map((item, index) => (
              <BankImage key={index} bank={item} />
            ))}
          </div>
        </div>
      </div>
    ) : null
  }

  const checkout = async () => {
    setIsSubmitting(true)
    const res = await checkoutPhoto()
      .catch((err) => err)
      .finally(() => {
        setIsSubmitting(false)
      })
    if (res instanceof Error) {
      return toast(res.message)
    }
    navigate("/payment/" + res.data.payment_id)
    toast("Checkout berhasil")
    setIsSubmitting(false)
  }

  const openModalBeliPhoto = () => {
    if (token) {
      const cartExist = Object.values(photoCart).some(
        (item) => item.photo.id === photo.id
      )
      if (cartExist) {
        window.open(`/photos/your-collection`, "_self")
      } else {
        saveOnePhoto({ id: photo.id, photo: photo }) //
      }
    } else {
      toast("Login diperlukan untuk beli foto ini")
    }
  }

  useEffect(() => {
    setIsModal(isModalBeli)
  }, [isModalBeli])

  return (
    <>
      {/* TODO: fix modal scrolling */}
      {/* TODO: Head SEO */}
      {isModal && (
        <div
          onClick={(e) => {
            setIsModal(false)
          }}
          className="fixed h-full w-full z-50 flex justify-center items-center backdrop-blur-sm bg-black bg-opacity-25 "
        >
          <div
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            className="py-32 max-h-full overflow-y-scroll rounded-lg right-0 left-0 top-0 bottom-0 z-50  scrollbar-none "
          >
            <div className="flex w-[742px] pointer-events-none">
              <div
                style={{ boxShadow: "3px 8px 19px rgba(0, 0, 0, 0.44)" }}
                className="modal-content gap-[17px] px-[24px] py-[24px] slide-in-up transition-transform xl:w-full md:w-full lg:w-screen sm:w-screen w-screen max-h-fit border-none shadow-lg relative flex flex-col pointer-events-auto bg-[#191919] bg-clip-padding rounded-md outline-none text-current"
              >
                <div className="flex flex-col border-b-[1px] border-[#434343] pb-[17px] gap-[17px]">
                  <div className="modal-header flex flex-shrink-0 items-center justify-between py-4 border-b-4 border-[#FC8300] border-dashed rounded-t-md">
                    <h5 className="text-[18px] font-[600] leading-normal font-nunito text-[#DCDCDC]">
                      Beli Photo
                    </h5>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <div className="h-[30px] w-[3px] bg-primary"></div>
                      <div className="text-[#DCDCDC]">Payment Method</div>
                    </div>
                  </div>
                  {/* start deskripsi event */}
                  <div>
                    {/* <div className="flex flex-row items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-[#DCDCDC] gap-1 flex flex-row">
                          Deskripsi Event:{" "}
                          <p className="text-[#FC8300]">350.000</p>
                        </div>
                      </div>
                      <ChevronDownIcon
                        id="GHKoin"
                        onClick={(e) => dropdownHandler(e)}
                        className={`${
                          dropdown.GHKoin ? "rotate-0" : "rotate-[180deg]"
                        } text-secondary transition-all h-[1rem] w-auto cursor-pointer`}
                      />
                    </div> */}
                    {/* <div
                      className={`${
                        dropdown.GHKoin ? "max-h-0" : "max-h-[100rem] mt-1"
                      } transition-all ease-in-out duration-100 overflow-hidden text-white`}
                    >
                      <div className="flex flex-col gap-1">
                        <span className="font-[400] text-[12px] text-[#DCDCDC]">
                          Otomatis memotong Saldo GH Koin
                        </span>
                        <div className="flex flex-row gap-[6px] items-center">
                          <span className="text-[#FC8300] flex flex-row gap-[6px] bg-[#272727] px-[13px] py-[9px] font-[700] text-[14px]">
                            <img
                              alt=""
                              src={ListImages.GHCoin}
                              color="#FC8300"
                            />
                            GH Coin
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  {/* e-wallet */}
                  <BankAccordion
                    paymentMethods={paymentMethods.filter(
                      (item) => item.bank_code === "GOPAY"
                    )}
                    text={"E-Wallet"}
                  />

                  {/* virtual account */}
                  <BankAccordion
                    paymentMethods={paymentMethods.filter(
                      (item) =>
                        !["GOPAY", "CREDITCARD"].includes(item.bank_code)
                    )}
                    text={"Virtual Account"}
                  />

                  {/* credit card */}
                  <BankAccordion
                    paymentMethods={paymentMethods.filter(
                      (item) => item.bank_code === "CREDITCARD"
                    )}
                    text={"Credit Card"}
                  />
                </div>
                {/* end deskripszi event  */}

                {/* start order summary*/}
                <div className="flex flex-col  border-b-[2px] border-[#434343] pb-[17px] gap-[17px]">
                  <div className="flex flex-col w-full gap-[17px] justify-between">
                    <div className="flex space-x-2 items-center flex-row">
                      <div className="h-[30px] w-[3px] bg-primary"></div>
                      <div className="text-[#DCDCDC]">Order Summary</div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="flex gap-[18px] flex-row ">
                        <div className="border-[1px] border-[#FC8300] p-1 rounded-md">
                          <Image size={14} color="#FC8300" />
                        </div>
                        <p className="text-[#DCDCDC]">{photo.name}</p>
                      </div>
                      <div className="flex">
                        <p className="text-[#DCDCDC]">
                          {formatRupiah(Number(photo.price))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end order summary */}

                {/* start subtotal */}
                <div className="flex flex-col gap-[16px] border-b-[2px] border-[#434343] pb-[16px]">
                  <div className="flex flex-row justify-between">
                    <span className="flex flex-row gap-1">
                      <p className="font-[400] text-[#DCDCDC] text-[16px]">
                        Subtotal
                      </p>
                    </span>
                    <p className="text-[#DCDCDC] font-[600] text-[16px]">
                      {formatRupiah(Number(photo.price))}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <span className="flex flex-row gap-1">
                      <p className="font-[400] text-[#DCDCDC] text-[16px]">
                        Biaya Admin
                      </p>
                    </span>
                    <p className="text-[#DCDCDC] font-[600] text-[16px]">
                      {formatRupiah(Number(adminFee)) ?? 0}
                    </p>
                  </div>
                </div>
                {/* end subtotal */}

                {/* total */}
                <div className="justify-between flex flex-row font-[600] text-[18px] text-[#DCDCDC]">
                  <p>Total</p>
                  <p>{formatRupiah(Number(photo.price + adminFee))}</p>
                </div>

                <div className="flex flex-row items-center gap-2">
                  <button
                    className="bg-[#1D1D1D] w-full py-[9px] rounded-[5px] border-[#303030] border-2"
                    onClick={() => setIsModal(false)}
                  >
                    <span className="font-[600] text-[14px] text-[#DCDCDC]">
                      Cancel
                    </span>
                  </button>
                  <button
                    className="bg-[#FC8300] rounded-[5px] w-full py-[9px] border-[#FC8300] border-2 "
                    onClick={checkout}
                  >
                    <span className="font-[600] text-[14px] text-[#1D1D1D]">
                      Beli Photo
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Layout>
        <div className="flex justify-center">
          <div className="container items-center flex flex-col">
            {isSkeletonPhoto ? (
              <DetailPhotoSection1 />
            ) : (
              <div
                className={`${
                  isGambarZoom
                    ? "max-h-screen"
                    : "max-h-[300px] lg:max-h-[605px]"
                } relative w-screen flex justify-center overflow-hidden transition-all`}
              >
                {/* spacer */}
                <div
                  className={`${
                    isGambarZoom ? "h-[90vh]" : "h-[300px] lg:h-[605px]"
                  } transition-all`}
                >
                  .
                </div>
                {/* end spacer */}
                <div
                  onClick={() => setIsGambarzoom((old) => !old)}
                  className={`${
                    isGambarZoom
                      ? "cursor-zoom-out h-[90vh]"
                      : "cursor-zoom-in h-[300px] lg:h-[605px]"
                  } absolute container z-10 h-full w-full flex flex-col opacity-0 hover:opacity-100 transition-all`}
                >
                  <div className="justify-end hidden lg:flex p-8">
                    <button
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsGambarzoom((old) => !old)
                      }}
                    >
                      <Maximize4 className="text-secondary h-6" />
                    </button>
                  </div>
                  <div className="flex justify-between items-center flex-1 px-8">
                    <button
                      hidden
                      onClick={handlePrev}
                      className="w-[44px] h-[44px] lg:flex md:flex sm:flex flex rounded-full items-center justify-center bg-[#191919]"
                    >
                      <ArrowLeft2 size={24} className="text-secondary" />
                    </button>
                    <button
                      onClick={handleNext}
                      className="w-[44px] h-[44px] lg:flex md:flex sm:flex flex rounded-full items-center justify-center bg-[#191919]"
                    >
                      <ArrowRight2 size={24} className="text-secondary" />
                    </button>
                  </div>
                </div>
                <div className="absolute top-0 w-screen bg-black flex justify-center">
                  <div className="text-white rounded-lg  absolute bottom-0 bg-yellow-600 p-1 ">
                    Untuk download foto resolusi tinggi diperlukan koneksi
                    internet yang cepat dan stabil
                  </div>
                  <img
                    src={photo.url_watermark}
                    alt=""
                    className={`${
                      isGambarZoom ? "h-[90vh] " : "h-[300px] lg:h-[605px]"
                    } lg:py-4 transition-all object-contain`}
                  />
                </div>
              </div>
            )}

            <div className="flex flex-col w-full mt-[25px] mb-[43px] space-y-12 ">
              {/* Beli atau download photo*/}
              {isSkeletonPhoto ? (
                <DetailPhotoSection2 />
              ) : (
                <div className="flex justify-between xl:mx-0 lg:mx-0 md:mx-0 sm:mx-4 mx-4 xl:flex-row lg:flex-row md:flex-row sm:flex-row flex-col lg:items-center">
                  <div className="flex flex-col">
                    <span className="flex-grow font-[600] text-[18px] text-[#DCDCDC] ">
                      <p>{photo.name}</p>
                    </span>
                    <span className="flex-grow font-[500] text-[16px] text-[#BABABA] ">
                      <p>{formatRupiah(Number(photo.price))}</p>
                    </span>
                  </div>
                  <div className="flex flex-row gap-[8px] xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0 mt-5">
                    {photo.price === 0 ? (
                      <>
                        <button
                          onClick={() => handleDownloadPhoto(photo.id)}
                          className="bg-[#1D1D1D] rounded-[5px] border-2 border-[#303030]"
                        >
                          <span className="flex-grow font-[600] text-[14px] text-[#DCDCDC] ">
                            <p className="px-[10px] py-[9px]">Download Photo</p>
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => openModalBeliPhoto()}
                          className="bg-[#1D1D1D] rounded-[5px] border-2 border-[#303030]"
                        >
                          <span className="flex-grow font-[600] text-[14px] text-[#DCDCDC] ">
                            <p className="px-[10px] py-[9px]">Beli Photo</p>
                          </span>
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation()
                            savePhoto({ id: photo.id, photo: photo })
                          }}
                          className="bg-[#1D1D1D] flex items-center rounded-[5px] border-2 border-[#303030]"
                        >
                          <span title="Add to Collection">
                            <Add
                              size={24}
                              className={`m-[8px] ${
                                photoCart[photo.id]
                                  ? "text-primary"
                                  : "text-secondary"
                              } `}
                            />
                          </span>
                        </button>
                      </>
                    )}

                    {/* TODO: Share */}
                    {/* <button className="bg-[#1D1D1D] flex items-center rounded-[5px] border-2 border-[#303030]">
                    <Share className="text-secondary m-[8px]" size={24} />
                  </button> */}
                  </div>
                </div>
              )}

              {/* END PHOTO DETAIL 1 */}

              {/* PHOTO DETAIL */}
              {isSkeletonPhoto ? (
                <DetailPhotoSection3 />
              ) : (
                <div
                  className="mt-[36px] 
                xl:mx-0
                lg:mx-0
                md:mx-0 md:flex-row md:space-y-0 md:space-x-4
                sm:mx-4
                mx-4 space-y-4 flex xl:flex-row  flex-col "
                >
                  <div className="flex flex-col gap-0 lg:gap-[4px]">
                    <span className="flex-grow font-[400] text-[12px] text-[#DCDCDC] ">
                      <p>Photo By</p>
                    </span>
                    <span className="flex-grow font-[600] text-[14px] text-[#DCDCDC] ">
                      <p>{photo.photo_by}</p>
                    </span>
                  </div>
                  <div className="flex flex-col gap-0 lg:gap-[4px]">
                    <span className="flex-grow font-[400] text-[12px] text-[#DCDCDC] ">
                      <p>Dimension</p>
                    </span>
                    <span className="flex-grow font-[600] text-[14px] text-[#DCDCDC] ">
                      <p>{photo.dimension}</p>
                    </span>
                  </div>
                  <div className="flex flex-col gap-0 lg:gap-[4px]">
                    <span className="flex-grow font-[400] text-[12px] text-[#DCDCDC] ">
                      <p>Size</p>
                    </span>
                    <span className="flex-grow font-[600] text-[14px] text-[#DCDCDC] ">
                      <p>{photo.size / 1000} Mb</p>
                    </span>
                  </div>
                  <div className="flex flex-col gap-0 lg:gap-[4px]">
                    <span className="flex-grow font-[400] text-[12px] text-[#DCDCDC] ">
                      <p>File Type</p>
                    </span>
                    <span className="flex-grow font-[600] text-[14px] text-[#DCDCDC] ">
                      <p className="uppercase">{photo.type_file}</p>
                    </span>
                  </div>
                  <div className="flex flex-col gap-0 lg:gap-[4px]">
                    <span className="flex-grow font-[400] text-[12px] text-[#DCDCDC] ">
                      <p>Tag</p>
                    </span>
                    <span className="flex-grow font-[600] text-[14px] text-[#DCDCDC] ">
                      <p>
                        {(photo.photo_tags ?? [])
                          .map((tags) => tags.tag.name)
                          .join(", ")}
                      </p>
                    </span>
                  </div>
                </div>
              )}

              {/* END PHOTO DETAIL */}
              {/* SIMILAR PHOTO */}
              <div className="flex text-t-primary-disabled items-center space-x-3 mx-4 md:mx-0">
                <span className="flex-grow border-t-2 border-b-0 border-b-primary-disabled h-[1px]"></span>
                <p>Similar Photo</p>
                <span className="flex-grow border-t-2 border-b-0 border-b-primary-disabled h-[1px]"></span>
              </div>

              {isSkeletonPhoto ? (
                <DetailPhotoSection4 />
              ) : (
                <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4 mx-4 md:mx-0">
                  {similarPhotos.map((item, index) => {
                    return (
                      <PhotoThumbnail isLast={false} key={index} photo={item} />
                    )
                  })}
                </div>
              )}

              {hasMoreSimilar && (
                <div className="flex justify-center">
                  <GHButton onClick={fetchNext}>LOAD MORE</GHButton>
                </div>
              )}
              {/* END SIMILAR PHOTO */}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
