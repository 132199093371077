import { CloseCircle } from "iconsax-react";
import PhotoThumbnail from "../../PhotoThumbnail";
import { useRevalidator, useSearchParams } from "react-router-dom";
import { GHButton } from "../../GHSystem";

function PhotoSearchResult({ searchResult, search, hasMore, fetchNext }) {
  const revalidator = useRevalidator();
  const [searchParams, setSearchParams] = useSearchParams();

  const clearSearch = () => {
    setSearchParams((prev) => {
      prev.delete("search");
      return prev;
    });
    revalidator.revalidate();
  };

  return (
    <div className="flex flex-col space-y-12">
      <div className="flex items-center space-x-3">
        <p className="text-secondary text-base">
          Foto dengan kata <span className="text-primary">{`${search}`}</span>
        </p>
        <span
          onClick={clearSearch}
          className="flex border-2 border-primary rounded-full cursor-pointer items-center"
        >
          <CloseCircle size="32" className="text-primary" />
          <p className="text-secondary pr-2">Clear search</p>
        </span>
      </div>
      <div
        className="grid 
      grid-cols-2 lg:grid-cols-4
      gap-4"
      >
        {searchResult.map((photo, index) => {
          return <PhotoThumbnail isLast={false} key={index} photo={photo} />;
        })}
      </div>
      {hasMore && (
        <div className="flex justify-center">
          <GHButton onClick={fetchNext}>LOAD MORE</GHButton>
        </div>
      )}
      <div className="flex text-t-primary-disabled items-center space-x-3">
        <span className="flex-grow border-t-2 border-b-0 border-b-primary-disabled h-[1px]"></span>
        <p>Photo dari Spesifik Event</p>
        <span className="flex-grow border-t-2 border-b-0 border-b-primary-disabled h-[1px]"></span>
      </div>
    </div>
  );
}

export default PhotoSearchResult;
