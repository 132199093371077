import React from "react"
import ListImages from "../assets"
import {
  CheckBadgeIcon,
  MapIcon,
  CalendarDaysIcon,
  ClockIcon,
  ChevronDownIcon,
  PhoneIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline"
import { formatRupiah } from "../utils"

import Countdown from "react-countdown"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import axios from "axios"
import { useState } from "react"
import { useRef } from "react"
import { toast } from "react-toastify"
import copy from "copy-to-clipboard"
import getPaymentMethodImage from "../utils/getPaymentMethodImage"
import { GHButton } from "../components/GHSystem"
import PaymentInstruction from "../components/PaymentInstruction"
import { Helmet } from "react-helmet"
import { format } from "date-fns"
import { id as idID } from "date-fns/locale"
import Layout from "../components/layout"
import { Image } from "iconsax-react"
import { ModalDetail } from "../components/Modal"

function Payment() {
  const { paymentid } = useParams()
  const [paymentDetail, setPaymentDetail] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [showError, setShowError] = useState(false)
  const [subTotal, setSubTotal] = useState(0)
  const [dataPayment, setDataPayment] = useState()
  const navigate = useNavigate()
  const [countTicket, setCountTicket] = useState(0)
  const [isModal, setIsModal] = useState(false)
  const [urlCC, setUrlCC] = useState()

  const copyCode = useRef(null)
  const [dropdownList, setDropdownList] = useState({
    atm: true,
    mobileBanking: true,
    internetBanking: true,
  })

  const copyHandler = () => {
    copy(copyCode.current.innerHTML)
    toast("Copied to Clipboard")
  }

  // useEffect(() => {
  //   if (paymentDetail.is_not_close) {
  //     setIsModal(true)
  //   }
  // }, [paymentDetail])

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <div className="p-5 px-12">
          <div className="text-primary text-[2rem]">EXPIRED</div>
        </div>
      )
    } else {
      // Render a countdown
      return (
        <div className="p-5 px-12">
          <div className="flex space-x-3">
            <div className="flex flex-col items-center">
              <div className="text-[2rem] text-primary">
                {hours.toString().length > 1 ? hours : "0" + hours}
              </div>
              <div>Jam</div>
            </div>
            <span className="text-[1.5rem] text-primary mt-1">:</span>
            <div className="flex flex-col items-center">
              <div className="text-[2rem] text-primary">
                {minutes.toString().length > 1 ? minutes : "0" + minutes}
              </div>
              <div>Menit</div>
            </div>
            <span className="text-[1.5rem] text-primary mt-1">:</span>
            <div className="flex flex-col items-center">
              <div className="text-[2rem] text-primary">
                {seconds.toString().length > 1 ? seconds : "0" + seconds}
              </div>
              <div>Detik</div>
            </div>
          </div>
        </div>
      )
    }
  }

  const resendTicket = async () => {
    setIsSubmitting(true)
    const res = await axios
      .get(`${process.env.REACT_APP_BASE_URL}/apps/resend-ticket/${paymentid}`)
      .catch((err) => err)
    if (res.response) {
      setIsSubmitting(false)
      return toast(res.response.data.message ?? "Something went wrong")
    }
    if (res.data.success) {
      setIsSubmitting(false)
      toast("Ticket has been sent to your email")
    }
    return setIsSubmitting(false)
  }

  const handleGopay = (token) => {
    window.snap.pay(token, {
      onSuccess: (result) => {
        console.log(result)
      },
    })
  }

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(true)
    }, 4000)
    const getDetail = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/apps/payment/${paymentid}`, {
          timeout: 15000,
        })
        .then((response) => {
          let data = response.data
          if (data.success) {
            // if (data.payment.status === 1) {
            //   navigate(`/event/${id}/payment/${data.payment_id}/status`);
            // }
          }
          setPaymentDetail(response.data.payment)
          setUrlCC(response.data.payment.payment_code)
          setDataPayment(response.data.payment)
        })
        .catch((error) => {
          setShowError(true)
        })
    }
    getDetail()
  }, [])

  useEffect(() => {
    if (paymentDetail.payment_summary && paymentDetail.type === 2) {
      setSubTotal(
        paymentDetail?.payment_summary.reduce((sum, value, index) => {
          return sum + (value.ticket_category.price ?? 0) * (value.count ?? 0)
        }, 0)
      )
      setCountTicket(
        paymentDetail?.payment_summary?.reduce((sum, value) => {
          return sum + value.count
        })
      )
    }
    if (paymentDetail.payment_summary && paymentDetail.type === 3) {
      setSubTotal(
        paymentDetail?.payment_summary.reduce((prev, curr, index) => {
          return prev + (curr.photo.price ?? 0)
        }, 0)
      )
    }
    if (paymentDetail.payment_summary && paymentDetail.type === 0) {
      setSubTotal(
        paymentDetail?.payment_summary.reduce((sum, value, index) => {
          return sum + (value.ticket_category.price ?? 0) * (value.count ?? 0)
        }, 0)
      )
      setCountTicket(
        paymentDetail?.payment_summary?.reduce((sum, value) => {
          return sum + value.count
        }, 0)
      )
    }
  }, [paymentDetail])

  useEffect(() => {
    if (paymentDetail.status === 1) {
      setTimeout(() => {
        dataPayment.payment_summary.map((item) =>
          item.ticket_category.categories_name
            .toLowerCase()
            .includes("namarina")
            ? window.open(
                `https://ghseat.com/?email=${dataPayment.user.email}&paymentid=${dataPayment.id}`
              )
            : null
        )
      }, 2000)
    }
  }, [dataPayment])
  //TODO: extract to component instead
  const renderPaymentDetail = () => {
    switch (paymentDetail?.type) {
      case 3: //image/photo
        return (
          <div
            style={{ boxShadow: "0 0 5px black" }}
            className="
  hidden lg:flex
p-5 w-[60%] rounded-md overflow-hidden text-secondary  flex-col space-y-4 bg-[#191919] h-fit"
          >
            <div>Payment Summary</div>
            {paymentDetail?.payment_summary?.map((summary, index) => {
              return (
                <div key={index} className={` flex justify-between`}>
                  <div className="flex items-center space-x-2">
                    <Image className="text-primary h-5 w-5 border-[1px] border-primary rounded-[2px]" />
                    <div className="text-sm ">{summary.photo.name}</div>
                  </div>
                  <div className="mt-auto">
                    <div>{formatRupiah(summary.photo.price ?? 0)}</div>
                  </div>
                </div>
              )
            })}
            <div className="flex flex-col space-y-5 py-3 border-y border-[#434343]">
              <div className="flex items-center justify-between">
                <div>
                  Subtotal{" "}
                  <span className="text-primary">{countTicket} Photo</span>
                </div>
                <div>{formatRupiah(subTotal)}</div>
              </div>
              <div className="flex items-center justify-between">
                <div>Diskon Promo</div>
                <div>{`- ${formatRupiah(
                  paymentDetail?.discounts.length
                    ? paymentDetail.discounts[0].total_amount
                    : 0
                )}`}</div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  Biaya Transfer Bank + Biaya Admin + Pajak + Performing Right
                </div>
                <div>
                  {formatRupiah(
                    paymentDetail.amount -
                      (subTotal -
                        (paymentDetail?.discounts.length
                          ? paymentDetail.discounts[0].total_amount
                          : 0))
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="font-semibold">Total</div>
              <div>{formatRupiah(paymentDetail?.amount ?? 0 + 10000)}</div>
            </div>
          </div>
        )

      default: //2 ticket
        return (
          <div
            style={{ boxShadow: "0 0 5px black" }}
            className="
hidden lg:flex
p-5 w-[60%] rounded-md overflow-hidden text-white  flex-col space-y-4 bg-[#191919] h-fit"
          >
            <div>Event Detail</div>
            <img
              className="w-full max-h-[500px] object-cover object-center"
              src={paymentDetail.event?.event_images?.[0].image}
              alt=""
            />
            <div>{paymentDetail.event?.title}</div>
            <div className="flex items-center space-x-2">
              <MapIcon className="text-primary h-[1.5rem] w-auto" />
              <div>
                {paymentDetail.event?.venue_name} ,{" "}
                {paymentDetail.event?.venue_location}
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <CalendarDaysIcon className="text-primary h-[1.5rem] w-auto" />
              <div>
                {format(
                  new Date(paymentDetail.event?.start_date ?? Date.now()),
                  "dd MMMM yyyy",
                  { locale: idID }
                )}
              </div>
            </div>
            <div className="flex items-center space-x-2 pb-5 border-b-4 border-dashed border-primary">
              <ClockIcon className="text-primary h-[1.5rem] w-auto" />
              <div>{paymentDetail.event?.start_time} WIB</div>
            </div>
            <div>ORDER SUMMARY</div>
            {paymentDetail?.payment_summary?.map((summary, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    index !== paymentDetail.payment_summary.length - 1
                      ? "border-b-4 border-dashed border-[#434343] pb-3"
                      : ""
                  } flex justify-between`}
                >
                  <div className="flex items-start space-x-2">
                    <img className="mt-1" src={ListImages.Ticket} alt="" />
                    <div>
                      <div className="text-[1.25rem] font-semibold uppercase">
                        {summary.ticket_category.categories_name}
                      </div>
                      <div className="font-light">{summary.count} Tiket</div>
                    </div>
                  </div>
                  <div className="mt-auto">
                    <div>
                      {formatRupiah(
                        summary.ticket_category.price ?? 0 * summary.count ?? 0
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="flex flex-col space-y-5 py-3 border-y border-[#434343]">
              <div className="flex items-center justify-between">
                <div>
                  Subtotal{" "}
                  <span className="text-primary">{countTicket} Tiket</span>
                </div>
                <div>{formatRupiah(subTotal)}</div>
              </div>
              <div className="flex items-center justify-between">
                <div>Diskon Promo</div>
                <div>{`- ${formatRupiah(
                  paymentDetail?.discounts.length
                    ? paymentDetail.discounts[0].total_amount
                    : 0
                )}`}</div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  Biaya Transfer Bank + Biaya Admin + Pajak + Performing Right
                </div>
                <div>
                  {formatRupiah(
                    paymentDetail.amount -
                      (subTotal -
                        (paymentDetail?.discounts.length
                          ? paymentDetail.discounts[0].total_amount
                          : 0))
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="font-semibold">Total</div>
              <div>{formatRupiah(paymentDetail?.amount ?? 0 + 10000)}</div>
            </div>
          </div>
        )
    }
  }

  const renderSuccess = (navigate) => {
    switch (paymentDetail?.type) {
      //photo
      case 3:
        return (
          <div className="bg-semiblack flex items-center justify-center p-12 text-secondary ">
            <div className="flex flex-col space-y-12 items-center text-center ">
              <img src={ListImages.Illustration2} alt="" />
              <div>
                <div className="text-2xl">Transaksi Selesai!</div>
                <div className="text-lg">
                  Silahkan download photomu pada menu <br /> gallery photo di
                  halaman profile
                </div>
              </div>
              <div>
                <button
                  disabled={isSubmitting}
                  onClick={() => navigate("/profile/gallery-photo")}
                  className="hover:brightness-110 transition-all py-3 px-5 text-black bg-primary rounded-md"
                >
                  {isSubmitting ? (
                    <div
                      className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full m-0"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Lihat Gallery Photo"
                  )}
                </button>
              </div>
              <div>
                <div>Having trouble receiving the photos?</div>
                <div className="flex items-center space-x-5 mt-5">
                  <div className="flex items-center space-x-2 text-primary">
                    <PhoneIcon className="h-[1.5rem] w-auto" />
                    <div>(021)-50812002</div>
                  </div>
                  <div className="flex items-center space-x-2 text-primary">
                    <EnvelopeIcon className="h-[1.5rem] w-auto" />
                    <div>sosmed@guehadir.id</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      //2 ticket
      default:
        return (
          <div className="bg-semiblack flex items-center justify-center p-12 text-secondary ">
            <div className="flex flex-col space-y-12 items-center text-center ">
              <img src={ListImages.Illustration2} alt="" />
              <div>
                <div className="text-[1.5rem]">Transaksi Selesai!</div>
                <div className="text-[1.5rem]">Tickets have been sent to</div>
                <div>{paymentDetail?.user?.email}</div>
              </div>

              {dataPayment.payment_summary.map((item) =>
                item.ticket_category.categories_name
                  .toLowerCase()
                  .includes("namarina") ? (
                  <div className="flex flex-col">
                    <text className="mt-10 font-bold">
                      Choose seat with click button bellow
                    </text>
                    <button
                      disabled={isSubmitting}
                      onClick={() =>
                        window.open(
                          `https://ghseat.com/?email=${dataPayment.user.email}&paymentid=${dataPayment.id}`
                        )
                      }
                      className="hover:brightness-110 transition-all mt-5 py-3 px-5 text-black bg-primary rounded-md"
                    >
                      {isSubmitting ? (
                        <div
                          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full m-0"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Choose Seat"
                      )}
                    </button>
                  </div>
                ) : null
              )}
              <div>
                <div>Haven't received ticket yet?</div>
                <button
                  disabled={isSubmitting}
                  onClick={resendTicket}
                  className="hover:brightness-110 transition-all mt-5 py-3 px-5 text-black bg-primary rounded-md"
                >
                  {isSubmitting ? (
                    <div
                      className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full m-0"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Resend Ticket"
                  )}
                </button>
              </div>
              <div>
                <div>Having trouble receiving the tickets?</div>
                <div className="flex items-center space-x-5 mt-5">
                  <div className="flex items-center space-x-2 text-primary">
                    <PhoneIcon className="h-[1.5rem] w-auto" />
                    <div>(021)-50812002</div>
                  </div>
                  <div className="flex items-center space-x-2 text-primary">
                    <EnvelopeIcon className="h-[1.5rem] w-auto" />
                    <div>sosmed@guehadir.id</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <Layout>
      {isModal && (
        <ModalDetail title={"Attention!"}>
          <div className="flex text-white flex-col gap-3">
            <text className="font-bold text-white">
              Your tickets might have seats that are not next to each other.
            </text>
            <text className="font-thin">
              (Tiket Anda mungkin memiliki kursi yang tidak bersebelahan.)
            </text>
            <button
              className="border-2 rounded-lg px-3 py-2 w-20 justify-end self-end border-yellow-800 hover:border-yellow-900 duration-300 transition-all text-white"
              onClick={() => setIsModal(false)}
            >
              Close
            </button>
          </div>
        </ModalDetail>
      )}
      <dialog id="paymentCC" className="modal">
        <div className="modal-box modal-lg bg-white">
          <h3 className="font-bold text-lg text-black">Payment Credit Card</h3>
          <iframe
            src={urlCC}
            title="Payment Credit Card"
            className="w-full xl:h-[500px] lg:h-[500px] md:h-[500px] sm:h-screen h-screen mt-5"
            allowFullScreen
          />
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn bg-white border-2 text-black hover:border-gray-900 border-gray-400">
                Close
              </button>
            </form>
          </div>
        </div>
      </dialog>

      <>
        <Helmet>
          <title>{`Payment Status ${paymentid} | Gue Hadir`}</title>z
          <meta
            property="og:title"
            content={`Payment Status ${paymentid} | Gue Hadir`}
          />
          <meta
            property="og:description"
            content="Payment status page @ Gue Hadir"
          />
          <meta
            property="og:image"
            content={paymentDetail.event?.event_images?.[0].image}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Gue Hadir" />
          <meta
            property="og:url"
            content={`https://guehadir.id/payment/${paymentid}`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="guehadir.id" />
          <meta
            property="twitter:url"
            content={`https://guehadir.id/payment/${paymentid}`}
          />
          <meta
            name="twitter:title"
            content={`Payment Status ${paymentid} | Gue Hadir`}
          />
          <meta
            name="twitter:description"
            content="Payment status page @ Gue Hadir"
          />
          <meta
            name="twitter:image"
            content={paymentDetail.event?.event_images?.[0].image}
          />
        </Helmet>
        {paymentDetail.status === 1 ? (
          renderSuccess(navigate)
        ) : paymentDetail.status === 0 || paymentDetail.status === 2 ? (
          <>
            <div
              className="banner2-bg flex flex-col space-y-6 justify-center items-center text-white
    pt-[20px] lg:pt-[70px]
    pb-[80px] lg:pb-[130px]
    "
            >
              <div className="text-[1.5rem]">Sisa Waktu Pembayaran</div>
              <div
                className="p-2 bg-semiblack"
                style={{ boxShadow: "0 0 10px black" }}
              >
                {paymentDetail.expired_time &&
                new Date().getTime() <
                  new Date(paymentDetail.expired_time).getTime() ? (
                  <Countdown
                    date={
                      new Date().getTime() +
                      (new Date(paymentDetail.expired_time).getTime() -
                        new Date().getTime())
                    }
                    intervalDelay={0}
                    renderer={renderer}
                  />
                ) : paymentDetail.status !== 2 ? (
                  <div className="p-5 px-12">
                    <div className="text-primary text-[2rem]">PENDING</div>
                  </div>
                ) : (
                  <div className="p-5 px-12">
                    <div className="text-primary text-[2rem]">EXPIRED</div>
                  </div>
                )}
              </div>
              <div className="hidden md:block p-3 bg-primary text-black rounded-md whitespace-nowrap px-5">
                Please complete the payment before{" "}
                {new Date(paymentDetail?.expired_time).toDateString()}{" "}
                {new Date(paymentDetail?.expired_time).getHours()}:
                {new Date(paymentDetail?.expired_time).getMinutes()}{" "}
              </div>
            </div>
            <div
              className="
      flex flex-col items-center
    bg-semiblack"
            >
              <div
                className="flex container 
    px-4 lg:px-0 justify-center
    translate-y-[-60px]
    "
              >
                <div className="w-full flex flex-col lg:flex-row lg:space-x-5 ">
                  <div className="w-full lg:w-[40%] flex flex-col space-y-12">
                    <div
                      style={{ boxShadow: "0 0 5px black" }}
                      className="p-4 rounded-md bg-[#191919] text-white flex flex-col items-center space-y-5"
                    >
                      <div className="flex items-center space-x-3 py-1 w-full">
                        <div className="w-[4px] h-[25px] bg-primary rounded-md"></div>
                        <div>Silahkan Transfer ke</div>
                      </div>
                      <div className="flex flex-col w-full pb-3 border-[#272727] border-b">
                        <div className="text-[#BABABA]">Kode Pesanan</div>
                        <div className="mt-1">{paymentDetail?.id}</div>
                      </div>
                      {!["CREDITCARD", "GOPAY", "bca_va"].includes(
                        paymentDetail?.bank.bank_code
                      ) ? (
                        //  VIRTUAL ACCOUNT LINKQU
                        <>
                          <div className="flex flex-col w-full pb-3 border-[#272727] border-b">
                            <div className="text-[#BABABA]">
                              Virtual Account
                            </div>
                            <div className="flex items-center justify-between">
                              <div className="mt-1">
                                {paymentDetail?.bank.name}
                              </div>
                              <img
                                className="h-[2rem] w-auto"
                                src={getPaymentMethodImage(
                                  paymentDetail?.bank.bank_code
                                )}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-full border-b-2 border-b-t-primary-disabled border-dashed pb-3">
                            <div className="text-[#BABABA]">
                              Nomor Virtual Account
                            </div>
                            <div className="flex mt-2 items-center justify-between space-x-2">
                              <div
                                value={paymentDetail?.payment_code}
                                ref={copyCode}
                              >
                                {paymentDetail?.payment_code}
                              </div>
                              <div
                                onClick={() => copyHandler()}
                                className="text-primary font-semibold cursor-pointer"
                              >
                                SALIN
                              </div>
                            </div>
                          </div>
                        </>
                      ) : ["GOPAY", "bca_va"].includes(
                          paymentDetail?.bank.bank_code
                        ) ? (
                        // MIDTRANS CHANNEL
                        <div className="flex flex-col w-full pb-3 border-[#272727] border-b">
                          <div className="text-[#BABABA]">
                            {paymentDetail?.bank.name}
                          </div>
                          <div className="flex items-center justify-between">
                            {/* <div className="mt-1">{paymentDetail?.payment_code}</div> */}
                            <GHButton
                              onClick={() =>
                                handleGopay(paymentDetail?.payment_code)
                              }
                            >
                              Bayar
                            </GHButton>
                            <img
                              className="h-[2rem] w-auto"
                              src={getPaymentMethodImage(
                                paymentDetail?.bank.bank_code
                              )}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        // CREDIT CARD Xendit
                        <div className="flex flex-col w-full pb-3 border-[#272727] border-b">
                          <div className="text-[#BABABA]">Credit Card</div>
                          <div className="flex items-center justify-between">
                            {/* <div className="mt-1">{paymentDetail?.payment_code}</div> */}
                            <GHButton
                              onClick={() =>
                                document.getElementById("paymentCC").showModal()
                              }
                            >
                              Bayar
                            </GHButton>
                            <img
                              className="h-[2rem] w-auto"
                              src={getPaymentMethodImage(
                                paymentDetail?.bank.bank_code
                              )}
                              alt=""
                            />
                          </div>
                        </div>
                      )}

                      {paymentDetail?.is_not_close && (
                        <div className="flex justify-between items-center w-full">
                          <p className="p-2 bg-orange-400 rounded-md bg-opacity-50 border-orange-500 border-[1px]">
                            Perhatian! Tiket Anda mungkin memiliki kursi tidak
                            bersebelahan.
                          </p>
                        </div>
                      )}

                      <div className="flex justify-between items-center w-full">
                        <div className="text-[#BABABA]">Total Pembayaran</div>
                        <div>
                          {formatRupiah(paymentDetail.amount ?? 0 + 10000)}
                        </div>
                      </div>

                      <button
                        onClick={() => window.location.reload()}
                        className="p-2 w-full flex justify-center items-center space-x-2 text-black bg-primary font-semibold rounded-md"
                      >
                        <CheckBadgeIcon className="h-[1.5rem]" />
                        <div>Cek Status Pembayaran</div>
                      </button>
                    </div>
                    <PaymentInstruction
                      bankCode={paymentDetail?.bank.bank_code}
                    />
                  </div>

                  {renderPaymentDetail()}
                </div>
              </div>
            </div>
          </>
        ) : showError ? (
          <div
            className={`flex justify-center w-screen h-screen text-white py-5 bg-gh-black-100`}
          >
            <div className="container w-full h-full flex flex-col justify-center items-center ">
              <div className="flex flex-col">
                <p>Sorry we'are busy</p>
                <p>Please try again</p>
                <div className="flex pt-3">
                  <GHButton
                    onClick={() => {
                      window.location.reload()
                    }}
                  >
                    Refresh
                  </GHButton>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-semiblack flex items-center justify-center p-12 text-primary min-h-full h-screen">
            <div className="flex justify-center items-center">
              <div className="flex flex-col items-center">
                {showMessage && (
                  <p className="text-white pb-3">
                    This may take a while, please wait...
                  </p>
                )}
                <div
                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </Layout>
  )
}

export default Payment
