import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { ContextHandler } from "./context/context.jsx";
import { StoreProvider } from "easy-peasy";
import store from "./store";

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
  <StoreProvider store={store}>
    <ContextHandler>
      <App />
    </ContextHandler>
  </StoreProvider>
);
