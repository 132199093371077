import { useStoreActions, useStoreState } from "easy-peasy"
import { GHButton } from "../components/GHSystem"
import Layout from "../components/layout"

const ErrorPage = () => {
  const showErrorPage = useStoreState((state) => state.showErrorPage)
  const showIsUnique = useStoreState((state) => state.showIsUnique)

  const setErrorPage = useStoreActions((action) => action.setErrorPage)
  return (
    <div
      className={`flex justify-center fixed z-[1030] w-screen h-screen text-white py-5 bg-gh-black-100 ${
        showErrorPage ? "" : "hidden"
      }`}
    >
      <div className="container w-full h-full flex flex-col justify-center items-center ">
        {showIsUnique ? (
          <div className="flex flex-col">
            <p>Email anda sudah digunakan untuk mendapatkan tiket acara ini.</p>
            <p>Silahkan gunakan alamat email lain.</p>
            <div className="flex pt-3">
              <GHButton
                onClick={() => {
                  setErrorPage(false)
                }}
              >
                Kembali
              </GHButton>
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <p>Tenang.. Kamu dalam antrian untuk mendapat tiket</p>
            <p>Silahkan coba beberapa saat lagi</p>
            <div className="flex pt-3">
              <GHButton
                onClick={() => {
                  setErrorPage(false)
                }}
              >
                Kembali
              </GHButton>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ErrorPage
