import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import NewsCard from "../components/newsCard"
import ListImages from "../assets"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { useStoreState } from "easy-peasy"
import { DetailNews } from "../components/partials"

function NewsDetail() {
  const { id } = useParams()
  const currentPath = window.location.href
  const [detail, setDetail] = useState([])
  const [newsData, setNewsData] = useState([])
  const navigate = useNavigate()
  const [isSkeleton, setIsSkeleton] = useState(true)

  useEffect(() => {
    const getDetail = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/apps/news/${id}`
      )
      setDetail(response.data.data)
      setIsSkeleton(false)
      const recommendedResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/apps/news?category=${response.data.data.news_category.name}`
      )
      const filteredData = recommendedResponse.data.data.filter(
        (news) => news.slug !== id
      )
      setNewsData(filteredData)
    }
    getDetail()
  }, [])
  return (
    <Layout>
      <>
        {detail ? (
          <div className="min-h-screen min-w-screen bg-semiblack">
            <Helmet>
              <title>{`${detail?.title ?? ""} | GH News`}</title>
              <meta name="description" content={detail?.description} />
              <meta property="og:title" content={`${detail?.title}`} />
              <meta property="og:description" content={detail?.description} />
              <meta property="og:image" content={detail?.image} />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="630" />
              <meta property="og:type" content="article" />
              <meta property="og:site_name" content="Gue Hadir" />
              <meta
                property="og:url"
                content={`https://guehadir.id/news/${detail?.slug}`}
              />
              <meta property="twitter:domain" content="dev.guehadir.id" />
              <meta
                property="twitter:url"
                content={`https://guehadir.id/news/${detail?.slug}`}
              />
              <meta name="twitter:title" content={`${detail?.title}`} />
              <meta name="twitter:description" content={detail?.description} />
              <meta name="twitter:image" content={detail?.image} />
            </Helmet>
            <div className="container mx-auto bg-semiblack">
              {isSkeleton ? (
                <DetailNews />
              ) : (
                <div className="p-5 lg:p-12 bg-semiblack text-white flex flex-col">
                  <div className="hidden lg:block lg:line-clamp-1 capitalize">
                    <span
                      className="cursor-pointer"
                      onClick={() => navigate("/")}
                    >
                      GH News
                    </span>{" "}
                    {">"} {detail?.news_category?.name}
                  </div>
                  <div className="font-semibold text-[2rem] mt-3">
                    {detail.title}
                  </div>
                  <div className="mt-1">{detail.date}</div>
                  <div className="flex items-center space-x-4 mt-3">
                    <div>Bagikan :</div>
                    <a
                      href={`whatsapp://send?text=${currentPath}`}
                      data-action="share/whatsapp/share"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ListImages.facebookColored} />
                    </a>
                    <a href="#">
                      <img src={ListImages.tiktokColored} />
                    </a>
                    <a href="#">
                      <img src={ListImages.telegramColored} />
                    </a>
                    <a href="#">
                      <img src={ListImages.whatsappColored} />
                    </a>
                  </div>
                  <div className="mt-5">
                    <img
                      className="w-full rounded-md max-h-[630px] object-cover"
                      src={detail.image}
                      alt=""
                    />
                  </div>
                  <div
                    className="mt-5"
                    dangerouslySetInnerHTML={{
                      __html: window.atob(detail?.content ?? ""),
                    }}
                  ></div>
                  {/* Berita Rekomendasi  */}
                  <div className="mt-5">
                    <div className="flex items-center justify-between">
                      <div className="font-semibold text-[1.5rem]">
                        GH News Lainnya
                      </div>
                      <div
                        className="cursor-pointer text-primary"
                        onClick={() => navigate("/news/all")}
                      >
                        Lihat Semua GH News
                      </div>
                    </div>

                    <div
                      className="flex overflow-x-auto gap-3
                md:grid md:grid-cols-2 md:overflow-x-hidden
                lg:grid-cols-3 py-5"
                    >
                      {newsData?.map((x) => {
                        return (
                          <NewsCard
                            image={x.image}
                            title={x.title}
                            date={x.createdAt}
                            category={x.news_category.name}
                            description={x.description}
                            id={x.slug}
                          />
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
              {/* isi detail news */}

              {/* end isi news */}
            </div>
          </div>
        ) : (
          <div className="min-h-screen min-w-screen bg-semiblack">
            Getting data ....
          </div>
        )}
      </>
    </Layout>
  )
}

export default NewsDetail
