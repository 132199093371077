import { useNavigate, useRouteError } from "react-router-dom";
import { GHButton } from "../../components/GHSystem";

const PhotoError = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  return (
    <div
      className={`flex justify-center fixed z-[1030] w-screen h-screen text-white py-5 bg-gh-black-100`}
    >
      <div className="container w-full h-full flex flex-col justify-center items-center ">
        <div className="flex flex-col">
          <p>{error.data.message}</p>
          <div className="flex pt-3">
            <GHButton
              onClick={() => {
                navigate(-1);
              }}
            >
              Kembali
            </GHButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoError;
