import React from "react"
import ListImages from "../assets"
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/outline"
import { useParams, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import axios from "axios"
import { useState } from "react"
import { toast } from "react-toastify"
import Layout from "../components/layout"

function PaymentStatus() {
  const { id, paymentid } = useParams()
  const navigate = useNavigate()
  const [paymentDetail, setPaymentDetail] = useState([])

  const onResendTicket = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/apps/resend-ticket/${paymentid}`
    )
    toast(res.data.message)
  }

  useEffect(() => {
    const getDetail = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/apps/payment/${paymentid}`)
        .then((response) => {
          setPaymentDetail(response.data.payment)
        })
      // .catch((error) => console.log(error));
    }
    getDetail()
  }, [])

  return (
    <Layout>
      <div className="bg-semiblack flex items-center justify-center p-12 text-white ">
        <div className="flex flex-col space-y-12 items-center text-center ">
          <img src={ListImages.Illustration2} alt="" />
          <div>
            <div className="text-[1.5rem]">Transaksi Selesai!</div>
            <div className="text-[1.5rem]">Tickets have been sent to</div>
            <div></div>
          </div>
          <div>
            <div>Haven't received ticket yet?</div>
            <button
              onClick={onResendTicket}
              className="hover:brightness-110 transition-all mt-5 py-3 px-5 text-black bg-primary rounded-md"
            >
              Resend Ticket
            </button>
          </div>
          <div>
            <div>Having trouble receiving the tickets?</div>
            <div className="flex items-center space-x-5 mt-5">
              <div className="flex items-center space-x-2 text-primary">
                <PhoneIcon className="h-[1.5rem] w-auto" />
                <div>(021) 5522181777</div>
              </div>
              <div className="flex items-center space-x-2 text-primary">
                <EnvelopeIcon className="h-[1.5rem] w-auto" />
                <div>sosmed@guehadir.id</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PaymentStatus
