import { useEffect, useState } from "react";

const ArtistList = ({ artists = [] }) => {
  const [remain, setRemain] = useState([]);
  const [showMore, setShowMore] = useState(false);
  useEffect(() => {
    setRemain(artists.slice(3));
  }, [artists]);
  return (
    <div
      className="p-5 flex flex-col space-y-3 bg-[#191919] rounded-md"
      style={{ boxShadow: "0 0 20px black" }}
    >
      {/* Top Level Element */}
      <div
        className={`${
          showMore ? "block" : "hidden"
        } transition-all bg-black top-0 left-0 bg-opacity-40 fixed z-[1021] h-screen w-screen flex items-center justify-center`}
      >
        <div className="flex flex-col bg-gh-black-200 rounded-lg max-w-[608px] p-6 max-h-screen overflow-y-auto">
          <div className="flex pb-3 mb-4 justify-between border-b-[1px] border-b-primary border-dashed">
            <p>Line Up Artist</p>
            <div onClick={() => setShowMore(false)} className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3">
            {artists.map((item, index) => (
              <div
                key={index}
                className="flex flex-row items-center [&>*]:mr-2 max-h-[274px] "
              >
                <img
                  className="h-8 w-8 rounded-full"
                  src={item.artist.photo}
                  alt={item.artist.name}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src =
                      "https://ui-avatars.com/api/?background=FC8300&color=fff&name=" +
                      item.artist.name;
                  }}
                />
                <span className="line-clamp-1">{item.artist.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Top Level Element */}
      <div>Line Up Artist</div>
      {artists.slice(0, 3).map((item, index) => (
        <div key={index} className="flex flex-row align-middle [&>*]:mr-2 ">
          <img
            className="h-8 w-8 rounded-full"
            src={item.artist.photo}
            alt={item.artist.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "https://ui-avatars.com/api/?background=FC8300&color=fff&name=" +
                item.artist.name;
            }}
          />
          <span className="line-clamp-1">{item.artist.name}</span>
        </div>
      ))}
      {remain.length ? (
        <div
          onClick={() => setShowMore(true)}
          className="cursor-pointer"
        >{`${remain.length} More`}</div>
      ) : null}
    </div>
  );
};

export default ArtistList;
