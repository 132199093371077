import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Base64 } from "js-base64";
import { useState } from "react";

const Separator = () => {
  return <div className="my-3 h-[1px] w-full bg-[#434343]"></div>;
};

const Instruction = ({ title, step, index }) => {
  const [show, setShow] = useState(true);
  return (
    <>
      {index === 0 ? null : <Separator />}
      <div className="mt-2">
        <div
          className="flex items-center justify-between mt-2 cursor-pointer"
          onClick={(e) => setShow((old) => !old)}
        >
          <div>{title}</div>
          <ChevronDownIcon
            id="atm"
            className={`${
              show ? "rotate-[180deg]" : "rotate-0"
            } transition-all h-[1.25rem] w-auto cursor-pointer`}
          />
        </div>
        <div
          className={`${
            show ? "max-h-[100rem]" : "max-h-0 overflow-hidden"
          } transition-all flex flex-col space-y-1 mt-1`}
        >
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: Base64.decode(step),
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

const InstructionList = ({ title, instructions }) => {
  return (
    <div
      style={{ boxShadow: "0 0 20px black" }}
      className="w-full p-4 text-white flex flex-col bg-[#191919] mt-12"
    >
      <div className="flex items-center space-x-3">
        <div className="h-[20px] w-[5px] bg-primary"></div>
        <div className="py-1">{title}</div>
      </div>
      {instructions.map((instruction, index) => {
        return (
          <Instruction
            key={index}
            title={instruction.title}
            step={instruction.step64}
            index={index}
          />
        );
      })}
    </div>
  );
};

const PaymentInstruction = ({ bankCode }) => {
  const instructionsData = {
    "014": {
      title: "Cara pembayaran dengan BCA - Virtual Account",
      instructions: [
        {
          title: "Pembayaran melalui ATM",
          step64:
            "PHA+MS4gSW5wdXQga2FydHUgPGI+QVRNPC9iPiBkYW4gPGI+UElOPC9iPiBBbmRhPC9wPjxwPjIuIFBpbGloIG1lbnUgPGI+VHJhbnNha3NpIGxhaW4gbGFpbm55YTwvYj48L3A+PHA+My4gUGlsaWggPGI+VHJhbnNmZXI8L2I+PC9wPjxwPjQuIElucHV0IDxiPk5vbW9yIFZpcnR1YWwgQWNjb3VudDwvYj48L3A+PHA+NS4gUGlsaWggPGI+QmVuYXI8L2I+PC9wPjxwPjYuIFBpbGloIDxiPkl5YTwvYj48L3A+PHA+Ny4gQW1iaWwgPGI+QnVrdGkgQmF5YXI8L2I+IEFuZGE8L3A+PHA+OC4gU2VsZXNhaTwvcD4=",
        },
        {
          title: "Pembayaran melalui Mobile Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+TW9iaWxlPC9iPiZuYnNwOzxzcGFuIHN0eWxlPSJmb250LXdlaWdodDogNzAwOyI+QmFua2luZzwvc3Bhbj48L3A+PHA+Mi4gUGlsaWggbWVudSA8Yj5tLVRyYW5zZmVyPC9iPjwvcD48cD4zLiBQaWxpaCA8Yj5CQ0EgVmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD40LiBJbnB1dCZuYnNwOzxzcGFuIHN0eWxlPSJmb250LXdlaWdodDogNzAwOyI+Tm9tb3IgVmlydHVhbCBBY2NvdW50PC9zcGFuPjwvcD48cD41LiBLbGlrIDxiPlNlbmQ8L2I+PC9wPjxwPjYuIDxiPkluZm9ybWFzaSBWaXJ0dWFsIEFjY291bnQgPC9iPmFrYW4gZGl0YW1waWxrYW48L3A+PHA+Ny4gTWFzdWtrYW4gPGI+T0s8L2I+PC9wPjxwPjguIElucHV0Jm5ic3A7PGI+UElOIDwvYj5Nb2JpbGUgYmFua2luZzwvcD48cD45LiZuYnNwOzxzcGFuIHN0eWxlPSJmb250LXdlaWdodDogNzAwOyI+QnVrdGkgQmF5YXI8L3NwYW4+Jm5ic3A7YWthbiBkaXRhbXBpbGthbjwvcD48cD4xMC4gU2VsZXNhaTwvcD4=",
        },
        {
          title: "Pembayaran melalui Internet Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+SW50ZXJuZXQgQmFua2luZzwvYj48L3A+PHA+Mi4gUGlsaWggPGI+VHJhbnNha3NpIGRhbmE8L2I+PC9wPjxwPjMuIFBpbGloIFRyYW5zZmVyIGtlJm5ic3A7PHNwYW4gc3R5bGU9ImZvbnQtd2VpZ2h0OiA3MDA7Ij5CQ0EgVmlydHVhbCBBY2NvdW50PC9zcGFuPjwvcD48cD40LiBJbnB1dCZuYnNwOzxiPk5vbW9yIFZpcnR1YWwgQWNjb3VudDwvYj48L3A+PHA+NS4gS2xpayA8Yj5MYW5qdXRrYW48L2I+PC9wPjxwPjYuIElucHV0Jm5ic3A7PGI+UmVzcG9uIEtleSBCQ0EgQXBwbGkgMTwvYj48L3A+PHA+Ny4gS2xpayZuYnNwOzxzcGFuIHN0eWxlPSJmb250LXdlaWdodDogNzAwOyI+S2lyaW08L3NwYW4+PC9wPjxwPjguJm5ic3A7PHNwYW4gc3R5bGU9ImZvbnQtd2VpZ2h0OiA3MDA7Ij5CdWt0aSBCYXlhcjwvc3Bhbj4mbmJzcDtha2FuIGRpdGFtcGlsa2FuPC9wPjxwPjkuIFNlbGVzYWk8L3A+",
        },
      ],
    },
    "002": {
      title: "Cara pembayaran dengan BRI - Virtual Account",
      instructions: [
        {
          title: "Pembayaran melalui ATM",
          step64:
            "PHA+MS4gSW5wdXQga2FydHUgPGI+QVRNPC9iPiBkYW4gPGI+UElOPC9iPiBBbmRhPC9wPjxwPjIuIFBpbGloIG1lbnUgPGI+VHJhbnNha3NpIGxhaW48L2I+PC9wPjxwPjMuIFBpbGloIG1lbnUgPGI+UGVtYmF5YXJhbjwvYj48L3A+PHA+NC4gUGlsaWggbWVudSA8Yj5MYWluLWxhaW48L2I+PC9wPjxwPjUuIFBpbGloIG1lbnUgPGI+QlJJVkE8L2I+PC9wPjxwPjYuIE1hc3Vra2FuIDxiPk5vbW9yIFZpcnR1YWwgQWNjb3VudDwvYj48L3A+PHA+Ny4gUGlsaWggPGI+WWE8L2I+PC9wPjxwPjguIEFtYmlsIDxiPkJ1a3RpIEJheWFyPC9iPjwvcD48cD45LiBTZWxlc2FpPC9wPg==",
        },
        {
          title: "Pembayaran melalui Mobile Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+QlJJIE1vYmlsZTwvYj48L3A+PHA+Mi4gUGlsaWggPGI+TW9iaWxlIEJhbmtpbmcgQlJJPC9iPjwvcD48cD4zLiBQaWxpaCBtZW51IDxiPlBlbWJheWFyYW48L2I+PC9wPjxwPjQuIFBpbGloIG1lbnUgPGI+QlJJVkE8L2I+PC9wPjxwPjUuIE1hc3Vra2FuIDxiPk5vbW9yIFZpcnR1YWwgQWNjb3VudDwvYj48L3A+PHA+Ni4gTWFzdWtrYW4gbm9taW5hbCwgbWlzYWwgPGI+MTAwMDAwPC9iPjwvcD48cD43LiBLbGlrIDxiPktpcmltPC9iPjwvcD48cD44LiBNYXN1a2thbiA8Yj5QSU4gQlJJIE1vYmlsZTwvYj48L3A+PHA+OS4gS2xpayA8Yj5LaXJpbTwvYj48L3A+PHA+MTAuIEJ1a3RpIEJheWFyIGthbiBkaWtpcmltIG1lbGFsdWkgc21zPC9wPjxwPjExLiBTZWxlc2FpPC9wPg==",
        },
        {
          title: "Pembayaran melalui Internet Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+SW50ZXJuZXQgQmFua2luZzwvYj48L3A+PHA+Mi4gUGlsaWggbWVudSZuYnNwOzxzcGFuIHN0eWxlPSJmb250LXdlaWdodDogNzAwOyI+UGVtYmF5YXJhbjwvc3Bhbj48L3A+PHA+My4gUGlsaWggbWVudSZuYnNwOzxzcGFuIHN0eWxlPSJmb250LXdlaWdodDogNzAwOyI+QlJJVkE8L3NwYW4+PC9wPjxwPjQuIE1hc3Vra2FuIDxiPk5vbW9yIFZpcnR1YWwgQWNjb3VudDwvYj48L3A+PHA+NS4gS2xpayA8Yj5LaXJpbTwvYj48L3A+PHA+Ni4gTWFzdWtrYW4gPGI+UGFzc3dvcmQ8L2I+PC9wPjxwPjcuIE1hc3Vra2FuIDxiPm1Ub2tlbjwvYj48L3A+PHA+OC4gS2xpayA8Yj5LaXJpbTwvYj48L3A+PHA+OS4gPGI+QnVrdGkgQmF5YXI8L2I+IGFrYW4gZGl0YW1waWxrYW48L3A+PHA+MTAuIFNlbGVzYWk8L3A+",
        },
      ],
    },
    "022": {
      title: "Cara pembayaran dengan CIMB NIAGA - Virtual Account",
      instructions: [
        {
          title: "Pembayaran melalui ATM",
          step64:
            "PHA+MS4gSW5wdXQga2FydHUgPGI+QVRNPC9iPiBkYW4gPGI+UElOPC9iPiBBbmRhPC9wPjxwPjIuIFBpbGloIG1lbnUgPGI+UGVtYmF5YXJhbjwvYj48L3A+PHA+My4gUGlsaWggPGI+TGFuanV0PC9iPjwvcD48cD40LiBQaWxpaCBtZW51PGI+Jm5ic3A7VmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD41LiBJbnB1dCZuYnNwOzxzcGFuIHN0eWxlPSJmb250LXdlaWdodDogNzAwOyI+Tm9tb3IgVmlydHVhbCBBY2NvdW50PC9zcGFuPjwvcD48cD42LiBQaWxpaCA8Yj5Qcm9zZXM8L2I+PC9wPjxwPjcuIDxiPkRhdGEgVmlydHVhbCBBY2NvdW50IDwvYj5ha2FuIGRpdGFtcGlsa2FuPC9wPjxwPjguIFBpbGloIDxiPlByb3NlczwvYj48L3A+PHA+OS4gQW1iaWwgPGI+QnVrdGkgQmF5YXI8L2I+PC9wPjxwPjEwLiBTZWxlc2FpPC9wPg==",
        },
        {
          title: "Pembayaran melalui Mobile Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+R28gTW9iaWxlPC9iPjwvcD48cD4yLiBQaWxpaCBtZW51IDxiPlRyYW5zZmVyPC9iPjwvcD48cD4zLiBQaWxpaCBtZW51IDxiPlRyYW5zZmVyIGtlIENJTUIgTmlhZ2EgTGFpbjwvYj48L3A+PHA+NC4gUGlsaWggc3VtYmVyIGRhbmEgeWFuZyBha2FuIGRpZ3VuYWthbjwvcD48cD41LiBNYXN1a2thbiA8Yj5Ob21vciBWaXJ0dWFsIEFjY291bnQ8L2I+PC9wPjxwPjYuIE1hc3Vra2FuIG5vbWluYWwsIG1pc2FsIDxiPjEwMDAwMDwvYj48L3A+PHA+Ny4gS2xpayA8Yj5MYW5qdXQ8L2I+PC9wPjxwPjguJm5ic3A7PHNwYW4gc3R5bGU9ImZvbnQtd2VpZ2h0OiA3MDA7Ij5EYXRhIFZpcnR1YWwgQWNjb3VudCZuYnNwOzwvc3Bhbj5ha2FuIGRpdGFtcGlsa2FuPC9wPjxwPjkuIE1hc3Vra2FuIDxiPlBJTiBNb2JpbGU8L2I+PC9wPjxwPjEwLiA8Yj5CdWt0aSBCYXlhciA8L2I+YWthbiBkaWtpcmltIG1lbGFsdWkgU01TPC9wPjxwPjExLiBTZWxlc2FpPC9wPg==",
        },
        {
          title: "Pembayaran melalui Internet Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+SW50ZXJuZXQgQmFua2luZzwvYj48L3A+PHA+Mi4gUGlsaWggPGI+QmF5YXIgVGFnaWhhbjwvYj48L3A+PHA+My4gUGlsaWggUmVrZW5pbmcgU3VtYmVyIHlhbmcgYWthbiBBbmRhIGd1bmFrYW48L3A+PHA+NC4gUGlsaWggPGI+VmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD41LiBVbnR1ayBQZW1iYXlhcmFuIC0gUGlsaWggPGI+TWFzdWtrYW4gTm9tb3IgVmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD42LiBJc2kmbmJzcDs8Yj5SZW1hcmsgPC9iPmppa2EgZGlwZXJsdWthbjwvcD48cD43LiBLbGlrIDxiPkxhbmp1dDwvYj48L3A+PHA+OC4mbmJzcDs8c3BhbiBzdHlsZT0iZm9udC13ZWlnaHQ6IDcwMDsiPkRhdGEgVmlydHVhbCBBY2NvdW50Jm5ic3A7PC9zcGFuPmFrYW4gZGl0YW1waWxrYW48L3A+PHA+OS4gTWFzdWtrYW4gPGI+bVBpbjwvYj48L3A+PHA+MTAuIEtsaWsgPGI+S2lyaW08L2I+PC9wPjxwPjExLjxiPiBCdWt0aSBCYXlhciA8L2I+YWthbjwvcD48cD4xMi4gU2VsZXNhaTwvcD4=",
        },
      ],
    },
    "009": {
      title: "Cara pembayaran dengan BNI - Virtual Account",
      instructions: [
        {
          title: "Pembayaran melalui ATM",
          step64:
            "PHA+MS4gTWFzdWtrYW4ga2FydHUgQW5kYTwvcD48cD4yLiBQaWxpaCA8Yj5CYWhhc2E8L2I+PC9wPjxwPjMuIE1hc3Vra2FuIDxiPlBJTjwvYj4gQVRNIEFuZGE8L3A+PHA+NC4gUGlsaWggPGI+TWVudSBsYWlubnlhPC9iPjwvcD48cD41LiBQaWxpaCA8Yj5UcmFuc2ZlcjwvYj48L3A+PHA+Ni4gUGlsaWggPGI+SmVuaXMgcmVrZW5pbmc8L2I+Jm5ic3A7IHlhbmcgYWthbiBBbmRhIGd1bmFrYW4gKENvbnRvaDsg4oCcRGFyaSBSZWtlbmluZyBUYWJ1bmdhbuKAnSk8L3A+PHA+Ny4gUGlsaWggPGI+VmlydHVhbCBBY2NvdW50IEJpbGxpbmc8L2I+PC9wPjxwPjguIE1hc3Vra2FuIDxiPk5vbW9yIFZpcnR1YWwgQWNjb3VudDwvYj4gQW5kYSwgY29udG9oOiA8Yj4xMjM0NTY3ODkwMTJYWFhYPC9iPjwvcD48cD45LiBUYWdpaGFuIHlhbmcgaGFydXMgZGliYXlhcmthbiBha2FuIG11bmN1bCBwYWRhIGxheWFyIGtvbmZpcm1hc2k8L3A+PHA+MTAuIEtvbmZpcm1hc2ksIGFwYWJpbGEgdGVsYWggc2VzdWFpLCBsYW5qdXRrYW4gdHJhbnNha3NpPC9wPjxwPjExLiBUcmFuc2Frc2kgQW5kYSB0ZWxhaCBzZWxlc2FpPC9wPg==",
        },
        {
          title: "Pembayaran melalui Mobile Banking",
          step64:
            "PHA+MS4gQWtzZXMgQk5JIE1vYmlsZSBCYW5raW5nIGRhcmkgaGFuZHBob25lIGtlbXVkaWFuIG1hc3Vra2FuPGI+IFVzZXIgSUQgZGFuIFBhc3N3b3JkPC9iPjwvcD48cD4yLiBQaWxpaCBtZW51IDxiPlRyYW5zZmVyPC9iPjwvcD48cD4zLiBQaWxpaCBtZW51IDxiPlZpcnR1YWwgQWNjb3VudCBCaWxsaW5nPC9iPiBrZW11ZGlhbiBwaWxpaCByZWtlbmluZyBkZWJldDwvcD48cD40LiBNYXN1a2thbiA8Yj5Ob21vciBWaXJ0dWFsIEFjY291bnQ8L2I+ICwgbWlzYWwgPGI+NzAwMTRYWFhYWFhYWFhYWDwvYj4gcGFkYSBtZW51IDxiPklucHV0IEJhcnU8L2I+PC9wPjxwPjUuIFRhZ2loYW4geWFuZyBoYXJ1cyBkaWJheWFya2FuIGFrYW4gbXVuY3VsIHBhZGEgbGF5YXIga29uZmlybWFzaTwvcD48cD42LiBLb25maXJtYXNpIHRyYW5zYWtzaSBkYW4gbWFzdWtrYW4gUGFzc3dvcmQgVHJhbnNha3NpPC9wPjxwPjcuIFBlbWJheWFyYW4gQW5kYSBUZWxhaCBCZXJoYXNpbDwvcD4=",
        },
        {
          title: "Pembayaran melalui Internet Banking",
          step64:
            "PHA+MS4gS2V0aWsgYWxhbWF0IDxhIGhyZWY9Imh0dHBzOi8vaWJhbmsuYm5pLmNvLmlkIiB0YXJnZXQ9Il9ibGFuayI+aHR0cHM6Ly9pYmFuay5ibmkuY28uaWQ8L2E+PGI+Jm5ic3A7PC9iPmtlbXVkaWFuIGtsaWsgRW50ZXI8L3A+PHA+Mi4gTWFzdWtrYW4gPGI+VXNlciBJRDwvYj4gZGFuIDxiPlBhc3N3b3JkPC9iPjwvcD48cD4zLiBQaWxpaCBtZW51IDxiPlRyYW5zZmVyPC9iPjwvcD48cD40LiBQaWxpaCA8Yj5WaXJ0dWFsIEFjY291bnQgQmlsbGluZzwvYj48L3A+PHA+NS4gS2VtdWRpYW4gbWFzdWthbiA8Yj5Ob21vciBWaXJ0dWFsIEFjY291bnQ8L2I+IEFuZGEsIG1pc2FsbnlhIDxiPjcwMDE0WFhYWFhYWFhYWFgmbmJzcDs8L2I+eWFuZyBoZW5kYWsgZGliYXlhcmthbi4gTGFsdSBwaWxpaCByZWtlbmluZyBkZWJldCB5YW5nIGFrYW4gZGlndW5ha2FuLiBLZW11ZGlhbiB0ZWthbiA8Yj5sYW5qdXQ8L2I+PC9wPjxwPjYuIEtlbXVkaW4gdGFnaWhhbiB5YW5nIGhhcnVzIGRpYmF5YXJrYW4gYWthbiBtdW5jdWwgcGFkYSBsYXlhciBrb25maXJtYXNpLjwvcD48cD43LiBNYXN1a2thbiA8Yj5Lb2RlIE90ZW50aWthc2kgVG9rZW48L2I+PC9wPjxwPjguIFBlbWJheWFyYW4gQW5kYSB0ZWxhaCBiZXJoYXNpbDwvcD4=",
        },
        {
          title: "Pembayaran melalui SMS Banking",
          step64:
            "PHA+MS4gQnVrYSBhcGxpa2FzaSBTTVMgQmFua2luZyBCTkk8L3A+CjxwPjIuIFBpbGloIG1lbnUKICAgIDxzdHJvbmc+VHJhbnNmZXI8L3N0cm9uZz4KPC9wPgo8cD4zLiBQaWxpaCBtZW51CiAgICA8c3Ryb25nPlRyZiByZWtlbmluZyBCTkk8L3N0cm9uZz4KPC9wPgo8cD40LiBNYXN1a2thbgogICAgPHN0cm9uZz5Ob21vciByZWtlbmluZyB0dWp1YW48L3N0cm9uZz5kZW5nYW4KICAgIDxzdHJvbmc+MTYgZGlnaXQgTm9tb3IgVmlydHVhbCBBY2NvdW50PC9zdHJvbmc+LCBjb250b2g6IDEyMzQ1Njc4OTEyWFhYPC9wPgo8cD41LiBNYXN1a2FuCiAgICA8c3Ryb25nPk5vbWluYWw8L3N0cm9uZz50cmFuc2ZlciBzZXN1YWkgdGFnaWhhbiBhdGF1IGtld2FqaWJhbiBBbmRhLiBOb21pbmFsIHlhbmcgYmVyYmVkYSB0aWRhayBkYXBhdCBkaXByb3NlczwvcD4KPHA+Ni4gUGlsaWgKICAgIDxzdHJvbmc+UHJvc2VzPC9zdHJvbmc+a2VtdWRpYW4KICAgIDxzdHJvbmc+U2V0dWp1PC9zdHJvbmc+CjwvcD4KPHA+Ny4gUmVwbHkgc21zIGRlbmdhbiBrZXRpawogICAgPHN0cm9uZz5QSU48L3N0cm9uZz5zZXVzdWFpIHBlcmludGFoPC9wPgo8cD44LiBUcmFuc2Frc2kgQmVyaGFzaWw8L3A+CjxwPkF0YXUgRGFwYXQganVnYSBsYW5nc3VuZyBtZW5nZXRpayBzbXMgZGVuZ2FuIGZvcm1hdDoKICAgIDxzdHJvbmc+VFJGW1NQQVNJXU5vbW9yVkFbU1BBU0ldTk9NSU5BPC9zdHJvbmc+TCBkYW4ga2VtdWRpYW4ga2lyaW0ga2UKICAgIDxzdHJvbmc+MzM0Njwvc3Ryb25nPkNvbnRvaCA6IFRSRiAxMjM0NTY3ODkwMTJYWFhYIDQ0MDAwPC9wPg==",
        },
      ],
    },
    "008": {
      title: "Cara pembayaran dengan MANDIRI - Virtual Account",
      instructions: [
        {
          title: "Pembayaran melalui ATM",
          step64:
            "PHA+MS4gSW5wdXQga2FydHUgQVRNIGRhbiBQSU4gQW5kYTwvcD48cD4yLiBQaWxpaCA8Yj5CYXlhciAvIEJlbGk8L2I+PC9wPjxwPjMuIFBpbGloIDxiPkxhaW5ueWE8L2I+PC9wPjxwPjQuIFBpbGloIDxiPk11bHRpIFBheW1lbnQ8L2I+PC9wPjxwPjUuIElucHV0IDxiPjcwMDE3PC9iPiAsc2ViYWdhaSBLb2RlIGluc3RpdHVzaTwvcD48cD42LiBJbnB1dCA8Yj5Ob21vciBWaXJ0dWFsIEFjY291bnQ8L2I+ICxtaXNhbCA8Yj43MDAxN1hYWFhYWFhYWFhYPC9iPjwvcD48cD43LiBQaWxpaCBCZW5hcjwvcD48cD44LiBQaWxpaCBJeWE8L3A+PHA+OS4gQW1iaWwgQnVrdGkgYmF5YXIgQW5kYTwvcD48cD4xMC4gU2VsZXNhaTwvcD4=",
        },
        {
          title: "Pembayaran melalui Mobile Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+TW9iaWxlIEJhbmtpbmc8L2I+PC9wPjxwPjIuIFBpbGloIDxiPkJheWFyPC9iPjwvcD48cD4zLiBJbnB1dCA8Yj5pLVBheTwvYj4gc2ViYWdhaSA8Yj5QZW55ZWRpYSBqYXNhPC9iPjwvcD48cD40LiBJbnB1dCA8Yj5Ob21vciBWaXJ0dWFsIEFjY291bnQ8L2I+ICwgbWlzYWwgPGI+NzAwMTdYWFhYWFhYWFhYWDwvYj48L3A+PHA+NS4gUGlsaWggPGI+TGFuanV0PC9iPjwvcD48cD42LiBJbnB1dCA8Yj5PVFAgYW5kIFBJTjwvYj48L3A+PHA+Ny4gUGlsaWggPGI+T0s8L2I+PC9wPjxwPjguIDxiPkJ1a3RpIGJheWFyPC9iPiBkaXRhbXBpbGthbjwvcD48cD45LiBTZWxlc2FpPC9wPg==",
        },
        {
          title: "Pembayaran melalui Internet Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+SW50ZXJuZXQgQmFua2luZzwvYj48L3A+PHA+Mi4gUGlsaWggPGI+QmF5YXI8L2I+PC9wPjxwPjMuIFBpbGloIDxiPk11bHRpIHBheW1lbnQ8L2I+PC9wPjxwPjQuIElucHV0IDxiPmktUGF5PC9iPiBzZWJhZ2FpIDxiPlBlbnllZGlhIGphc2E8L2I+PC9wPjxwPjUuIElucHV0IDxiPk5vbW9yIFZpcnR1YWwgQWNjb3VudCA8L2I+LCBtaXNhbCA8Yj43MDAxN1hYWFhYWFhYWFhYPC9iPjwvcD48cD42LiBQaWxpaCA8Yj5MYW5qdXQ8L2I+PC9wPjxwPjcuIElucHV0IDxiPk9UUCBhbmQgUElOPC9iPjwvcD48cD44LiBQaWxpaCA8Yj5PSzwvYj48L3A+PHA+OS4gPGI+QnVrdGkgYmF5YXI8L2I+IGRpdGFtcGlsa2FuPC9wPjxwPjEwLiBTZWxlc2FpPC9wPjxkaXY+PGJyPjwvZGl2Pg==",
        },
        {
          title: "Pembayaran melalui Channel non-Bank Mandiri",
          step64:
            "PHA+MS4gSW5wdXQga2FydHUgQVRNIGRhbiBQSU4gQW5kYTwvcD48cD4yLiBQaWxpaCA8Yj5CYXlhciAvIEJlbGk8L2I+PC9wPjxwPjMuIFBpbGloIDxiPkxhaW5ueWE8L2I+PC9wPjxwPjQuIFBpbGloIDxiPlRyYW5zZmVyIEJhbms8L2I+PC9wPjxwPjUuIFBpbGloIDxiPkJhbmsgTWFuZGlyaSAoMDA4KTwvYj48L3A+PHA+Ni4gTWFzdWtrYW4gPGI+ODgwMTc8L2I+LGRpaWt1dGkgZGVuZ2FuIG5vbW9yIHZpcnR1YWwgYWNjb3VudCwgbWlzYWwgPGI+ODgxNzE0NTAxMDAwMDA5PC9iPjwvcD48cD43LiBNYXN1a2thbiBOb21pbmFsIFBlbWJheWFyYW48L3A+PHA+OC4gTGFrdWthbiBQZW1iYXlhcmFuPC9wPg==",
        },
      ],
    },
    "013": {
      title: "Cara pembayaran dengan Permata Bank - Virtual Account",
      instructions: [
        {
          title: "Pembayaran melalui ATM",
          step64:
            "PHA+MS4gSW5wdXQga2FydHUgPGI+QVRNPC9iPiBkYW4gPGI+UElOPC9iPiBBbmRhPC9wPjxwPjIuIFBpbGloIG1lbnUgPGI+VHJhbnNha3NpIGxhaW48L2I+PC9wPjxwPjMuIFBpbGloIDxiPlBlbWJheWFyYW48L2I+PC9wPjxwPjQuIFBpbGloIDxiPlBlbWJheWFyYW4gbGFpbi1sYWluPC9iPjwvcD48cD41LiBQaWxpaCA8Yj5WaXJ0dWFsIEFjY291bnQ8L2I+PC9wPjxwPjYuIE1hc3Vra2FuIDxiPk5vbW9yIFZpcnR1YWwgQWNjb3VudDwvYj4gLG1pc2FsPGI+IDg2MjVYWFhYWFhYWFhYPC9iPjwvcD48cD43LiBTZWxlY3QgPGI+QmVuYXI8L2I+PC9wPjxwPjguIFNlbGVjdCA8Yj5ZYTwvYj48L3A+PHA+OS4gQW1iaWw8Yj4gQnVrdGkgQmF5YXI8L2I+IEFuZGE8L3A+PHA+MTAuIFNlbGVzYWk8L3A+PGRpdj48YnI+PC9kaXY+",
        },
        {
          title: "Pembayaran melalui Mobile Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+TW9iaWxlIGJhbmtpbmc8L2I+PC9wPjxwPjIuIFBpbGloPGI+IFBlbWJheWFyYW4gdGFnaWhhbjwvYj48L3A+PHA+My4gUGlsaWggPGI+VmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD40LiBJbnB1dDxiPiBOb21vciBWaXJ0dWFsIEFjY291bnQ8L2I+ICxtaXNhPGI+bCA4NjI1WFhYWFhYWDwvYj4gc2ViYWdhaSA8Yj5Oby4gVmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD41LiBJbnB1dCBub21pbmFsLCBtaXNhbCA8Yj4xMDAwMDA8L2I+PC9wPjxwPjYuIEtsaWsgPGI+S2lyaW08L2I+PC9wPjxwPjcuIElucHV0IDxiPlRva2VuPC9iPjwvcD48cD44LiBrbGlrIDxiPktpcmltPC9iPjwvcD48cD45LiA8Yj5CdWt0aSBCYXlhcjwvYj4gYWthbiBkaXRhbXBpbGthbjwvcD48cD4xMC4gU2VsZXNhaTwvcD48ZGl2Pjxicj48L2Rpdj4=",
        },
        {
          title: "Pembayaran melalui Internet Banking",
          step64:
            "PHA+MS4gTG9naW4gPGI+SW50ZXJuZXQgQmFua2luZzwvYj48L3A+PHA+Mi4gUGlsaWggPGI+UGVtYmF5YXJhbiBUYWdpaGFuPC9iPjwvcD48cD4zLiBQaWxpaCA8Yj5WaXJ0dWFsIEFjY291bnQ8L2I+PC9wPjxwPjQuIElucHV0PGI+IE5vbW9yIFZpcnR1YWwgQWNjb3VudDwvYj4sIG1pc2FsIDxiPjg2MjVYWFhYWDwvYj4gc2ViYWdhaSA8Yj5Oby4gVmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD41LiBJbnB1dCBub21pbmFsLCBtaXNhbCA8Yj41MDAwMDwvYj48L3A+PHA+Ni4gS2xpayA8Yj5LaXJpbTwvYj48L3A+PHA+Ny4gSW5wdXQgPGI+VG9rZW48L2I+PC9wPjxwPjguIEtsaWsgPGI+S2lyaW08L2I+PC9wPjxwPjkuIDxiPkJ1a3RpIEJheWFyPC9iPiBha2FuIGRpdGFtcGlsa2FuPC9wPjxwPjEwLiBTZWxlc2FpPC9wPjxkaXY+PGJyPjwvZGl2Pg==",
        },
      ],
    },
    "011": {
      title: "Cara pembayaran dengan Bank Danamon - Virtual Account",
      instructions: [
        {
          title:
            "Pembayaran melalui ATM Bank Danamon menggunakan Kartu Bank Danamon",
          step64:
            "PHA+MS4gSW5wdXQga2FydHUgPGI+QVRNPC9iPiBkYW48Yj4gUElOJm5ic3A7PC9iPkFuZGE8L3A+PHA+Mi4gUGlsaWggTWVudTxiPiBQZW1iYXlhcmFuPC9iPiAmZ3Q7Jmd0OyZndDsgPGI+VmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD4zLiBNYXN1a2FuIDxiPk5vbW9yIFZpcnR1YWwgQWNjb3VudDwvYj48L3A+PHA+NC4gTWFzdWtrYW4gPGI+Tm9taW5hbDwvYj48L3A+PHA+NS4gUGFkYSBsYXlhciBrb25maXJtYXNpIHBlbWJheWFyYW4sIHBhc3Rpa2FuIHRyYW5zYWtzaSBzdWRhaCBiZW5hciAtJmd0OyBwaWxpaCA8Yj5ZQTwvYj4gdW50dWsgbWVtcHJvc2VzIHRyYW5zYWtzaTwvcD4=",
        },
        {
          title: "Pembayaran melalui Aplikasi D-Mobile",
          step64:
            "PHA+MS4gTG9naW4gcGFkYTxiPiBBcGxpa2FzaSBELU1vYmlsZTwvYj48L3A+PHA+Mi4gUGlsaWggbWVudSA8Yj5WaXJ0dWFsIEFjY291bnQ8L2I+PC9wPjxwPjMuIE1hc3VrYW4gPGI+MTYgZGlnaXQ8L2I+Jm5ic3A7PGI+Tm9tb3IgVmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD40LiBNYXN1a2FuIDxiPk5vbWluYWw8L2I+PC9wPjxwPjUuIFBhZGEgbGF5YXIga29uZmlybWFzaSBwZW1iYXlhcmFuLCBwYXN0aWthbiB0cmFuc2Frc2kgc3VkYWggYmVuYXIgLSZndDsgcGlsaWggPGI+WWE8L2I+IHVudHVrIG1lbXByb3NlcyB0cmFuc2Frc2kuPC9wPjxkaXY+PGJyPjwvZGl2Pg==",
        },
        {
          title:
            "Pembayaran melalui ATM Bank Danamon menggunakan kartu Bank Lain",
          step64:
            "PHA+MS4gTWFzdWsga2UgbWVudSA8Yj50cmFuc2ZlcjwvYj48L3A+PHA+Mi4gUGlsaWggdHVqdWFuIHJla2VuaW5nIDxiPkJhbmsgRGFuYW1vbjwvYj48L3A+PHA+My4gTWFzdWtrYW4gS29kZSBCYW5rIERhbmFtb248Yj4gKDAxMSk8L2I+ICsgPGI+MTYgZGlnaXQgbm9tb3IgVmlydHVhbCBBY2NvdW50PC9iPjwvcD48cD40LiBNYXN1a2thbiA8Yj5ub21pbmFsIHBlbWJheWFyYW48L2I+PC9wPjxwPjUuIFBhZGEgbGF5YXIga29uZmlybWFzaSBwZW1iYXlhcmFuLCBoYXJhcCBwYXN0aWthbiBuYW1hIHR1anVhbiBkYW4gbm9taW5hbCB0cmFuc2Frc2kgc3VkYWggdGVwYXQ8L3A+PHA+Ni4gS29uZmlybWFzaSBwZW1iYXlhcmFuPC9wPjxkaXY+PGJyPjwvZGl2Pg==",
        },
      ],
    },
    CREDITCARD: {
      title: "Cara pembayaran dengan CREDIT CARD",
      instructions: [
        {
          title: "Pembayaran melalui GUEHADIR Website",
          step64:
            "PHA+MS4gU2V0ZWxhaCBQZW1iZWxpYW4gVGlrZXQgYWthbiBtdW5jdWwgamVuZGVsYSBiYXJ1IHVudHVrIHBlbWJheWFyYW4gPGI+Q3JlZGl0IENhcmQsIDwvYj5BcGFiaWxhIGplbmRlbGEgdGVydGV0dXAgamVuZGVsYSBwZW1iYXlhcmFuIGRhcGF0IGRpYWtzZXMga2VtYmFsaSBkZW5nYW4gbWVuZWthbiB0b21ib2wgYmF5YXIgcGFkYSBoYWxhbWFuIHBheW1lbnQgeWFuZyBkYXBhdCBkaWFrc2VzIG1lbGF1aSBlbWFpbCBpbnZvaWNlPC9wPjxwPjIuIDxiPkluZm9ybWFzaTwvYj4gQW5kYSBha2FuIGRpdGFtcGlsa2FuPC9wPjxwPjMuIFBpbGloIDxiPkNvbnRpbnVlIFBheW1lbnQ8L2I+PC9wPjxwPjQuSXNpIGRhdGEgPGI+Q3JlZGl0IENhcmQ8L2I+IEFuZGE8L3A+PHA+NS4gU2VsZXNhaTwvcD48ZGl2Pjxicj48L2Rpdj4=",
        },
      ],
    },
    GOPAY: {
      title: "Cara pembayaran dengan GOPAY",
      instructions: [
        {
          title: "Pembayaran melalui GUEHADIR Website",
          step64:
            "PHA+MS4gU2V0ZWxhaCBQZW1iZWxpYW4gVGlrZXQgYWthbiBtdW5jdWwgamVuZGVsYSBiYXJ1IHVudHVrIHBlbWJheWFyYW4gPGI+R09QQVkgPC9iPnlhbmcgbWVuYW1waWxrYW4gPGI+UVJJUzwvYj4gdW50dWsgcGVtYmF5YXJhbiwgQXBhYmlsYSBqZW5kZWxhIHRlcnRldHVwIGplbmRlbGEgcGVtYmF5YXJhbiBkYXBhdCBkaWFrc2VzIGtlbWJhbGkgZGVuZ2FuIG1lbmVrYW4gdG9tYm9sIGJheWFyIHBhZGEgaGFsYW1hbiBwYXltZW50IHlhbmcgZGFwYXQgZGlha3NlcyBtZWxhdWkgZW1haWwgaW52b2ljZTwvcD48cD4yLiBCdWthIGFwbGlrYXNpIDxiPkdvamVrPC9iPiBsYWx1IHBpbGloIG1lbnUgPGI+QmF5YXI8L2I+PC9wPjxwPjMuIFNldGVsYWggaXR1IHBpbmRhaSA8Yj5RUklTPC9iPiB5YW5nIGJpYXNhbnlhIGFkYSBsYXlhciBBbmRhLjwvcD48cD40LiBNYXN1a2thbjxiPiBub21pbmFsPC9iPiB0cmFuc2Frc2kgZGFuIHBhc3Rpa2FuIG5vbWluYWwgdGVsYWggc2VzdWFpIHNlYmVsdW0gbGFuanV0IGtlIGxhbmdrYWggYmVyaWt1dG55YS48L3A+PHA+NS4gS2lsaWsgPGI+S29uZmlybWFzaSAmYW1wOyBCYXlhcjwvYj48L3A+PHA+Ni4gTWFzdWtrYW4ga29kZSA8Yj5QSU48L2I+IEdvcGF5LjwvcD48cD43LiBTZWxlc2FpPC9wPg==",
        },
      ],
    },
  };
  let instruction = instructionsData[bankCode] ?? instructionsData["014"];
  return (
    <InstructionList
      title={instruction.title}
      instructions={instruction.instructions}
    />
  );
};

export default PaymentInstruction;
