import { Link, useNavigate } from "react-router-dom";
import PhotoThumbnail from "./PhotoThumbnail";

function PhotoSection({ photos, count, leftText, rightText, redirectUrl }) {
  const slicedPhoto = photos.slice(0, 4);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col space-y-6">
      <div className="flex justify-between items-baseline">
        <p className="font-montserrat font-semibold text-2xl text-secondary">
          {leftText}
        </p>
        <Link className="text-primary text-right" to={redirectUrl}>
          {rightText}
        </Link>
      </div>
      <div
        className="grid 
      grid-cols-2 lg:grid-cols-4
      gap-4"
      >
        {slicedPhoto.map((photo, index) => {
          return (
            <PhotoThumbnail
              isLast={index === slicedPhoto.length - 1}
              key={index}
              photo={photo}
              count={count}
              listPageUrl={redirectUrl}
            />
          );
        })}
      </div>
    </div>
  );
}

export default PhotoSection;
