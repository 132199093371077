import axios from 'axios';
import catchAxiosError from './catchAxiosError';

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')
  if (!refreshToken) {
    return logOut()
  }
  const response = await axios({
    url: `${process.env.REACT_APP_BASE_URL}/apps/refresh`,
    method: 'POST',
    data: { refresh_token: refreshToken }
  }).catch(catchAxiosError)
  if (response.status === 400) {
    return logOut()
  }
  localStorage.setItem("token", response.data.accessToken);
  localStorage.setItem("refreshToken", response.data.refreshToken);
  return response.data.accessToken
}

const logOut = () => {
  localStorage.clear();
  setTimeout(() => {
    window.location.reload();
  }, 2000)
}

const axiosWithInterceptor = () => {
  let api = axios.create();
  api.interceptors.response.use(
    response => response,
    (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return refreshToken().then(({ token }) => {
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return api(originalRequest);
        }).catch(() => {
          logOut()
        });
      }
      return Promise.reject(error);
    }
  );
  
  return api
}


export default axiosWithInterceptor;
