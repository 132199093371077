export function SkeletonAllPhoto() {
  return (
    <div className="flex flex-col w-full gap-6">
      {/* section1 */}
      <div className="flex flex-row mt-1 items-center justify-between">
        <div className="animate-pulse w-48 h-8 rounded-sm bg-zinc-700" />
        <div className="animate-pulse w-40 h-5 rounded-sm bg-zinc-700" />
      </div>
      <div className="grid grid-cols-4 gap-5">
        <div className="animate-pulse w-full h-60 rounded-lg bg-zinc-700" />
        <div className="animate-pulse w-full h-60 rounded-lg bg-zinc-700" />
        <div className="animate-pulse w-full h-60 rounded-lg bg-zinc-700" />
        <div className="animate-pulse w-full h-60 rounded-lg bg-zinc-700" />
      </div>
      {/* endsection1 */}
      {/* section2 */}
      <div className="flex flex-row mt-1 items-center justify-between">
        <div className="animate-pulse w-48 h-8 rounded-sm bg-zinc-700" />
        <div className="animate-pulse w-40 h-5 rounded-sm bg-zinc-700" />
      </div>
      <div className="grid grid-cols-4 gap-5">
        <div className="animate-pulse w-full h-60 rounded-lg bg-zinc-700" />
        <div className="animate-pulse w-full h-60 rounded-lg bg-zinc-700" />
        <div className="animate-pulse w-full h-60 rounded-lg bg-zinc-700" />
        <div className="animate-pulse w-full h-60 rounded-lg bg-zinc-700" />
      </div>
      {/* endsection2 */}
    </div>
  )
}
