import { useStoreState } from "easy-peasy";
import PhotoThumbnail from "../../PhotoThumbnail";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function CollectionSection() {
  const photoCart = useStoreState((state) => state.photoCart);
  const [photoKeys, setPhotoKeys] = useState([]);
  useEffect(() => {
    setPhotoKeys(Object.keys(photoCart));
  }, [photoCart]);
  return photoKeys.length !== 0 ? (
    <div className="flex flex-col space-y-6">
      <div className="flex justify-between items-baseline">
        <p className="font-montserrat font-semibold text-2xl text-secondary">
          Your Collection
        </p>
        <Link to={"/photos/your-collection"} className="text-primary">
          Lihat Collection
        </Link>
      </div>
      <div
        className="grid 
  grid-cols-2 lg:grid-cols-4 
  grid-rows-2 lg:grid-rows-1
  gap-4"
      >
        {photoKeys.slice(0, 4).map((photoId, index) => {
          let photo = photoCart[photoId];
          return photo ? (
            <PhotoThumbnail
              isLast={index === photoKeys.slice(0, 4).length - 1 && index !== 0}
              key={index}
              photo={photo.photo}
              count={photoKeys.length}
              listPageUrl={"/photos/your-collection"}
            />
          ) : null;
        })}
      </div>
    </div>
  ) : null;
}

export default CollectionSection;
