import { toast } from "react-toastify";

const catchAxiosError = (error) => {
  if (error.response) {
    return toast(error.response.data.message);
  } else {
    toast("Terjadi kesalahan, mohon coba beberapa saat lagi");
  }
};

export default catchAxiosError;
