export function DetailPhotoSection1() {
  return (
    <div
      className={`max-h-[300px] lg:max-h-[605px] relative w-screen flex justify-center overflow-hidden transition-all`}
    >
      <div className={` transition-all h-[300px] lg:h-[605px]`}>.</div>
      {/* end spacer */}
      <div className="absolute top-0 w-screen bg-zinc-700 animate-pulse flex justify-center">
        <div
          className={`h-[300px] lg:h-[605px] lg:py-4 transition-all object-contain`}
        />
      </div>
    </div>
  )
}
