import {ReactComponent as Eye} from './eye.svg'
import {ReactComponent as EyeSlash} from './eye-slash.svg'
import {ReactComponent as ArrowLeft} from './arrow-left.svg'
import {ReactComponent as ArrowRight} from './arrow-right.svg'

const IconSax = {
    Eye,
    EyeSlash,
    ArrowLeft,
    ArrowRight
}

export default IconSax