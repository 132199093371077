import axios from "axios"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Helmet } from "react-helmet"
import NewsCard from "../components/newsCard"
import Layout from "../components/layout"
import { AllNews } from "../components/partials"

function NewsList() {
  const [newsData, setNewsData] = useState([])
  const [currentFilter, setCurrentFilter] = useState("")
  const [isSkeleton, setIsSkeleton] = useState(true)

  const filterList = [
    {
      id: "",
      name: "All",
    },
    {
      id: "hot-news",
      name: "Hot News",
    },
    {
      id: "music-rock",
      name: "Music Rock",
    },
    {
      id: "prambanan-jazz",
      name: "Prambanan Jazz",
    },
    {
      id: "festival",
      name: "Festival",
    },
    {
      id: "seminar",
      name: "Seminar",
    },
    {
      id: "music-pop",
      name: "Music Pop",
    },
  ]

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/apps/news?category=${currentFilter}`
      )
      setNewsData(response.data.data)
      setIsSkeleton(false)
    }
    getData()
  }, [currentFilter])
  return (
    <Layout>
      <div className="min-h-full min-w-full bg-semiblack">
        <Helmet>
          <title>{`GH News | Gue Hadir`}</title>
          <meta
            name="description"
            content={`Gue Hadir Ticket Experience System`}
          />
        </Helmet>
        <div className="container mx-auto bg-semiblack">
          <div className="p-5 md:p-12 bg-semiblack text-white flex flex-col w-full">
            <div className="text-[1.5rem] font-semibold mb-4">GH News</div>
            <div className="flex overflow-x-auto text-center items-center gap-5 mb-6">
              {filterList.map((x) => {
                return (
                  <div
                    className={`${
                      currentFilter === x.id
                        ? "border border-primary"
                        : "border border-gray-600"
                    } p-2 px-5 w-full md:w-fit rounded-md transition-all cursor-pointer whitespace-nowrap`}
                    id={x.id}
                    onClick={(e) => setCurrentFilter(e.target.id)}
                  >
                    {x.name}
                  </div>
                )
              })}
            </div>
            {isSkeleton ? (
              <AllNews />
            ) : (
              <div
                className="flex flex-col gap-3
            md:grid md:grid-cols-2
            lg:grid-cols-3
            "
              >
                {newsData?.map((x) => {
                  return (
                    <NewsCard
                      image={x.image}
                      title={x.title}
                      date={x.createdAt}
                      category={x.news_category.name}
                      description={x.description}
                      id={x.slug}
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NewsList
