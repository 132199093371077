import { useStoreActions, useStoreState } from "easy-peasy";

const ModalLoading = () => {
  const toggleShowLoadingModal = useStoreActions(
    (state) => state.toggleShowLoadingModal
  );
  const LoadingModal = useStoreState((state) => state.LoadingModal);

  return (
    <div
      className={`fixed inset-0 w-full h-full 
      z-[1030] bg-black bg-opacity-70 flex
       justify-center items-center ${LoadingModal.show ? "block" : "hidden"}`}
    >
      <div className="bg-gh-black-200 text-primary text-center p-4 space-y-4 rounded-md">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
        <p>{LoadingModal.text}</p>
      </div>
    </div>
  );
};

export default ModalLoading;
