export function ShowSkeletonPopular() {
  return (
    <div id="terpopuler" className="flex flex-col ">
      <div className="flex items-center justify-between">
        <div className="animate-pulse h-8 w-36 rounded-full flex space-x-4 bg-zinc-700 line-clamp-2"></div>
        <div className="animate-pulse h-5 w-36 rounded-full flex space-x-4 bg-zinc-700 line-clamp-2"></div>
      </div>
      {/* Content  */}
      <div className="flex flex-1 overflow-x-scroll py-5 gap-3 scrollbar-none">
        <EventCardOneSkeleton />
        <div className="flex lg:flex-col lg:flex-1 gap-3">
          <EventCardVtwoSkeleton />
        </div>
      </div>
    </div>
  )
}
const EventCardOneSkeleton = () => {
  return (
    <div className="hidden lg:flex w-[360px] flex-1 cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex-col">
      <div className="animate-pulse flex-3 h-[150px] w-full object-cover bg-zinc-700"></div>
      <div className="flex">
        <div className="flex flex-col align-middle text-center px-[14px] pt-2 space-y-2">
          <div className="animate-pulse h-4 w-8 rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-8 w-8 rounded-md flex bg-zinc-700"></div>
        </div>
        <div className="flex flex-col flex-4 flex-1 p-3 text-sm space-y-1">
          <div className="animate-pulse h-5 w-full rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-4 w-[50%] rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-4 w-[20%] rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-10 w-[12%] rounded-md flex bg-zinc-700"></div>
          <div className="animate-pulse h-4 w-full rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-4 w-[80%] rounded-full flex bg-zinc-700"></div>

          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="animate-pulse h-3 w-10 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-20 mt-1 rounded-full flex bg-zinc-700"></div>
            </div>
            <div className="animate-pulse h-10 w-32 rounded-md flex bg-zinc-700"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EventCardVtwoSkeleton = ({ isFirst }) => {
  return (
    <>
      <div
        className={`${
          isFirst ? "flex lg:hidden" : "flex"
        }  w-[375px] lg:w-auto lg:h-[180px] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex-col lg:flex-row`}
      >
        <div className="animate-pulse flex-3 h-[150px] lg:h-full lg:flex-none w-full lg:w-[159px] object-cover bg-zinc-700"></div>
        <div className="flex lg:flex-1 flex-1">
          <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase lg:hidden ">
            <div className="animate-pulse h-4 w-8 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-8 w-8 mt-1 rounded-md flex bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-4 flex-1 p-3 text-sm gap-1">
            <div className="animate-pulse w-[90%] h-4 bg-zinc-700 rounded-full"></div>
            <div className="animate-pulse w-[70%] h-4 bg-zinc-700 rounded-full"></div>

            <div className="animate-pulse w-[30%] h-4 bg-zinc-700 rounded-full"></div>
            <div className="animate-pulse h-9 w-[22%] rounded-md flex bg-zinc-700"></div>

            <div className="flex items-center mt-3 justify-between">
              <div>
                <div className="animate-pulse h-3 w-10 rounded-full flex bg-zinc-700"></div>
                <div className="animate-pulse h-4 w-20 mt-1 rounded-full flex bg-zinc-700"></div>
              </div>
              <div className="animate-pulse h-10 w-32 rounded-md flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          isFirst ? "flex lg:hidden" : "flex"
        }  w-[375px] lg:w-auto lg:h-[180px] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex-col lg:flex-row`}
      >
        <div className="animate-pulse flex-3 h-[150px] lg:h-full lg:flex-none w-full lg:w-[159px] object-cover bg-zinc-700"></div>
        <div className="flex lg:flex-1 flex-1">
          <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase lg:hidden ">
            <div className="animate-pulse h-4 w-8 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-8 w-8 mt-1 rounded-md flex bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-4 flex-1 p-3 text-sm gap-1">
            <div className="animate-pulse w-[90%] h-4 bg-zinc-700 rounded-full"></div>
            <div className="animate-pulse w-[70%] h-4 bg-zinc-700 rounded-full"></div>

            <div className="animate-pulse w-[30%] h-4 bg-zinc-700 rounded-full"></div>
            <div className="animate-pulse h-9 w-[22%] rounded-md flex bg-zinc-700"></div>

            <div className="flex items-center mt-3 justify-between">
              <div>
                <div className="animate-pulse h-3 w-10 rounded-full flex bg-zinc-700"></div>
                <div className="animate-pulse h-4 w-20 mt-1 rounded-full flex bg-zinc-700"></div>
              </div>
              <div className="animate-pulse h-10 w-32 rounded-md flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
