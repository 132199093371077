import React, { useEffect, useRef, useState } from "react";
import Footer from "./footer";
import Navigation from "./navigation";
import ScrollToTop from "./scrollToTop";
import { ArrowCircleUp } from "iconsax-react";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLogin from "./ModalLogin";
import { useLocation } from "react-router";
import ModalLoading from "./ModalLoading";

function Layout({ children, showTopButton }) {
  const togglePopover = useStoreActions((action) => action.togglePopover);
  const setScrollEnd = useStoreActions((action) => action.setScrollEnd);
  const location = useLocation();
  const layoutRef = useRef(null);
  const [visibleTopButton, setVisibleTopButton] = useState(false);
  useEffect(() => {
    if (layoutRef.current) {
      layoutRef.current.scrollTo(0, 0);
    }
  }, [location]);

  const onScrollListener = () => {
    setVisibleTopButton(layoutRef?.current?.scrollTop > 500 && showTopButton);
    setScrollEnd(
      layoutRef?.current?.scrollTop + layoutRef.current?.offsetHeight + 300 >=
        layoutRef.current?.scrollHeight
    );
  };

  return (
    // <>
    <div
      ref={layoutRef}
      className="min-h-screen w-screen max-w-full h-screen m-0 p-0 flex flex-col overflow-y-scroll scroll-smooth overflow-x-hidden relative"
      onClick={(e) => {
        togglePopover(e);
      }}
      onScroll={onScrollListener}
    >
      <Navigation />
      <ModalLogin />
      <ModalLoading />
      {visibleTopButton && <ButtonToTop parentRef={layoutRef} />}
      <div className="w-screen max-w-full m-0 p-0 flex-grow bg-gh-black-100">
        {children}
      </div>
      <Footer />
      {/* </> */}
    </div>
  );
}

const ButtonToTop = ({ parentRef }) => {
  const scroll = () => {
    if (parentRef.current) {
      parentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  return (
    <div
      className="fixed bottom-32 md:bottom-10 right-10 lg:bottom-20 lg:right-20 xl:bottom-40 xl:right-40 z-50 cursor-pointer"
      title="Scroll to top"
      onClick={scroll}
    >
      <ArrowCircleUp
        size="48"
        className="text-primary bg-gh-black-100 rounded-full"
      />
    </div>
  );
};

export default Layout;
