import { format } from "date-fns";
import { id as idID } from "date-fns/locale";
import ListImages from "../../../assets";
import { formatRupiah } from "../../../utils";

function VoucherCodeGrid({ vouchers }) {
  //TODO: benerin voucher promo, kalo ini udah oke
  return (
    <div className="grid xl:grid-cols-2 grid-cols-1 gap-x-3 gap-y-3">
      {vouchers.map((voucher, index) => {
        return (
          <div
            key={index}
            className={`flex flex-col justify-between basis-1/2 bg-background p-4 rounded-md space-y-2`}
          >
            <div className="flex flex-col mb-4">
              <span className="mb-2">
                {`Diskon ${voucher.discount}%${
                  voucher.max_discount
                    ? ", Maks " + formatRupiah(voucher.max_discount)
                    : ""
                }`}
              </span>
              {/* <span className="h-[1px] bg-lighter-black w-full"></span> */}
              <ul className="list-inside list-disc">
                <li>{`Gunakan kode voucher ${voucher.code}`}</li>
              
              {voucher.voucher_requirement ? (
                <>
                  {voucher.voucher_requirement.badge_id && (
                      <li><span className="capitalize ">{`Khusus member ${voucher.voucher_requirement.badge.name}`}</span></li>
                  )}
                  {voucher.voucher_requirement.bank_id && (
                      <li><span className="capitalize">{`Khusus pembayaran dengan ${voucher.voucher_requirement.bank.name}`}</span></li>
                  )}
                </>
                ) : null}
                </ul>
            </div>
            <div className="flex flex-col pt-4 border-t-2 border-dashed border-t-primary-disabled">
              {/* <span className="h-[1px] bg-lighter-black w-full"></span> */}
              <div className="flex align-middle">
                <img className="mr-3" src={ListImages.Clock} alt="" />
                <span>{`Sampai ${format(
                  new Date(voucher.valid_date),
                  "dd MMMM yyy",
                  { locale: idID }
                )}`}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default VoucherCodeGrid;
