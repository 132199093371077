import axios from "axios"
import { action, createStore, thunk } from "easy-peasy"
import { catchAxiosError, formatRupiah, getAxiosConfig } from "../utils"
import { toast } from "react-toastify"

//TODO : split store ini kalo ada waktu
const store = createStore({
  photoCart: {},
  onePhotoCart: {},
  isModalPhoto: false,
  updateCart: action((state, payload) => {
    state.photoCart = payload
  }),
  getCart: thunk(async (action, payload, helpers) => {
    const token = localStorage.getItem("token")
    let cart = await axios
      .get(`${process.env.REACT_APP_BASE_URL}/apps/cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => err)
    if (cart.data) {
      let newCart = cart.data.data.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.photo.id]: { ...curr, selected: false },
        }
      }, {})
      action.updateCart(newCart)
    }
  }),
  addPhoto: action((state, payload) => {
    state.photoCart[payload.id] = {
      selected: false,
      photo: payload.photo,
    }
  }),
  removePhoto: action((state, payload) => {
    delete state.photoCart[payload.id]
  }),
  showLoggedInModal: action((state, payload) => {
    state.popover = "login"
  }),
  savePhoto: thunk(async (action, payload, helpers) => {
    let isLoggedIn = helpers.getState().loggedIn
    if (!isLoggedIn) {
      action.showLoggedInModal()
      return
    }
    const token = localStorage.getItem("token")
    let cart = helpers.getState().photoCart
    if (cart[payload.id]) {
      let remove = await axios
        .delete(`${process.env.REACT_APP_BASE_URL}/apps/cart/${payload.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => err)
      if (remove.data) {
        action.removePhoto({ id: payload.id })
        if (token) {
          return toast("Foto berhasil dihapus dari collection")
        }
      }
    } else {
      let insert = await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/apps/cart`,
          {
            photos_id: payload.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch(catchAxiosError)
      if (insert.status === 200) {
        action.addPhoto({ id: payload.id, photo: payload.photo })
        if (token) {
          return toast("Foto berhasil ditambahkan ke collection")
        }
      }
    }
  }),
  saveOnePhoto: thunk(async (action, payload, helpers, state) => {
    let isLoggedIn = helpers.getState().loggedIn
    if (!isLoggedIn) {
      action.showLoggedInModal()
      return
    }
    const token = localStorage.getItem("token")
    let cart = helpers.getState().onePhotoCart
    if (cart[payload.id]) {
      let remove = await axios
        .delete(`${process.env.REACT_APP_BASE_URL}/apps/cart/${payload.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => err)
      if (remove.data) {
        action.removePhoto({ id: payload.id })
      }
    } else {
      let insert = await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/apps/cart`,
          {
            photos_id: payload.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch((e) => {
          if (e.response.data.message !== "Item already in your cart")
            return catchAxiosError(e)
          helpers.getState().isModalPhoto = false
        })
      if (insert.status === 200) {
        action.addPhoto({ id: payload.id, photo: payload.photo })
        helpers.getState().isModalPhoto = true
      }
    }
  }),
  showPhotoInstruction: true,
  hidePhotoInstruction: action((state, payload) => {
    state.showPhotoInstruction = false
  }),
  loggedIn: false,
  user: null,
  validateLogin: action((state, payload) => {
    if (payload.user) {
      state.loggedIn = true
      state.user = payload.user
    } else {
      state.loggedIn = false
      state.user = null
    }
  }),
  hydrateLogin: action((state, payload) => {
    let user = localStorage.getItem("user")
    if (user) {
      state.user = JSON.parse(user)
      state.loggedIn = true
    }
  }),
  updateUserProfile: action((state, payload) => {
    state.user.profile = payload
  }),
  showErrorPage: false,
  showIsUnique: false,
  setErrorPage: action((state, payload) => {
    state.showErrorPage = payload
  }),
  setIsUnique: action((state, payload) => {
    state.showIsUnique = payload
  }),
  popover: undefined,
  togglePopover: action((state, payload) => {
    payload.stopPropagation()
    let tag = payload.currentTarget.dataset.togglePopover
    if (state.popover === tag) {
      state.popover = undefined
    } else {
      state.popover = tag
    }
  }),
  resetStore: action((state, payload) => {
    state.photoCart = {}
    state.loggedIn = false
    state.user = null
    state.popover = undefined
  }),
  checkoutArray: [],
  toggleCheckout: action((state, payload) => {
    let deleteIndex = state.checkoutArray.indexOf(payload)
    if (deleteIndex === -1) {
      state.checkoutArray.push(payload)
    } else {
      state.checkoutArray.splice(deleteIndex, 1)
    }
  }),
  oneCheckoutList: [],
  oneCheckout: action((state, payload) => {
    state.oneCheckoutList.push(payload)
  }),
  resetCheckout: action((state, payload) => {
    state.checkoutArray = []
  }),
  selectAllCheckout: action((state, payload) => {
    state.checkoutArray = [
      ...Object.keys(state.photoCart).map((id) => Number(id)),
    ]
  }),
  setCheckoutArray: action((state, payload) => {
    state.checkoutArray = payload
  }),
  setOneCheckoutArray: action((state, payload) => {
    state.setOneCheckoutArray = payload
  }),
  checkoutPhoto: thunk(async (action, payload, helpers) => {
    action.toggleShowLoadingModal()
    let checkoutArray = helpers.getState().checkoutArray
    let photoCart = helpers.getState().photoCart
    if (!checkoutArray.length) {
      action.toggleShowLoadingModal()
      throw Error("Silahkan tambahkan photo terlebih dahulu")
    }
    if (!helpers.getState().paymentMethod.id) {
      action.toggleShowLoadingModal()
      throw Error("Silahkan pilih metode pembayaran")
    }
    let subtotal = checkoutArray.reduce((prev, curr) => {
      return prev + photoCart[curr].photo.price
    }, 0)
    if (subtotal < 10000) {
      action.toggleShowLoadingModal()
      throw Error("Minimal nominal pembelian adalah " + formatRupiah(10000))
    }
    let data = {
      bank_id: helpers.getState().paymentMethod?.id,
      items: helpers.getState().checkoutArray.map((item) => {
        return { photos_id: item }
      }),
    }
    const token = localStorage.getItem("token")
    const response = await axios
      .post(`${process.env.REACT_APP_BASE_URL}/apps/cart/checkout`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => err)
    action.toggleShowLoadingModal()
    if (!response.data) {
      throw Error(response.response?.data.message ?? "Terjadi kesalahan")
    }
    action.setCheckoutArray([])
    await action.getCart()
    return response
  }),
  oneCheckoutPhoto: thunk(async (action, payload, helpers) => {
    action.toggleShowLoadingModal()
    let checkoutArray = helpers.getState().oneCheckoutList
    let photoCart = helpers.getState().photoCart
    if (!checkoutArray.length) {
      action.toggleShowLoadingModal()
      throw Error("Silahkan tambahkan photo terlebih dahulu")
    }
    if (!helpers.getState().paymentMethod.id) {
      action.toggleShowLoadingModal()
      throw Error("Silahkan pilih metode pembayaran")
    }
    let subtotal = checkoutArray.reduce((prev, curr) => {
      return prev + photoCart[curr].photo.price
    }, 0)
    if (subtotal < 10000) {
      action.toggleShowLoadingModal()
      throw Error("Minimal nominal pembelian adalah " + formatRupiah(10000))
    }
    let data = {
      bank_id: helpers.getState().paymentMethod?.id,
      items: helpers.getState().oneCheckoutList.map((item) => {
        return { photos_id: item }
      }),
    }
    const token = localStorage.getItem("token")
    const response = await axios
      .post(`${process.env.REACT_APP_BASE_URL}/apps/cart/checkout`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => err)
    action.toggleShowLoadingModal()
    if (!response.data) {
      throw Error(response.response?.data.message ?? "Terjadi kesalahan")
    }
    action.setOneCheckoutArray([])
    await action.getCart()
    return response
  }),
  paymentMethod: { bank_code: null },
  setPaymentMethod: action((state, payload) => {
    state.paymentMethod = payload
  }),
  LoadingModal: {
    show: false,
    text: "Mohon tunggu",
  },
  toggleShowLoadingModal: action((state, payload) => {
    if (payload) {
      state.LoadingModal = { ...state.loadingModal, ...payload }
    } else {
      state.LoadingModal = {
        show: !state.LoadingModal.show,
        text: "Mohon tunggu",
      }
    }
  }),
  gallery: {
    total: 99,
    last_page: 2,
    current_page: 0,
    row: [],
  },
  updateGallery: action((state, payload) => {
    state.gallery = { ...payload, row: [...state.gallery.row, ...payload.row] }
  }),
  getGallery: thunk(async (action, payload, helpers) => {
    const token = localStorage.getItem("token")
    if (!token) {
      return new Promise.resolve()
    }
    const gallery = helpers.getState().gallery
    if (gallery.last_page === gallery.current_page) {
      return new Promise.resolve()
    }
    let response = await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/apps/photos/gallery?current_page=${
          gallery.current_page + 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => err)
    if (response.data) {
      action.updateGallery(response.data.data)
    }
  }),
  selectedPhoto: [],
  setSelectedPhoto: action((state, payload) => {
    state.selectedPhoto = payload
  }),
  toggleSelectedPhoto: action((state, payload) => {
    let deleteIndex = state.selectedPhoto.indexOf(payload)
    if (deleteIndex === -1) {
      state.selectedPhoto.push(payload)
    } else {
      state.selectedPhoto.splice(deleteIndex, 1)
    }
  }),
  resetSelectedPhoto: action((state, payload) => {
    state.selectedPhoto = []
  }),
  downloadPhoto: thunk(async (action, payload, helpers) => {
    action.toggleShowLoadingModal({
      text: "Downloading photo",
      show: true,
    })
    let url = `${process.env.REACT_APP_BASE_URL}/apps/photos/${payload}/download`
    axios
      .get(url, {
        ...getAxiosConfig(true),
      })
      .then((response) => {
        const url = response.data.link

        if (response.status === 200) {
          // const fetchDownload = async () => {
          //   const response = await fetch(url)
          //   const blob = await response.blob()
          //   const blobUrl = URL.createObjectURL(blob)
          //   const link = document.createElement("a")
          //   link.href = blobUrl
          //   link.download = "photo.jpg"
          //   document.body.appendChild(link)
          //   link.click()
          //   document.body.removeChild(link)
          //   URL.revokeObjectURL(blobUrl)
          // }
          // fetchDownload()
          window.open(url, "_blank")
        } else {
          toast("Mohon coba beberapa saat lagi")
        }
        // const suggestedFileName = response.headers["x-suggested-filename"];
        // // create file link in browser's memory
        // const blobURL = window.URL.createObjectURL(response.data);
        // const tempLink = document.createElement("a");
        // tempLink.style.display = "none";
        // tempLink.href = blobURL;
        // tempLink.setAttribute("download", suggestedFileName ?? "photo");
        // // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // // target if the browser does not support the HTML5 download attribute.
        // // This allows you to download files in desktop safari if pop up blocking
        // // is enabled.
        // if (typeof tempLink.download === "undefined") {
        //   tempLink.setAttribute("target", "_blank");
        // }
        // document.body.appendChild(tempLink);
        // tempLink.dispatchEvent(
        //   new MouseEvent("click", {
        //     bubbles: true,
        //     cancelable: true,
        //     view: window,
        //   })
        // );
        // document.body.removeChild(tempLink);
        // setTimeout(() => {
        //   // For Firefox it is necessary to delay revoking the ObjectURL
        //   window.URL.revokeObjectURL(blobURL);
        // }, 100);
      })
      .catch(catchAxiosError)
      .finally(() => {
        action.toggleShowLoadingModal()
      })
  }),
  downloadPhotos: thunk(async (action, payload, helpers) => {
    let photos = helpers.getState().selectedPhoto
    if (!photos.length) {
      throw Error("Silahkan tambahkan photo terlebih dahulu")
    }
    let requestUrl = []
    for (let i = 0; i < photos.length; i++) {
      const photo = photos[i]
      let url = `${process.env.REACT_APP_BASE_URL}/apps/photos/${payload}/download`
      requestUrl.push(
        axios.get(url, {
          ...getAxiosConfig(true),
          responseType: "blob",
        })
      )
    }
    Promise.all(requestUrl)
      .then((responses) => {
        for (let i = 0; i < responses.length; i++) {
          const res = responses[i]
          const suggestedFileName = res.headers["x-suggested-filename"]
          // create file link in browser's memory
          const blobURL = window.URL.createObjectURL(res.data)
          const tempLink = document.createElement("a")
          tempLink.style.display = "none"
          tempLink.href = blobURL
          tempLink.setAttribute("download", suggestedFileName ?? "photo")
          // Safari thinks _blank anchor are pop ups. We only want to set _blank
          // target if the browser does not support the HTML5 download attribute.
          // This allows you to download files in desktop safari if pop up blocking
          // is enabled.
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank")
          }
          document.body.appendChild(tempLink)
          tempLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          )
          document.body.removeChild(tempLink)
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobURL)
          }, 100)
        }
      })
      .catch(catchAxiosError)
      .finally(() => {
        action.toggleShowLoadingModal()
      })
  }),
  scrollEnd: false,
  setScrollEnd: action((state, payload) => {
    state.scrollEnd = payload
  }),
})

export default store
