import { ClockIcon, MapIcon } from "@heroicons/react/24/outline"
import {
  CalendarDaysIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline"
import React, { Fragment, useCallback, useReducer } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useState } from "react"
import { useEffect } from "react"
import axios from "axios"
import Ticket from "../components/ticket"
import ArtistList from "../components/ArtistList"
import ListImages from "../assets"
import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid"
import { toast } from "react-toastify"
import { useContext } from "react"
import { GeneralContext } from "../context/context"
import { axiosWithInterceptor, catchAxiosError, formatRupiah } from "../utils"
import getPaymentMethodImage from "../utils/getPaymentMethodImage"
import { format } from "date-fns/esm"
import { Helmet } from "react-helmet"
import { id as idID } from "date-fns/locale"
// import { set } from "date-fns";
import IconSax from "../assets/vuesaxIcon"
import { Base64 } from "js-base64"
import Layout from "../components/layout"
import debounce from "lodash/debounce"
import { VoucherItemType } from "../constants/enums"
import { ArrowDown2, ArrowUp2 } from "iconsax-react"
import Spinner from "../components/Spinner"
import VoucherCodeGrid from "../components/partials/Event/VoucherCodeGrid"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import { useStoreActions, useStoreState } from "easy-peasy"
import TicketSponsor from "../components/ticketsponsor"
import "../assets/mobileeventdetail.css"

function EventDetail() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { id, eventName } = useParams()
  const [detail, setDetail] = useState(undefined)
  const [paymentMethods, setPaymentMethods] = useState([])
  const loggedIn = useStoreState((state) => state.loggedIn)
  const user = useStoreState((state) => state.user)
  const setErrorPage = useStoreActions((actions) => actions.setErrorPage)
  const setIsUnique = useStoreActions((actions) => actions.setIsUnique)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [snk, setSnk] = useState(false)
  const { paymentMethod, setPaymentMethod, setPaymentId } =
    useContext(GeneralContext)
  const [isMerchandise, setIsMerchandise] = useState(false)
  const [inputData, setInputData] = useState({
    birthdate: "2022-11-11",
  })
  const [voucher, setVoucher] = useState(null) //claimed voucher
  const [vouchers, setVouchers] = useState([]) //voucher list
  const [voucherCode, setVoucherCode] = useState(null)
  const [captchaToken, setCaptchaToken] = useState(null)
  const [isClaiming, setIsClaiming] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const [alertVoucher, setAlertVoucher] = useState(null)
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  const [sponsorId, setSponsorId] = useState()
  const [dataSponsor, setDataSponsor] = useState()
  const [masterSponsor, setMasterSponsor] = useState(undefined)
  const [seatingPlanWindow, setSeatingPlanWindow] = useState(false)
  const [seatMapImages, setSeatMapImages] = useState([])
  const [description, setDescription] = useState("")
  const [seatMapIndex, setSeatMapIndex] = useState(0)
  const [areaArray, setAreaArray] = useState([])
  const [dataSurvey, setDataSurvey] = useState()
  const [dropdown, setDropdown] = useState({
    event: true,
    syarat: true,
    tiket: true,
    pembayaran: false,
    tiketsponsor: false,
  })
  const initialState = {
    items: [],
    totalQuantity: 0,
    totalAmount: 0,
    voucherDiscount: 0,
    tax: 0,
  }

  const gender = [
    { value: "0", name: "Laki-laki" },
    { value: "1", name: "Perempuan" },
  ]

  const [ticket, dispatch] = useReducer(reducer, initialState)

  const [skeletonImage, setSkeletonImage] = useState(true)

  const handleTextCreditCard = (method) => {
    return method ? (
      <h5 className="text-sm font-bold leading-5">Credit Card</h5>
    ) : null
  }

  function reducer(state, action) {
    switch (action.type) {
      case "addTicket":
        let findIndex = state.items.findIndex((x) => x.id === action.payload.id)
        let tempItems = [...state.items]
        if (findIndex >= 0) {
          tempItems[findIndex].qty++
        } else {
          tempItems.push({ ...action.payload, qty: 1 })
        }

        return {
          totalQuantity: state.totalQuantity + 1,
          totalAmount: state.totalAmount + action.payload.price,
          items: tempItems,
          voucherDiscount: 0,
        }
      case "removeTicket":
        let index = state.items.findIndex((x) => x.id === action.payload.id)
        let temp = [...state.items]
        if (index >= 0) {
          if (temp[index].qty > 1) {
            temp[index].qty--
            return {
              totalQuantity: state.totalQuantity - 1,
              totalAmount: state.totalAmount - action.payload.price,
              items: temp,
              voucherDiscount: 0,
            }
          } else if (temp[index].qty === 1) {
            temp = temp.filter((x) => x.id !== action.payload.id)
            return {
              totalQuantity: state.totalQuantity - 1,
              totalAmount: state.totalAmount - action.payload.price,
              items: temp,
              voucherDiscount: 0,
            }
          }
        }
        return {
          totalQuantity: state.totalQuantity,
          totalAmount: state.totalAmount,
          items: state.items,
          voucherDiscount: 0,
        }
      case "applyVoucher":
        let discount = Math.round(
          state.totalAmount * (action.payload.discount / 100)
        )
        return {
          totalQuantity: state.totalQuantity,
          totalAmount: state.totalAmount,
          items: state.items,
          voucherDiscount:
            action.payload.max_discount &&
            discount > action.payload.max_discount
              ? action.payload.max_discount
              : discount || 0,
        }
      default:
        throw new Error()
    }
  }
  useEffect(() => {
    const fun = async () => {
      setDescription(Base64.decode(detail?.description ?? ""))
      const fetchVouchers = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/apps/voucher?transaction_type=0&event_id=${detail?.id}`
        )
        .catch(catchAxiosError)
      if (fetchVouchers.data.success) {
        setVouchers(fetchVouchers.data.vouchers)
      }
    }
    fun()
  }, [detail])

  useEffect(() => {
    if (user) {
      setInputData({
        birthdate: user.profile.birthdate,
        email: user.email,
        fullname: user.profile.fullname,
        phone_number: user.profile.phone_number,
        location: user.profile.city,
        nik: user.profile.nik,
      })
    }
  }, [loggedIn])

  useEffect(() => {
    const fetchData = async () => {
      const response = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_BASE_URL}/apps/events/${id}/detail${
            eventName ? `?search=${eventName}` : ""
          }`
        ),
        axios.get(`${process.env.REACT_APP_BASE_URL}/apps/payment-methods`),
        axios.get(
          `${process.env.REACT_APP_BASE_URL}/apps/sponsor?event_id=${id}`
        ),
      ])

      setMasterSponsor(response[2].data)
      setSkeletonImage(false)
      setDetail(response[0]?.data?.data ?? [])
      setPaymentMethods(response[1]?.data?.payment_methods ?? [])
      setSeatMapImages(
        response[0].data?.data.event_images.filter(
          (img) => img.image_cat === "seating-map"
        )
      )
    }
    fetchData()
  }, [id])

  useEffect(() => {
    const fetchData = async () => {
      if (sponsorId) {
        const resSponsor = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/apps/events/${id}/detail?sponsor_id=${sponsorId}`
        )

        if (resSponsor.status === 200) {
          setDataSponsor(resSponsor.data.data ?? [])
        }
      }
    }
    fetchData()
  }, [sponsorId])

  const nextSeating = () => {
    if (seatMapIndex === seatMapImages.length - 1) {
      return
    }
    setSeatMapIndex((old) => old + 1)
  }
  const prevSeating = () => {
    if (seatMapIndex === 0) {
      return
    }
    setSeatMapIndex((old) => old - 1)
  }

  useEffect(() => {
    if (!detail) {
      return
    }
    let currentAmount = ticket.totalAmount
    let sumDiscount = 0
    //check if ticket is eligible for promo promotor
    if (ticket.items.length && detail?.promo_promotors.length) {
      let finalDiscount = 0
      detail?.promo_promotors.forEach((promo) => {
        let accumulator = 0
        //promo is eligible if all requirement fulfilled
        let isEligible = promo.promo_promotor_requirements.every(
          (requirement) => {
            let currentTicket = ticket.items.find(
              (ticks) => ticks.id === requirement.ticket_categories_id
            )
            if (!currentTicket) {
              //ticket on requirement not yet available on cart
              return false
            }
            // current ticket qty supposed to be more than min buy
            if (currentTicket.qty >= requirement.min_buy) {
              let currentDiscount = currentTicket.price * (promo.discount / 100)
              accumulator +=
                (currentDiscount <= promo.max_discount
                  ? currentDiscount
                  : promo.max_discount) * currentTicket.qty
              return true
            }
            return false
          }
        )
        //all requirement fulfilled
        if (isEligible) {
          finalDiscount += accumulator
        }
      })
      sumDiscount += finalDiscount
    }
    // todo: if ticket bundle is true, setIsMerchandise(true)
    // check if ticket has merchendise
    if (ticket.items.length) {
      const ketemu = ticket.items.some((item) => item.has_bundle)
      if (ketemu && !isMerchandise) {
        setIsMerchandise(true)
      }
      if (!ketemu && isMerchandise) {
        setIsMerchandise(false)
      }
    } else {
      setIsMerchandise(false)
    }
    //check if selected payment method is eligible for available promo
    let method = paymentMethods.find(
      (method) => Number(method.id) === Number(paymentMethod)
    )
    if (method && detail?.promo_channel_payments.length) {
      //find promo that correspond to promoChannelPayment
      let promoChannelPayment = detail?.promo_channel_payments.find(
        (promo) => promo.bank_id === method.id
      )
      if (promoChannelPayment) {
        let discountChannelPayment =
          (currentAmount - sumDiscount) * (promoChannelPayment.discount / 100)
        let finalDiscount =
          discountChannelPayment > promoChannelPayment.max_discount
            ? promoChannelPayment.max_discount
            : discountChannelPayment
        sumDiscount += finalDiscount
      }
    }
    //check if user is eligible for badge promo
    const user = JSON.parse(localStorage.getItem("user"))
    if (user && detail?.promo_badges.length) {
      let promoBadge = detail?.promo_badges.find(
        (promo) => promo.badge_id === user.profile.badge.id
      )
      if (promoBadge) {
        let discountBadge =
          (currentAmount - sumDiscount) * (promoBadge.discount / 100)
        let finalDiscount =
          discountBadge > promoBadge.max_discount
            ? promoBadge.max_discount
            : discountBadge
        sumDiscount += finalDiscount
      }
    }
    setTotalDiscount(sumDiscount)
  }, [paymentMethod, ticket])

  const dropdownHandler = (e) => {
    setDropdown((prevState) => {
      return {
        ...prevState,
        [e.target.id]: !prevState[e.target.id],
      }
    })
  }

  const methodHandler = (e) => {
    setPaymentMethod(e.target.id)
  }

  const inputHandler = (e) => {
    setInputData((prevState) => {
      return {
        ...prevState,
        [e.target.id]: e.target.value,
      }
    })
  }

  // const handleCreditCard = (token) => {
  //   window.snap.pay(token, {
  //     onSuccess: (result) => {},
  //   })
  // }

  const handleGopay = (token) => {
    window.snap.pay(token, {
      onSuccess: (result) => {},
    })
  }

  const paymentHandler = async (e) => {
    if (ticket.items.length === 0) {
      return toast("Anda belum memilih tiket")
    }
    // const token = localStorage.getItem("token");
    if (String(inputData.nik).length !== 16) {
      return toast("NIK harus terdiri dari 16 digit angka")
    } else if (String(inputData.phone_number).length < 10) {
      return toast("Nomor Telepon harus memiliki minimal 10 digit angka")
    } else if (
      new Date("2010-1-1").getFullYear() -
        new Date(inputData.birthdate).getFullYear() <
      0
    ) {
      return toast("Peserta minimal harus berusia 15 tahun")
    } else if (String(inputData.fullname).length < 4) {
      return toast("Nama Lengkap minimal terdiri dari 4 karakter")
    } else if (!inputData.location) {
      return toast("Masukkan Kota Domisili Anda")
    } else if (
      !dataSurvey &&
      (detail.slug === "winter-concert-day-1" ||
        detail.slug === "winter-concert-day-2")
    ) {
      return toast("Masukkan ukuran sepatu")
    }

    if (!paymentMethod) {
      return toast("Pilih Metode Pembayaran")
    }
    if (isMerchandise) {
      if (ticket.items.length > 2) {
        return toast("Maksimal Beli 2 Tiket Merchandise")
      }
      if (!inputData.ukuranBaju) {
        return toast("Mohon pilih ukuran baju")
      }
      if (!inputData.warnaBaju) {
        return toast("Mohon pilih warna baju")
      }
      if (!inputData.shippingAddress) {
        return toast("Mohon isi alamat pengiriman")
      }
    }
    setLoading(true)

    let ticketList = []

    ticket.items.map((x) => {
      ticketList.push({ pricing_id: x.id, count: x.qty })
    })
    const requestTicket = axiosWithInterceptor()
    const enableSurveySlugs = [
      "winter-concert-day-1",
      "winter-concert-day-2",
      "winter-concert-afternoon-special-show",
      "winter-concert-x-irresistible-bazaar"
    ]

    requestTicket
      .post(
        `${process.env.REACT_APP_BASE_URL}/apps/reserve-ticket`,
        {
          transaction_type: VoucherItemType.ticket,
          nik: inputData.nik,
          fullname: inputData.fullname,
          email: inputData.email,
          phone_number: inputData.phone_number,
          birthdate: inputData.birthdate,
          location: inputData.location,
          event_id: Number(detail.id ?? 0),
          tickets: ticketList,
          ...(enableSurveySlugs.includes(detail.slug) && {
            survey: [`${dataSurvey}`],
          }),
          bank_id: Number(paymentMethod),
          ...(isMerchandise ? { size: inputData.ukuranBaju } : {}),
          ...(isMerchandise ? { color: inputData.warnaBaju } : {}),
          ...(isMerchandise
            ? { shipping_address: inputData.shippingAddress }
            : {}),
          ...(voucher ? { voucher_code: voucher.code } : {}),
          token: captchaToken,
        },
        {
          // ...(token
          //   ? {
          //       headers: {
          //         Authorization: `Bearer ${token}`,
          //       },
          //     }
          //   : {}),
        }
      )
      .then(({ data }) => {
        if (handleShowPayment()) {
          toast(data.message)
          setTimeout(() => {
            navigate(`/event/${id}/payment/${data.payment_id}/status`)
          }, 1000)
        } else {
          if (data.payment_id) {
            console.log("trigger")
            const urlRegex =
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
            if (urlRegex.test(data.payment_code)) {
              // handleCreditCard(data.payment_code, data.payment_id)
            } else if (data.payment_code.includes("-")) {
              handleGopay(data.payment_code)
            }
            toast(data.message)
            setPaymentId(data.payment_id)
            setTimeout(() => {
              navigate(`/payment/${data.payment_id}`)
            }, 1000)
          } else {
            toast("Terjadi kesalahan, mohon coba beberapa saat lagi")
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (Number(error.response.status) === 500) {
            return (
              setErrorPage(true),
              setIsUnique(
                detail?.ticket_categories?.some((item) => item.is_unique_user)
              )
            )
          } else {
            return catchAxiosError(error)
          }
        }
        return catchAxiosError(error)
      })
      .finally(() => {
        setLoading(false)
        setRefreshReCaptcha((r) => !r)
      })
  }

  const renderSingleMethod = (method) => {
    return method ? (
      <div className="flex items-center space-x-3 cursor-pointer">
        <input
          name="payment"
          type="radio"
          id={method.id}
          onChange={(e) => methodHandler(e)}
        />
        <label htmlFor={method.id}>
          <img
            className="h-[3rem] w-[8rem] cursor-pointer"
            id={method.name}
            src={getPaymentMethodImage(method.bank_code)}
            alt={method.name}
          />
        </label>
      </div>
    ) : null
  }

  const countPerformingRight = () => {
    return ticket.items.reduce((prev, curr) => {
      let voucherdiscount
      if (voucher) {
        voucherdiscount = curr.price * (voucher.discount / 100) || 0
        if (voucher.max_discount && voucherdiscount > voucher.max_discount) {
          voucherdiscount = voucher.max_discount
        }
      }

      const subtotal = curr.price * curr.qty
      return (
        prev +
        Math.round(
          voucher
            ? (subtotal - voucherdiscount) * curr.performing_right
            : subtotal * curr.performing_right
        )
      )
    }, 0)
  }

  const countBankFee = () => {
    let method
    method = paymentMethods.find(
      (method) => method.id === Number(paymentMethod)
    )
    let ppn = 0
    if (!method) {
      method = {
        admin_fee: 0,
        admin_fee_percentage: 0,
      }
    }
    let finalFee =
      Math.round(
        (ticket?.totalAmount +
          countPerformingRight() +
          countTax() -
          ticket?.voucherDiscount +
          countAdminFee()) *
          (method.admin_fee_percentage / 100)
      ) + method.admin_fee
    if (method.bank_code === "GOPAY") {
      ppn = Math.round(finalFee * 0.11)
    }
    return finalFee + ppn
  }

  const countAdminFee = () => {
    return ticket.items.reduce((prev, curr) => {
      let voucherdiscount
      if (voucher) {
        voucherdiscount = curr.price * (voucher.discount / 100) || 0
        if (voucher.max_discount && voucherdiscount > voucher.max_discount) {
          voucherdiscount = voucher.max_discount
        }
      }
      const subtotal = curr.price * curr.qty
      return (
        prev +
        Math.round(
          voucher
            ? (subtotal - voucherdiscount) * curr.fee_percentage
            : subtotal * curr.fee_percentage
        )
      )
    }, 0)
  }

  const countTax = () => {
    return ticket.items.reduce((prev, curr) => {
      let voucherdiscount
      if (voucher) {
        voucherdiscount = curr.price * (voucher.discount / 100) || 0
        if (voucher.max_discount && voucherdiscount > voucher.max_discount) {
          voucherdiscount = voucher.max_discount
        }
      }
      return (
        prev +
        Math.round(
          voucher
            ? (curr.price - voucherdiscount) * curr.tax_percentage * curr.qty
            : curr.price * curr.tax_percentage * curr.qty
        )
      )
    }, 0)
  }

  const claimVoucher = useCallback(
    debounce(async (code) => {
      setAlertVoucher(null)
      setVoucher(null)
      setIsClaiming(true)
      const token = localStorage.getItem("token")
      if (code === "" || code === null) {
        setIsClaiming(false)
        return
      }
      if (!token) {
        setIsClaiming(false)
        setAlertVoucher("Login diperlukan untuk menggunakan kupon promo")
        return
      }
      const axiosInstance = axiosWithInterceptor()
      const response = await axiosInstance
        .post(
          `${process.env.REACT_APP_BASE_URL}/apps/voucher/claim`,
          {
            voucher_code: code,
            transaction_type: VoucherItemType.ticket,
            tickets: ticket.items,
            photos: [true],
            payment_method: Number(paymentMethod),
            event_id: Number(detail?.id ?? 0),
          },
          {
            ...(token
              ? {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              : {}),
          }
        )
        .catch((error) => {
          setVoucher(null)
          if (error.response) {
            return setAlertVoucher(error.response.data.message)
          } else {
            setAlertVoucher("Kesalahan terjadi")
          }
        })
        .finally(() => {
          setIsClaiming(false)
        })
      if (response.data.success) {
        setVoucher(response.data.voucher)
      }
    }, 1000),
    [ticket, paymentMethod]
  )

  const handleVerify = useCallback((token) => {
    setCaptchaToken(token)
  }, [])

  useEffect(() => {
    claimVoucher(voucherCode)
  }, [voucherCode, ticket.items, paymentMethod, detail])

  useEffect(() => {
    if (voucher) {
      dispatch({ type: "applyVoucher", payload: { ...voucher } })
    } else {
      dispatch({ type: "applyVoucher", payload: {} })
    }
  }, [voucher])

  const handleShowPayment = () => {
    if (
      ticket.items?.length > 0 ||
      detail.ticket_categories?.every(
        (item) => item?.price === 0 && item?.is_free
      )
    ) {
      if (ticket.items?.every((item) => item?.is_free && item?.price === 0))
        return true
    } else {
      return false
    }
  }

  const handleAreaArray = (value) => {
    if (areaArray.includes(value)) {
      const hapus = areaArray.filter((item) => item !== value)
      setAreaArray(hapus)
    } else {
      areaArray.push(value)
    }
  }

  return (
    <Layout>
      <GoogleReCaptcha
        onVerify={handleVerify}
        refreshReCaptcha={refreshReCaptcha}
      />
      <div className="py-5 px-4 w-full flex justify-center bg-gh-black-200 text-white">
        <div className="container lg:px-20">
          <Helmet>
            <title>{`Event ${detail?.title ?? ""} | GUEHADIR`}</title>
            <meta name="description" content={`${detail?.label_description}`} />
            <meta
              property="og:title"
              content={`Event ${detail?.title} | GUEHADIR`}
            />
            <meta
              property="og:description"
              content={detail?.label_description}
            />
            <meta property="og:image" content={detail?.event_images[0].image} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:type" content="article" />
            <meta property="og:site_name" content="Gue Hadir" />
            <meta
              property="og:url"
              content={`https://guehadir.id/event/${detail?.slug}`}
            />
            <meta property="twitter:domain" content="dev.guehadir.id" />
            <meta
              property="twitter:url"
              content={`https://guehadir.id/event/${detail?.slug}`}
            />
            <meta name="twitter:title" content={`Event ${detail?.title}`} />
            <meta
              name="twitter:description"
              content={detail?.label_description}
            />
            <meta
              name="twitter:image"
              content={detail?.event_images[0].image}
            />
          </Helmet>
          <div
            className={`${
              seatingPlanWindow ? "opacity-1 visible" : "opacity-0 invisible"
            } transition-all fixed z-[9999999999] left-0 top-0 right-0 w-full h-screen bottom-0 flex items-center justify-center `}
            style={{ background: "rgba(0,0,0,0.3)" }}
            onClick={(e) => {
              setSeatingPlanWindow(false)
            }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              className=" max-w-[90%] max-h-[90%] p-5 rounded-md bg-semiblack"
            >
              <div className="flex items-center justify-between">
                <div>Seating plan</div>
                <XMarkIcon
                  onClick={() => setSeatingPlanWindow(false)}
                  className="h-[1.5rem] w-auto cursor-pointer"
                />
              </div>
              <div className="mt-4 flex flex-col items-center max-w-full">
                <div className="flex items-center gap-3 max-w-full justify-between">
                  <div
                    onClick={prevSeating}
                    className="p2 rounded-full border-[2px] border-primary cursor-pointer"
                  >
                    <IconSax.ArrowLeft className="stroke-primary" />
                  </div>
                  {seatMapImages.length ? (
                    seatMapImages
                      .filter((item, index) => index === seatMapIndex)
                      .map((item, index) => (
                        <img
                          key={index}
                          src={item.image}
                          alt=""
                          className={`flex-1 w-[20%] max-h-[80vh] max-w-[80vw]`}
                        />
                      ))
                  ) : (
                    <p>No image</p>
                  )}
                  <div
                    onClick={nextSeating}
                    className="p2 rounded-full border-[2px] border-primary cursor-pointer"
                  >
                    <IconSax.ArrowRight className="stroke-primary" />
                  </div>
                </div>
                <div className=" ">{`(${seatMapIndex + 1} / ${
                  seatMapImages.length
                })`}</div>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-1 mx-w-full">
            <span className="cursor-pointer" onClick={() => navigate("/")}>
              Home
            </span>{" "}
            &nbsp;
            {`>`}{" "}
            <span className="text-primary line-clamp-1">
              <p>{detail?.title ?? ""}</p>
            </span>
          </div>
          {skeletonImage ? (
            <>
              <div className="hidden md:block relative">
                <div className="animate-pulse rounded-md mt-5 w-full h-[25rem] bg-zinc-700"></div>
              </div>
            </>
          ) : (
            <>
              <div className="hidden md:block relative">
                <img
                  className="mt-5 w-full blur h-[25rem]"
                  src={detail.event_images[0].image}
                  alt=""
                />
                <img
                  className="absolute left-[50%] top-[50%] rounded-md md:h-[90%] md:w-auto object-contain"
                  style={{ transform: "translate(-50%,-50%)" }}
                  src={
                    detail.event_images.find(
                      (image) => image.image_cat === "banner"
                    )?.image ?? detail.event_images[0].image
                  }
                  alt=""
                />
              </div>
              <img
                className="md:hidden mt-5 w-full"
                src={detail.event_images[0].image}
                alt=""
              />
            </>
          )}
          {skeletonImage ? (
            <div className="flex flex-col-reverse lg:flex-row lg:space-x-12 mt-5">
              {/* Box 1  */}
              <div className="w-full">
                {/* Deskripsi Event  */}
                <div className="py-5 border-b border-[#434343]">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <div className="animate-pulse w-32 h-5 bg-zinc-700 rounded-full" />
                    </div>
                    <div className="animate-pulse w-6 h-6 bg-zinc-700 rounded-md" />
                  </div>
                  <div
                    className={`${
                      dropdown.event ? "max-h-0" : "max-h-[100rem] mt-5"
                    } transition-all overflow-hidden text-white`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    ></div>
                  </div>
                </div>

                {/* Promo  */}
                <div className="py-5 border-b border-[#434343] flex flex-col space-y-5">
                  <div className="flex items-center space-x-2">
                    <div className="animate-pulse w-40 h-5 bg-zinc-700 rounded-full" />
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="animate-pulse w-7 h-7 bg-zinc-700 rounded-full" />
                    <div className="animate-pulse w-[40%] h-5 bg-zinc-700 rounded-full" />
                  </div>
                  <div className="flex flex-col ">
                    {detail?.promo_badges?.length ? (
                      <>
                        <div className="flex align-middle justify-between mb-3">
                          <div className="font-bold text-sm ">Promo Badge</div>
                          <div className="font-normal text-sm text-zinc-500">
                            {detail.promo_badges.length} Promo
                          </div>
                        </div>
                        <div className="grid xl:grid-cols-2 grid-cols-1 gap-x-3 gap-y-3">
                          {detail.promo_badges.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`flex flex-col basis-1/2 bg-background p-4 rounded-md space-y-2`}
                              >
                                <span>
                                  {`Diskon ${
                                    item.discount
                                  }%, Maks ${formatRupiah(
                                    item.max_discount ?? 0
                                  )}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <span className="line-clamp-1">
                                  <span className="text-[5px] px-3">{`  \u2B24 `}</span>
                                  {`Khusus pelanggan ${item.badge.name}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <div className="flex align-middle">
                                  <img
                                    className="mr-3"
                                    src={ListImages.Clock}
                                    alt=""
                                  />
                                  <span>{`Sampai ${format(
                                    new Date(detail.end_date),
                                    "dd MMMM yyy",
                                    { locale: idID }
                                  )}`}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </>
                    ) : null}
                    {detail?.promo_channel_payments?.length ? (
                      <>
                        <div className="flex align-middle justify-between my-3">
                          <div className="font-bold text-sm ">
                            Promo Metode Pembayaran
                          </div>
                          <div className="font-normal text-sm text-zinc-500">
                            {detail?.promo_channel_payments.length} Promo
                          </div>
                        </div>
                        <div className="grid xl:grid-cols-2 grid-cols-1 gap-x-3 gap-y-3">
                          {detail.promo_channel_payments.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`flex flex-col basis-1/2 bg-background p-4 rounded-md space-y-2`}
                              >
                                <span>
                                  {`Diskon ${
                                    item.discount
                                  }%, Maks ${formatRupiah(
                                    item.max_discount ?? 0
                                  )}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <span className="line-clamp-1">
                                  <span className="text-[5px] px-3">{`  \u2B24 `}</span>
                                  {`Pembayaran dengan ${item.bank.name}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <div className="flex align-middle">
                                  <img
                                    className="mr-3"
                                    src={ListImages.Clock}
                                    alt=""
                                  />
                                  <span>{`Sampai ${format(
                                    new Date(detail.end_date),
                                    "dd MMMM yyy",
                                    { locale: idID }
                                  )}`}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </>
                    ) : null}
                    {detail?.promo_promotors?.length ? (
                      <>
                        <div className="flex align-middle justify-between my-3">
                          <div className="font-bold text-sm ">
                            Promo Promotor
                          </div>
                          <div className="font-normal text-sm text-zinc-500">
                            {detail?.promo_promotors.length} Promo
                          </div>
                        </div>
                        <div className="grid xl:grid-cols-2 grid-cols-1 gap-x-3 gap-y-3">
                          {detail.promo_promotors.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`flex flex-col basis-1/2 bg-background p-4 rounded-md space-y-2`}
                              >
                                <span>
                                  {`Diskon ${
                                    item.discount
                                  }%, Maks ${formatRupiah(
                                    item.max_discount ?? 0
                                  )}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                {item.promo_promotor_requirements.map(
                                  (requirement, index) => (
                                    <span key={index} className="line-clamp-1">
                                      <span className="text-[5px] px-3">{`  \u2B24 `}</span>
                                      {`Min Order. ${requirement.min_buy}, Tiket ${requirement.ticket_category.categories_name}`}
                                    </span>
                                  )
                                )}
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <div className="flex align-middle">
                                  <img
                                    className="mr-3"
                                    src={ListImages.Clock}
                                    alt=""
                                  />
                                  <span>{`Sampai ${format(
                                    new Date(detail.end_date),
                                    "dd MMMM yyy",
                                    { locale: idID }
                                  )}`}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </>
                    ) : null}
                    {vouchers.length ? (
                      <>
                        <div className="flex align-middle justify-between my-3">
                          <div className="font-bold text-sm ">Voucher code</div>
                          <div className="font-normal text-sm text-zinc-500">
                            {vouchers.length} Promo
                          </div>
                        </div>
                        <VoucherCodeGrid vouchers={vouchers} />
                      </>
                    ) : null}
                  </div>
                </div>
                {/* Promo End  */}

                {/* Penukaran Tiket  */}
                <div className="py-5 border-b border-[#434343] flex flex-col space-y-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <div className="animate-pulse w-32 h-5 bg-zinc-700 rounded-full" />
                    </div>
                    <div className="animate-pulse w-6 h-6 bg-zinc-700 rounded-md" />
                  </div>
                  <div
                    className={`${
                      dropdown.tiket ? "max-h-0" : "max-h-[100rem] mt-5"
                    } transition-all overflow-hidden`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: window.atob(detail?.exchange_info ?? ""),
                      }}
                    ></div>
                  </div>
                </div>
                {/* Penukaran Tiket End  */}

                {/* Tiket  */}
                <div className="py-5 border-b border-[#434343] flex flex-col space-y-5">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <div className="animate-pulse w-20 h-5 bg-zinc-700 rounded-full" />
                    </div>
                    <div className="animate-pulse w-40 h-5 bg-zinc-700 rounded-full" />
                  </div>
                  {/* card tiket jual */}
                  <div className=" rounded-md flex flex-col space-y-2 bg-zinc-800 overflow-x-hidden">
                    <div
                      className={`uppercase text-[1.5rem] font-semibold px-5 pt-5 }`}
                    >
                      <div className="animate-pulse w-56 h-7 bg-zinc-700 rounded-full" />
                    </div>
                    <div className={`px-5 flex align-middle`}>
                      <div className="animate-pulse w-40 h-4 mt-3 bg-zinc-700 rounded-full" />
                    </div>
                    <div className="flex px-5">
                      <div className={`mb-5 transition-all flex-1`}>
                        <div className="animate-pulse w-20 mt-3 h-4 bg-zinc-700 rounded-full" />
                      </div>
                      <div>
                        <div className="animate-pulse w-5 h-5 mt-2 bg-zinc-700 rounded-full" />
                      </div>
                    </div>
                    <div
                      className={`relative flex items-center justify-between p-5 border-t-4 animate-pulse border-dashed border-zinc-700`}
                    >
                      <div className="h-[30px] w-[30px] bg-gh-black-200 rounded-tr-full rounded-br-full  absolute left-[-2%] top-[-25%]"></div>
                      <div className="h-[30px] w-[30px] bg-gh-black-200 rounded-tl-full rounded-bl-full absolute right-[-2%] top-[-25%]"></div>
                      <div className={`font-semibold text-[1.25rem]`}>
                        <div className="animate-pulse w-40 h-8 bg-zinc-700 rounded-full"></div>
                      </div>
                      <div className="flex items-center space-x-5">
                        <div className="animate-pulse w-36 h-6 bg-zinc-700 rounded-full"></div>
                      </div>
                    </div>
                  </div>

                  {/* end card tiket jual */}
                </div>
                {/* Tiket End  */}

                {/* Data Pembeli  */}
                <div className="py-5 border-b border-[#434343] flex flex-col space-y-5">
                  <div className="flex items-center space-x-2">
                    <div className="animate-pulse w-[20%] h-4 bg-zinc-700 rounded-full "></div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div>
                      <div className="animate-pulse w-7 h-7 bg-zinc-700 rounded-full"></div>
                    </div>
                    <div>
                      <div className="animate-pulse w-96 h-4 bg-zinc-700 rounded-full "></div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div className="flex flex-col space-y-2">
                      <div className="animate-pulse w-28 h-6 bg-zinc-700 rounded-full "></div>
                      <div className="animate-pulse w-full h-10 bg-zinc-700 rounded-md "></div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="animate-pulse w-28 h-6 bg-zinc-700 rounded-full "></div>
                      <div className="animate-pulse w-full h-10 bg-zinc-700 rounded-md "></div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="animate-pulse w-28 h-6 bg-zinc-700 rounded-full "></div>
                      <div className="animate-pulse w-full h-10 bg-zinc-700 rounded-md "></div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="animate-pulse w-28 h-6 bg-zinc-700 rounded-full "></div>
                      <div className="animate-pulse w-full h-10 bg-zinc-700 rounded-md "></div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="animate-pulse w-28 h-6 bg-zinc-700 rounded-full "></div>
                      <div className="animate-pulse w-full h-10 bg-zinc-700 rounded-md "></div>
                    </div>

                    <div className="flex flex-col space-y-2">
                      <div className="animate-pulse w-28 h-6 bg-zinc-700 rounded-full "></div>
                      <div className="animate-pulse w-full h-10 bg-zinc-700 rounded-md "></div>
                    </div>
                  </div>
                </div>
                {/* Data End  */}

                {/* Metode Pembayaran */}
                <div className="pt-5 border-b border-[#434343] flex flex-col space-y-5">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <div className="animate-pulse bg-zinc-700 w-64 h-4 rounded-full"></div>
                    </div>
                    <div className="animate-pulse bg-zinc-700 rounded-md w-6 h-6 " />
                  </div>
                  <div
                    className={`${
                      dropdown.pembayaran ? "max-h-0" : "max-h-[100rem] mt-5"
                    } transition-all overflow-hidden`}
                  >
                    <div className="pb-5 flex flex-col space-y-3">
                      <>
                        <h5 className="text-sm font-bold leading-5">
                          <div className="animate-pulse w-10 h-5 bg-zinc-700 rounded-full" />
                        </h5>
                        <div className="grid grid-cols-2 lg:grid-cols-4 w-fit gap-6 items-center">
                          <div className="flex items-center space-x-3 cursor-pointer">
                            <div className="animate-pulse w-4 h-4 bg-zinc-700 rounded-full" />
                            <div className="animate-pulse w-32 h-12 bg-zinc-700 rounded-md " />
                          </div>
                        </div>
                        <h5 className="text-sm font-bold leading-5">
                          <div className="animate-pulse w-28 h-5 bg-zinc-700 rounded-full" />
                        </h5>
                        <div className="grid grid-cols-2 lg:grid-cols-4 w-fit gap-6 items-center">
                          <div className="flex items-center space-x-3 cursor-pointer">
                            <div className="animate-pulse w-4 h-4 bg-zinc-700 rounded-full" />
                            <div className="animate-pulse w-32 h-12 bg-zinc-700 rounded-md " />
                          </div>
                          <div className="flex items-center space-x-3 cursor-pointer">
                            <div className="animate-pulse w-4 h-4 bg-zinc-700 rounded-full" />
                            <div className="animate-pulse w-32 h-12 bg-zinc-700 rounded-md " />
                          </div>
                          <div className="flex items-center space-x-3 cursor-pointer">
                            <div className="animate-pulse w-4 h-4 bg-zinc-700 rounded-full" />
                            <div className="animate-pulse w-32 h-12 bg-zinc-700 rounded-md " />
                          </div>
                        </div>
                        <h5 className="text-sm font-bold leading-5">
                          <div className="animate-pulse w-24 h-5 bg-zinc-700 rounded-full" />
                        </h5>
                        <div className="grid grid-cols-2 lg:grid-cols-4 w-fit gap-6 items-center">
                          <div className="flex items-center space-x-3 cursor-pointer">
                            <div className="animate-pulse w-4 h-4 bg-zinc-700 rounded-full" />
                            <div className="animate-pulse w-32 h-12 bg-zinc-700 rounded-md " />
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                {/* Data End  */}
              </div>

              {/* Box 2  */}
              <div className="flex flex-col space-y-3">
                {/* Detail  */}
                <div
                  className="p-5 flex flex-col bg-[#191919] rounded-md"
                  style={{ boxShadow: "0 0 20px black" }}
                >
                  <div className="animate-pulse w-full h-5 bg-zinc-700 rounded-full] rounded-full" />
                  <div className="animate-pulse w-[60%] h-5 bg-zinc-700 rounded-full] mt-2 rounded-full" />
                  <div className="flex flex-col space-y-2 mt-3">
                    <div className="flex items-center space-x-2">
                      <div className="animate-pulse w-5 h-5 bg-zinc-700 rounded-full] rounded-full" />
                      <div className="capitalize">
                        <div className="animate-pulse w-72 h-5 bg-zinc-700 rounded-full" />
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="animate-pulse w-5 h-5 bg-zinc-700 rounded-full] rounded-full" />
                      <div>
                        <div className="animate-pulse w-40 h-5 bg-zinc-700 rounded-full" />
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="animate-pulse w-5 h-5 bg-zinc-700 rounded-full] rounded-full" />
                      <div>
                        <div className="animate-pulse w-20 h-5 bg-zinc-700 rounded-full" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Line Up Artist */}
                <div
                  className="p-5 flex flex-col space-y-3 bg-[#191919] rounded-md"
                  style={{ boxShadow: "0 0 20px black" }}
                >
                  {/* Top Level Element */}
                  <div className="animate-pulse w-28 h-5 bg-zinc-700 rounded-full" />
                  <div className="flex flex-row items-center align-middle [&>*]:mr-2 ">
                    <div className="animate-pulse w-8 h-8 bg-zinc-700 rounded-full" />
                    <div className="animate-pulse w-28 h-5 bg-zinc-700 rounded-full" />
                  </div>
                  <div className="flex flex-row items-center align-middle [&>*]:mr-2 ">
                    <div className="animate-pulse w-8 h-8 bg-zinc-700 rounded-full" />
                    <div className="animate-pulse w-28 h-5 bg-zinc-700 rounded-full" />
                  </div>
                  <div className="flex flex-row items-center align-middle [&>*]:mr-2 ">
                    <div className="animate-pulse w-8 h-8 bg-zinc-700 rounded-full" />
                    <div className="animate-pulse w-28 h-5 bg-zinc-700 rounded-full" />
                  </div>
                  {/* <div
                      className="cursor-pointer"
                    >{`${remain.length} More`}</div> */}
                </div>
                {/* Line Up Artist */}

                {/* Order Summary */}
                <div
                  className="hidden p-5 lg:flex flex-col space-y-5 bg-[#191919] rounded-md sticky top-[13vh]"
                  style={{ boxShadow: "0 0 20px black" }}
                >
                  <div className="flex items-center justify-between">
                    <div className="animate-pulse w-28 h-5 bg-zinc-700 rounded-full" />
                    <div className="animate-pulse w-14 h-10 bg-zinc-700 rounded-md" />
                  </div>
                  <div className="animate-pulse w-full h-5 bg-zinc-700 rounded-full" />
                  <div className="border-dashed animate-pulse border-zinc-700 border-b-4"></div>
                  {ticket.items?.map((x, index) => {
                    return (
                      <div
                        key={index}
                        className={`${
                          index !== ticket.items.length - 1
                            ? "border-b-4 border-dashed border-[#434343] pb-3"
                            : ""
                        } flex justify-between`}
                      >
                        <div className="flex items-start space-x-2">
                          <img
                            className="mt-1"
                            src={ListImages.Ticket}
                            alt=""
                          />
                          <div>
                            <div className="text-[1.25rem] font-semibold uppercase">
                              {x.categories_name}
                            </div>
                            <div className="font-light">{x.qty} Tiket</div>
                          </div>
                        </div>
                        <div className="mt-auto">
                          <div>{formatRupiah(x.price * x.qty)}</div>
                        </div>
                      </div>
                    )
                  })}
                  <div className="flex flex-col border-y py-4 border-[#434343]">
                    <div className="animate-pulse w-[40%] h-5 rounded-full bg-zinc-700 mb-2" />
                    <div className="flex relative items-center">
                      <div className="animate-pulse w-full h-10 rounded-md bg-zinc-700 mb-2" />
                    </div>
                    <div className="text-red-500 line-clamp-3">
                      {alertVoucher}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="animate-pulse w-32 h-5 bg-zinc-700 rounded-full " />
                    </div>
                    <div className="animate-pulse w-10 h-5 bg-zinc-700 rounded-full " />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="animate-pulse w-32 h-5 bg-zinc-700 rounded-full " />
                    <div className="animate-pulse w-10 h-5 bg-zinc-700 rounded-full " />
                  </div>
                  <div className="flex items-center justify-between  ">
                    <div className="animate-pulse w-32 h-5 bg-zinc-700 rounded-full " />
                    <div className="animate-pulse w-10 h-5 bg-zinc-700 rounded-full " />
                  </div>
                  <div className="flex items-center justify-between  ">
                    <div className="animate-pulse w-32 h-5 bg-zinc-700 rounded-full " />
                    <div className="animate-pulse w-10 h-5 bg-zinc-700 rounded-full " />
                  </div>
                  <div className="flex items-center justify-between border-b-4 border-[#434343] pb-4 border-dashed">
                    <div className="animate-pulse w-32 h-5 bg-zinc-700 rounded-full " />
                    <div className="animate-pulse w-10 h-5 bg-zinc-700 rounded-full " />
                  </div>
                  <div className="flex items-center justify-between flex-wrap">
                    <div className="animate-pulse w-10 h-5 bg-zinc-700 rounded-full " />
                    <div className="animate-pulse w-10 h-5 bg-zinc-700 rounded-full " />
                    <div className="animate-pulse w-[85%] mt-1 h-5 bg-zinc-700 rounded-full " />
                  </div>
                  <div className="text-gray-400 text-sm">
                    <div className="animate-pulse w-full mt-1 h-5 bg-zinc-700 rounded-full " />
                    <div className="animate-pulse w-[60%] mt-1 h-5 bg-zinc-700 rounded-full " />
                  </div>
                  <div className="animate-pulse w-full mt-1 h-10 bg-zinc-700 rounded-md " />
                </div>
                {/* Order Summary End */}

                {/* Fixed Mobile  */}
                <div className="lg:hidden fixed bottom-0 left-0 bg-background max-h-[80vh] p-5 w-full flex flex-col">
                  <div
                    onClick={() => {
                      setShowSummary((old) => !old)
                    }}
                    className="font-montserrat font-semibold text-2xl text-secondary pb-5 flex items justify-between"
                  >
                    <div className="flex items-center space-x-2">
                      <div className="bg-primary h-full w-[3px] rounded-sm">
                        {" "}
                      </div>
                      <p className="teks12">ORDER SUMMARY</p>
                    </div>
                    {showSummary ? (
                      <ArrowUp2 size="32" className="text-secondary" />
                    ) : (
                      <ArrowDown2 size="32" className="text-secondary" />
                    )}
                  </div>
                  <div
                    onClick={() => {
                      setShowSummary((old) => !old)
                    }}
                  >
                    {`We keep your ticket(s) for ${
                      detail?.book_time_limit_minute ?? 60
                    } Minutes . After the timer end, we
              will released your ticket .`}
                  </div>
                  <div
                    className={`${
                      !showSummary && "hidden"
                    } transition-all bg-gh-black-100 px-2 flex-1 max-h-[40vh] overflow-y-scroll border-t-2 border-b-2 border-dashed border-t-primary border-b-primary-disabled py-4 mt-4 flex flex-col space-y-3`}
                  >
                    {ticket.items?.map((x, index) => {
                      return (
                        <div
                          key={index}
                          className={`${
                            index !== ticket.items.length - 1
                              ? "border-b-4 border-dashed border-[#434343] pb-3"
                              : ""
                          } flex justify-between`}
                        >
                          <div className="flex items-start space-x-2">
                            <img
                              className="mt-1"
                              src={ListImages.Ticket}
                              alt=""
                            />
                            <div>
                              <div className="text-[1.25rem] font-semibold uppercase">
                                {x.categories_name}
                              </div>
                              <div className="font-light">{x.qty} Tiket</div>
                            </div>
                          </div>
                          <div className="mt-auto">
                            <div>{formatRupiah(x.price * x.qty)}</div>
                          </div>
                        </div>
                      )
                    })}
                    <div className="flex flex-col border-b py-4 border-[#434343]">
                      <div className="text-primary">Mempunyai kupon promo?</div>
                      <div className="flex relative items-center">
                        {voucher ? (
                          <CheckIcon className="h-[20px] w-auto text-green-500 absolute right-0 pr-2" />
                        ) : (
                          <Spinner
                            className={`${
                              !isClaiming && "hidden"
                            } text-primary absolute right-0`}
                            innerClass="h-5 w-5"
                          />
                        )}
                        <input
                          className="flex-1 bg-background p-2 rounded-md"
                          placeholder="Masukkan kode kupon"
                          type="text"
                          onChange={(e) => {
                            setVoucherCode(e.target.value)
                          }}
                        />
                      </div>
                      <div className="text-red-500 text-clamp-3">
                        {alertVoucher}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div>
                        Subtotal{" "}
                        <span className="text-primary">
                          {ticket?.totalQuantity} Tiket
                        </span>
                      </div>
                      <div>{formatRupiah(ticket?.totalAmount)}</div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div>Diskon Promo</div>
                      <div>{`- ${formatRupiah(totalDiscount ?? 0)}`}</div>
                    </div>
                    {voucher && (
                      <div className="flex items-center justify-between">
                        <p className="line-clamp-2">{`Kupon "${voucher.code}"`}</p>
                        <div>{`- ${formatRupiah(
                          ticket.voucherDiscount ?? 0
                        )}`}</div>
                      </div>
                    )}
                    <div className="flex items-center justify-between  ">
                      <div>Biaya Lainnya</div>
                      <div>{formatRupiah(countBankFee() ?? 0)}</div>
                    </div>
                    <div className="flex items-center justify-between  ">
                      <div>Performing Right</div>
                      <div>{formatRupiah(countPerformingRight() ?? 0)}</div>
                    </div>
                    <div className="flex items-center justify-between  ">
                      <div>Biaya Admin</div>
                      <div>{formatRupiah(countAdminFee() ?? 0)}</div>
                    </div>
                    <div className="flex items-center justify-between ">
                      <div>Pajak</div>
                      <div>{formatRupiah(countTax() ?? 0)}</div>
                    </div>
                  </div>
                  <div className="text-gray-400 text-sm py-4">
                    <input
                      checked={snk}
                      onChange={() => {
                        setSnk((old) => {
                          return !old
                        })
                      }}
                      type="checkbox"
                      id="checkbox-snk"
                      name="checkbox-snk"
                      className="default:ring-2 mr-2 accent-primary "
                    />
                    <label htmlFor="checkbox-snk">
                      Saya menyetujui{" "}
                      <Link
                        to={"/syarat-dan-ketentuan"}
                        className="text-primary"
                        target={"_blank"}
                      >
                        Syarat dan Ketentuan
                      </Link>{" "}
                      serta{" "}
                      <Link
                        to={"/kebijakan-privasi"}
                        className="text-primary"
                        target={"_blank"}
                      >
                        Kebijakan Privasi
                      </Link>
                    </label>
                  </div>
                  <div className="flex items-center justify-between flex-wrap">
                    <div className="flex justify-between items-center flex-1 pr-4">
                      <div>
                        <div>Total</div>
                        <div className="text-primary">
                          {ticket?.items.length} Tiket
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setShowSummary((old) => !old)
                        }}
                        className="flex items-center space-x-1"
                      >
                        <span>
                          {formatRupiah(
                            Math.round(
                              ticket?.totalAmount -
                                ticket?.voucherDiscount -
                                totalDiscount
                            ) +
                              countPerformingRight() +
                              countBankFee() +
                              countAdminFee() +
                              countTax()
                          )}
                        </span>
                      </div>
                    </div>
                    <button
                      style={{ boxShadow: "0 0 100px black" }}
                      onClick={() => paymentHandler()}
                      className={`${
                        ticket?.items?.length < 1 || !snk
                          ? "bg-gray-500"
                          : "bg-primary"
                      } text-black rounded-md p-2 px-5 font-semibold`}
                      disabled={
                        !snk ||
                        detail?.is_corporate ||
                        new Date(detail?.end_date) < Date.now()
                      }
                    >
                      {loading ? "Loading..." : "Beli Tiket"}
                    </button>
                    <div className="text-gray-400 text-sm flex-1 basis-[100%]">
                      <p>Harga Termasuk Pajak dan Biaya Admin</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col-reverse lg:flex-row lg:space-x-12 mt-5">
              {/* Box 1  */}
              <div className="w-full">
                {/* Deskripsi Event  */}
                <div className="py-5 border-b border-[#434343]">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <div className="h-[30px] w-[3px] bg-primary"></div>
                      <div>Deskripsi Event</div>
                    </div>
                    <ChevronDownIcon
                      id="event"
                      onClick={(e) => dropdownHandler(e)}
                      className={`${
                        dropdown.event ? "rotate-0" : "rotate-[180deg]"
                      } transition-all h-[1rem] w-auto cursor-pointer`}
                    />
                  </div>
                  <div
                    className={`${
                      dropdown.event ? "max-h-0" : "max-h-[100rem] mt-5"
                    } transition-all overflow-hidden text-white`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    ></div>
                  </div>
                </div>

                {/* Promo  */}
                <div className="py-5 border-b border-[#434343] flex flex-col space-y-5">
                  <div className="flex items-center space-x-2">
                    <div className="h-[30px] w-[3px] bg-primary"></div>
                    <div>Promo yang berlaku</div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div>
                      <ExclamationCircleIcon className="h-[2rem] w-auto text-primary" />
                    </div>
                    {!detail?.promo_badges?.length &&
                    !detail?.promo_channel_payments?.length &&
                    !detail?.promo_promotors?.length &&
                    !vouchers.length ? (
                      <div>Promo tidak tersedia untuk event ini</div>
                    ) : (
                      <div>
                        Promo akan otomatis terpakai jika syaratnya terpenuhi
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col ">
                    {detail?.promo_badges?.length ? (
                      <>
                        <div className="flex align-middle justify-between mb-3">
                          <div className="font-bold text-sm ">Promo Badge</div>
                          <div className="font-normal text-sm text-zinc-500">
                            {detail.promo_badges.length} Promo
                          </div>
                        </div>
                        <div className="grid xl:grid-cols-2 grid-cols-1 gap-x-3 gap-y-3">
                          {detail.promo_badges.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`flex flex-col basis-1/2 bg-background p-4 rounded-md space-y-2`}
                              >
                                <span>
                                  {`Diskon ${
                                    item.discount
                                  }%, Maks ${formatRupiah(
                                    item.max_discount ?? 0
                                  )}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <span className="line-clamp-1">
                                  <span className="text-[5px] px-3">{`  \u2B24 `}</span>
                                  {`Khusus pelanggan ${item.badge.name}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <div className="flex align-middle">
                                  <img
                                    className="mr-3"
                                    src={ListImages.Clock}
                                    alt=""
                                  />
                                  <span>{`Sampai ${format(
                                    new Date(detail.end_date),
                                    "dd MMMM yyy",
                                    { locale: idID }
                                  )}`}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </>
                    ) : null}
                    {detail?.promo_channel_payments?.length ? (
                      <>
                        <div className="flex align-middle justify-between my-3">
                          <div className="font-bold text-sm ">
                            Promo Metode Pembayaran
                          </div>
                          <div className="font-normal text-sm text-zinc-500">
                            {detail?.promo_channel_payments.length} Promo
                          </div>
                        </div>
                        <div className="grid xl:grid-cols-2 grid-cols-1 gap-x-3 gap-y-3">
                          {detail.promo_channel_payments.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`flex flex-col basis-1/2 bg-background p-4 rounded-md space-y-2`}
                              >
                                <span>
                                  {`Diskon ${
                                    item.discount
                                  }%, Maks ${formatRupiah(
                                    item.max_discount ?? 0
                                  )}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <span className="line-clamp-1">
                                  <span className="text-[5px] px-3">{`  \u2B24 `}</span>
                                  {`Pembayaran dengan ${item.bank.name}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <div className="flex align-middle">
                                  <img
                                    className="mr-3"
                                    src={ListImages.Clock}
                                    alt=""
                                  />
                                  <span>{`Sampai ${format(
                                    new Date(detail.end_date),
                                    "dd MMMM yyy",
                                    { locale: idID }
                                  )}`}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </>
                    ) : null}
                    {detail?.promo_promotors?.length ? (
                      <>
                        <div className="flex align-middle justify-between my-3">
                          <div className="font-bold text-sm ">
                            Promo Promotor
                          </div>
                          <div className="font-normal text-sm text-zinc-500">
                            {detail?.promo_promotors.length} Promo
                          </div>
                        </div>
                        <div className="grid xl:grid-cols-2 grid-cols-1 gap-x-3 gap-y-3">
                          {detail.promo_promotors.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`flex flex-col basis-1/2 bg-background p-4 rounded-md space-y-2`}
                              >
                                <span>
                                  {`Diskon ${
                                    item.discount
                                  }%, Maks ${formatRupiah(
                                    item.max_discount ?? 0
                                  )}`}
                                </span>
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                {item.promo_promotor_requirements.map(
                                  (requirement, index) => (
                                    <span key={index} className="line-clamp-1">
                                      <span className="text-[5px] px-3">{`  \u2B24 `}</span>
                                      {`Min Order. ${requirement.min_buy}, Tiket ${requirement.ticket_category.categories_name}`}
                                    </span>
                                  )
                                )}
                                <span className="h-[1px] bg-lighter-black w-full"></span>
                                <div className="flex align-middle">
                                  <img
                                    className="mr-3"
                                    src={ListImages.Clock}
                                    alt=""
                                  />
                                  <span>{`Sampai ${format(
                                    new Date(detail.end_date),
                                    "dd MMMM yyy",
                                    { locale: idID }
                                  )}`}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </>
                    ) : null}
                    {vouchers.length ? (
                      <>
                        <div className="flex align-middle justify-between my-3">
                          <div className="font-bold text-sm ">Voucher code</div>
                          <div className="font-normal text-sm text-zinc-500">
                            {vouchers.length} Promo
                          </div>
                        </div>
                        <VoucherCodeGrid vouchers={vouchers} />
                      </>
                    ) : null}
                  </div>
                </div>
                {/* Promo End  */}

                {/* Penukaran Tiket  */}
                <div className="py-5 border-b border-[#434343] flex flex-col space-y-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <div className="h-[30px] w-[3px] bg-primary"></div>
                      <div>Penukaran Tiket</div>
                    </div>
                    <ChevronDownIcon
                      id="tiket"
                      onClick={(e) => dropdownHandler(e)}
                      className={`${
                        dropdown.tiket ? "rotate-0" : "rotate-[180deg]"
                      } transition-all h-[1rem] w-auto cursor-pointer`}
                    />
                  </div>
                  <div
                    className={`${
                      dropdown.tiket ? "max-h-0" : "max-h-[100rem] mt-5"
                    } transition-all overflow-hidden`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: window.atob(detail?.exchange_info ?? ""),
                      }}
                    ></div>
                  </div>
                </div>
                {/* Penukaran Tiket End  */}

                {/* Tiket Sponsor  */}
                {masterSponsor.data.length !== 0 && (
                  <div className="py-5 border-b  border-[#434343] flex flex-col space-y-1">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <div className="h-[30px] w-[3px] bg-primary"></div>
                        <div>Tiket Sponsor</div>
                      </div>
                      <ChevronDownIcon
                        id="tiketsponsor"
                        onClick={(e) => dropdownHandler(e)}
                        className={`${
                          dropdown.tiketsponsor ? "rotate-0" : "rotate-[180deg]"
                        } transition-all h-[1rem] w-auto cursor-pointer`}
                      />
                    </div>
                    <div
                      className={`${
                        dropdown.tiketsponsor
                          ? "max-h-0"
                          : "max-h-[100rem] mt-5"
                      } transition-all overflow-hidden`}
                    >
                      <div className="flex flex-col gap-10 mt-10 justify-center items-center">
                        <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 content-start">
                          {masterSponsor?.data?.map((sponsor, index) => (
                            <div
                              id={sponsor.id}
                              onClick={(e) => setSponsorId(e.currentTarget.id)}
                            >
                              <img
                                src={sponsor.logo}
                                alt={index}
                                className="w-[80%] h-[80%] rounded-lg opacity-100 transition-all duration-300 hover:opacity-50"
                              />
                            </div>
                          ))}
                        </div>

                        {dataSponsor?.ticket_categories?.length !== 0 &&
                        dataSponsor ? (
                          <div className="flex flex-row gap-5 w-full">
                            {dataSponsor?.ticket_categories?.map(
                              (category, index) => {
                                let current = ticket.items.find(
                                  (item) => item.id === category.id
                                )
                                return (
                                  <>
                                    {category.session_id && (
                                      <TicketSponsor
                                        key={index}
                                        name={category.categories_name}
                                        description={category.description}
                                        start_time={detail?.start_time}
                                        date={
                                          category.event_session
                                            ? category.event_session
                                                .start_session
                                            : "2000-01-01"
                                        }
                                        price={
                                          category.price !== null
                                            ? category.price
                                            : "999999"
                                        }
                                        qty={current ? current.qty : 0}
                                        onRemove={() =>
                                          dispatch({
                                            type: "removeTicket",
                                            payload: category,
                                          })
                                        }
                                        onAdd={() =>
                                          dispatch({
                                            type: "addTicket",
                                            payload: category,
                                          })
                                        }
                                        isFullBooked={category.is_full_booked}
                                        isSoldOut={category.is_sold_out}
                                        expiredDate={category.due_date}
                                        availableOn={category.available_buy_on}
                                        accessibleOn={category.accessible_on}
                                      />
                                    )}
                                  </>
                                )
                              }
                            )}
                          </div>
                        ) : dataSponsor === undefined ? (
                          <text className="text-white font-semibold">
                            Pilih sponsor terlebih dahulu
                          </text>
                        ) : (
                          <text className="text-white font-semibold">
                            Tidak ada tiket sponsor
                          </text>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Tiket Sponsor End  */}

                {/* Tiket  */}
                <div className="py-5 border-b border-[#434343] flex flex-col space-y-5">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <div className="h-[30px] w-[3px] bg-primary"></div>
                      <div>Tiket</div>
                    </div>
                    <div
                      onClick={() => setSeatingPlanWindow(true)}
                      className="text-primary cursor-pointer"
                    >
                      Lihat Seating Plan
                    </div>
                  </div>
                  {detail?.ticket_categories?.map((category, index) => {
                    let current = ticket.items.find(
                      (item) => item.id === category.id
                    )
                    return (
                      <>
                        {category.session_id && (
                          <Ticket
                            key={index}
                            name={category.categories_name}
                            description={category.description}
                            start_time={category?.event_session?.start_session}
                            date={
                              category.event_session
                                ? category.event_session.start_session
                                : "2000-01-01"
                            }
                            price={
                              category.price !== null
                                ? category.price
                                : "999999"
                            }
                            qty={current ? current.qty : 0}
                            onRemove={() =>
                              dispatch({
                                type: "removeTicket",
                                payload: category,
                              })
                            }
                            onAdd={() =>
                              dispatch({ type: "addTicket", payload: category })
                            }
                            isFullBooked={category.is_full_booked}
                            isSoldOut={category.is_sold_out}
                            expiredDate={category.due_date}
                            availableOn={category.available_buy_on}
                            accessibleOn={category.accessible_on}
                          />
                        )}
                      </>
                    )
                  })}
                </div>
                {/* Tiket End  */}

                {/* Data Pembeli  */}
                {/* border bottom kalo misal tiket free gk muncul */}
                <div
                  className={`py-5 ${
                    handleShowPayment() ? "" : "border-b border-[#434343]"
                  }  flex flex-col space-y-5`}
                >
                  <div className="flex items-center space-x-2">
                    <div className="h-[30px] w-[3px] bg-primary"></div>
                    <div>Masukkan Data Pembeli</div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div>
                      <ExclamationCircleIcon className="h-[2rem] w-auto text-primary" />
                    </div>
                    <div>
                      E-ticket akan dikirimkan melalui email. Pastikan email
                      yang diberikan sudah benar.
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div className="flex flex-col space-y-2">
                      <label>NIK</label>
                      <input
                        onChange={(e) => inputHandler(e)}
                        value={inputData.nik}
                        id="nik"
                        type="number"
                        className="bg-background p-2 rounded-md"
                        placeholder="No KTP"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label>Nama Lengkap</label>
                      <input
                        onChange={(e) => inputHandler(e)}
                        value={inputData.fullname}
                        id="fullname"
                        className="bg-background p-2 rounded-md"
                        placeholder="Nama Lengkap"
                      />
                    </div>

                    <div className="flex flex-col space-y-2">
                      <label>Email</label>
                      <input
                        onChange={(e) => inputHandler(e)}
                        value={inputData.email}
                        id="email"
                        type="email"
                        className="bg-background p-2 rounded-md"
                        placeholder="Email"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label>Nomor Telepon</label>
                      <input
                        onChange={(e) => inputHandler(e)}
                        value={inputData.phone_number}
                        id="phone_number"
                        type="number"
                        className="bg-background p-2 rounded-md"
                        placeholder="Nomor Telepon"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label>Tanggal Lahir</label>
                      <input
                        onChange={(e) => inputHandler(e)}
                        id="birthdate"
                        type="date"
                        value={inputData.birthdate}
                        className="bg-background p-2 rounded-md w-full"
                        placeholder="Tanggal Lahir"
                      />
                    </div>

                    <div className="flex flex-col space-y-2">
                      <label>Kota Domisili</label>
                      <input
                        onChange={(e) => inputHandler(e)}
                        value={inputData.location}
                        id="location"
                        className="bg-background p-2 rounded-md"
                        placeholder="Kota Domisili"
                      />
                    </div>
                    {/* {handleShowPayment() && (
                      <>
                        <div className="flex flex-col space-y-2">
                          <label>{`Wishlist di ${detail?.title} (brand/partner)?`}</label>
                          <input
                            onChange={(e) => inputHandler(e)}
                            value={inputData.location}
                            id="location"
                            className="bg-background p-2 rounded-md"
                            placeholder="Wishlist Brand"
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label>{`Tau informasi ${detail?.title} dari mana?`}</label>
                          <input
                            onChange={(e) => inputHandler(e)}
                            value={inputData.location}
                            id="location"
                            className="bg-background p-2 rounded-md"
                          />
                        </div>
                      </>
                    )} */}
                    {(detail.slug === "winter-concert-day-1" ||
                      detail.slug === "winter-concert-day-2" ||
                      detail.slug === "winter-concert-afternoon-special-show" ||
                      detail.slug === "winter-concert-x-irresistible-bazaar") && (
                      <>
                        <div className="flex flex-col space-y-2">
                          <label>{`Sebutkan ukuran sepatu kamu dan teman kamu`}</label>
                          <input
                            placeholder="40, 41, 42, dst ... "
                            onChange={(e) =>
                              setDataSurvey(e.currentTarget.value)
                            }
                            value={dataSurvey}
                            className="bg-background p-2 rounded-md"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* Data End  */}

                {/* Metode Pembayaran */}

                {handleShowPayment() ? null : (
                  <div className="pt-5 border-b border-[#434343] flex flex-col space-y-5">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <div className="h-[30px] w-[3px] bg-primary"></div>
                        <div>Pilih Metode Pembayaran</div>
                      </div>
                      <ChevronDownIcon
                        id="pembayaran"
                        onClick={(e) => dropdownHandler(e)}
                        className={`${
                          dropdown.pembayaran ? "rotate-0" : "rotate-[180deg]"
                        } transition-all h-[1rem] w-auto cursor-pointer`}
                      />
                    </div>
                    <div
                      className={`${
                        dropdown.pembayaran ? "max-h-0" : "max-h-[100rem] mt-5"
                      } transition-all overflow-hidden`}
                    >
                      <div className="pb-5 flex flex-col space-y-3">
                        {paymentMethods.length ? (
                          <>
                            <h5 className="text-sm font-bold leading-5">
                              E-Wallet
                            </h5>
                            <div className="grid grid-cols-2 lg:grid-cols-4 w-fit gap-6 items-center">
                              {renderSingleMethod(
                                paymentMethods.find(
                                  (method) => method.bank_code === "GOPAY"
                                )
                              )}
                            </div>
                            <h5 className="text-sm font-bold leading-5">
                              Virtual Account
                            </h5>
                            <div className="grid grid-cols-2 lg:grid-cols-4 w-fit gap-6 items-center">
                              {paymentMethods.map((x, index) => {
                                if (
                                  x.stat !== "ON" ||
                                  ["GOPAY", "CREDITCARD"].includes(x.bank_code)
                                ) {
                                  return <Fragment></Fragment>
                                }
                                return (
                                  <div
                                    key={index}
                                    className="flex items-center space-x-3 cursor-pointer"
                                  >
                                    <input
                                      name="payment"
                                      type="radio"
                                      id={x.id}
                                      onChange={(e) => methodHandler(e)}
                                    />
                                    <label htmlFor={x.id}>
                                      <img
                                        className="h-[3rem] w-[8rem] cursor-pointer"
                                        id={x.name}
                                        src={getPaymentMethodImage(x.bank_code)}
                                        alt={x.name}
                                      />
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                            {handleTextCreditCard(
                              paymentMethods.find(
                                (method) => method.bank_code === "CREDITCARD"
                              )
                            )}

                            {renderSingleMethod(
                              paymentMethods.find(
                                (method) => method.bank_code === "CREDITCARD"
                              )
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}

                {/* Data End  */}
              </div>

              {/* Box 2  */}
              <div className="flex flex-col space-y-3">
                {/* Detail  */}
                <div
                  className="p-5 flex flex-col bg-[#191919] rounded-md"
                  style={{ boxShadow: "0 0 20px black" }}
                >
                  <div>{detail?.title}</div>
                  <div className="flex flex-col space-y-2 mt-3">
                    <div className="flex items-center space-x-2">
                      <MapIcon className="h-[1rem] w-auto text-primary" />
                      <div className="capitalize">
                        {detail?.venue_name}, {detail?.venue_location}{" "}
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <CalendarDaysIcon className="h-[1rem] w-auto text-primary" />
                      <div>
                        {format(
                          new Date(detail?.start_date ?? Date.now()),
                          "dd MMMM yyyy",
                          { locale: idID }
                        )}
                      </div>
                    </div>
                    {/* <div className="flex items-center space-x-2">
                      <ClockIcon className="h-[1rem] w-auto text-primary" />
                      <div>
                        {format(
                          new Date(
                            `1970-01-01T${detail?.start_time ?? "18:00:00"}`
                          ),
                          "p"
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* Line Up Artist */}
                <ArtistList artists={detail?.event_artists ?? []} />
                {/* Line Up Artist */}

                {/* Order Summary */}
                <div
                  className="hidden p-5 lg:flex flex-col space-y-5 bg-[#191919] rounded-md sticky top-[13vh]"
                  style={{ boxShadow: "0 0 20px black" }}
                >
                  <div className="flex items-center justify-between">
                    <div>Order Summary</div>
                  </div>
                  <div className="pb-4 border-dashed border-primary border-b-4">
                    {`We keep your ticket(s) for ${
                      detail?.book_time_limit_minute ?? 60
                    } Minutes . After the timer end, we
              will released your ticket .`}
                  </div>
                  {ticket.items?.map((x, index) => {
                    return (
                      <div
                        key={index}
                        className={`${
                          index !== ticket.items.length - 1
                            ? "border-b-4 border-dashed border-[#434343] pb-3"
                            : ""
                        } flex justify-between`}
                      >
                        <div className="flex items-start space-x-2">
                          <img
                            className="mt-1"
                            src={ListImages.Ticket}
                            alt=""
                          />
                          <div>
                            <div className="text-[1.25rem] font-semibold uppercase">
                              {x.categories_name}
                            </div>
                            <div className="font-light">{x.qty} Tiket</div>
                          </div>
                        </div>
                        <div className="mt-auto">
                          <div>{formatRupiah(x.price * x.qty)}</div>
                        </div>
                      </div>
                    )
                  })}
                  <div className="flex flex-col border-y py-4 border-[#434343]">
                    <div className="text-primary">Mempunyai kupon promo?</div>
                    <div className="flex relative items-center">
                      {voucher ? (
                        <CheckIcon className="h-[20px] w-auto text-green-500 absolute right-0 pr-2" />
                      ) : (
                        <Spinner
                          className={`${
                            !isClaiming && "hidden"
                          } text-primary absolute right-0`}
                          innerClass="h-5 w-5"
                        />
                      )}

                      <input
                        className="flex-1 bg-background p-2 rounded-md"
                        placeholder="Masukkan kode kupon"
                        type="text"
                        onChange={(e) => {
                          setVoucherCode(e.target.value)
                        }}
                      />
                    </div>
                    <div className="text-red-500 line-clamp-3">
                      {alertVoucher}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div>
                      Subtotal{" "}
                      <span className="text-primary">
                        {ticket?.totalQuantity} Tiket
                      </span>
                    </div>
                    <div>{formatRupiah(ticket?.totalAmount)}</div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div>Diskon Promo</div>
                    <div>{`- ${formatRupiah(totalDiscount ?? 0)}`}</div>
                  </div>
                  {voucher && (
                    <div className="flex items-center justify-between">
                      <p className="line-clamp-2">{`Kupon "${voucher.code}"`}</p>
                      <div>{`- ${formatRupiah(
                        ticket.voucherDiscount ?? 0
                      )}`}</div>
                    </div>
                  )}
                  <div className="flex items-center justify-between  ">
                    <div>Biaya Lainnya</div>
                    <div>{formatRupiah(countBankFee() ?? 0)}</div>
                  </div>
                  <div className="flex items-center justify-between  ">
                    <div>Performing Right</div>
                    <div>{formatRupiah(countPerformingRight() ?? 0)}</div>
                  </div>
                  <div className="flex items-center justify-between  ">
                    <div>Biaya Admin</div>
                    <div>{formatRupiah(countAdminFee() ?? 0)}</div>
                  </div>
                  <div className="flex items-center justify-between border-b-4 border-[#434343] pb-4 border-dashed">
                    <div>Pajak</div>
                    <div>{formatRupiah(countTax() ?? 0)}</div>
                  </div>
                  <div className="flex items-center justify-between flex-wrap">
                    <div>Total</div>
                    <div>
                      {formatRupiah(
                        Math.round(
                          ticket?.totalAmount -
                            ticket?.voucherDiscount -
                            totalDiscount
                        ) +
                          countPerformingRight() +
                          countBankFee() +
                          countAdminFee() +
                          countTax()
                      )}
                    </div>
                    <div className="text-gray-400 text-sm flex-1 basis-[100%]">
                      <p>Harga Termasuk Pajak dan Biaya Admin</p>
                    </div>
                  </div>
                  <div className="text-gray-400 text-sm">
                    <input
                      checked={snk}
                      onChange={() => setSnk((old) => !old)}
                      type="checkbox"
                      id="checkbox-snk"
                      className="default:ring-2 mr-2 accent-primary"
                    />
                    <label htmlFor="checkbox-snk">
                      Saya menyetujui{" "}
                      <Link
                        to={"/syarat-dan-ketentuan"}
                        className="text-primary"
                        target={"_blank"}
                      >
                        Syarat dan Ketentuan
                      </Link>{" "}
                      serta{" "}
                      <Link
                        to={"/kebijakan-privasi"}
                        className="text-primary"
                        target={"_blank"}
                      >
                        Kebijakan Privasi
                      </Link>
                    </label>
                  </div>
                  <button
                    onClick={() => paymentHandler()}
                    className={`${
                      ticket?.items?.length < 1 || !snk
                        ? "bg-gray-500"
                        : "bg-primary"
                    } w-full font-semibold text-black p-3 rounded-md`}
                    disabled={
                      loading ||
                      !snk ||
                      detail?.is_corporate ||
                      new Date(detail?.end_date) < Date.now()
                    }
                  >
                    {loading ? "Loading..." : "Beli Tiket"}
                  </button>
                </div>
                {/* Order Summary End */}

                {/* Fixed Mobile  */}
                <div className="lg:hidden fixed bottom-0 left-0 bg-background max-h-[80vh] p-5 w-full flex flex-col">
                  <div
                    onClick={() => {
                      setShowSummary((old) => !old)
                    }}
                    className="font-montserrat font-semibold text-2xl text-secondary pb-2 flex items justify-between"
                  >
                    <div className="flex items-center space-x-2">
                      <div className="bg-primary h-full w-[3px] rounded-sm">
                        {" "}
                      </div>
                      <p className="teks12">ORDER SUMMARY</p>
                    </div>
                    {showSummary ? (
                      <ArrowUp2 size="32" className="text-secondary" />
                    ) : (
                      <ArrowDown2 size="32" className="text-secondary" />
                    )}
                  </div>
                  <div
                    onClick={() => {
                      setShowSummary((old) => !old)
                    }}
                  >
                    <div className="hiddenmobile">
                      {`We keep your ticket(s) for ${
                        detail?.book_time_limit_minute ?? 60
                      } Minutes . After the timer end, we will released your ticket .`}
                    </div>
                  </div>
                  <div
                    className={`${
                      !showSummary && "hidden"
                    } transition-all bg-gh-black-100 px-2 flex-1 max-h-[40vh] overflow-y-scroll border-t-2 border-b-2 border-dashed border-t-primary border-b-primary-disabled py-4 mt-4 flex flex-col space-y-3`}
                  >
                    {ticket.items?.map((x, index) => {
                      return (
                        <div
                          key={index}
                          className={`${
                            index !== ticket.items.length - 1
                              ? "border-b-4 border-dashed border-[#434343] pb-3"
                              : ""
                          } flex justify-between`}
                        >
                          <div className="flex items-start space-x-2">
                            <img
                              className="mt-1"
                              src={ListImages.Ticket}
                              alt=""
                            />
                            <div>
                              <div className="text-[1.25rem] font-semibold uppercase">
                                {x.categories_name}
                              </div>
                              <div className="font-light">{x.qty} Tiket</div>
                            </div>
                          </div>
                          <div className="mt-auto">
                            <div>{formatRupiah(x.price * x.qty)}</div>
                          </div>
                        </div>
                      )
                    })}
                    <div className="flex flex-col border-b py-2 border-[#434343]">
                      <div className="text-primary teks12">
                        Mempunyai kupon promo?
                      </div>
                      <div className="flex relative items-center">
                        {voucher ? (
                          <CheckIcon className="h-[20px] w-auto text-green-500 absolute right-0 pr-2" />
                        ) : (
                          <Spinner
                            className={`${
                              !isClaiming && "hidden"
                            } text-primary absolute right-0`}
                            innerClass="h-5 w-5"
                          />
                        )}
                        <input
                          className="teks10 flex-1 bg-background p-2 rounded-md"
                          placeholder="Masukkan kode kupon"
                          type="text"
                          onChange={(e) => {
                            setVoucherCode(e.target.value)
                          }}
                        />
                      </div>
                      <div className="text-red-500 text-clamp-3">
                        {alertVoucher}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="teks12">
                        Subtotal{" "}
                        <span className="text-primary teks12">
                          {ticket?.totalQuantity} Tiket
                        </span>
                      </div>
                      <div className="teks12">
                        {formatRupiah(ticket?.totalAmount)}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="teks12">Diskon Promo</div>
                      <div className="teks12">{`- ${formatRupiah(
                        totalDiscount ?? 0
                      )}`}</div>
                    </div>
                    {voucher && (
                      <div className="flex items-center justify-between">
                        <p className="line-clamp-2 teks12">{`Kupon "${voucher.code}"`}</p>
                        <div className="teks12">{`- ${formatRupiah(
                          ticket.voucherDiscount ?? 0
                        )}`}</div>
                      </div>
                    )}
                    <div className="flex items-center justify-between  ">
                      <div className="teks12">Biaya Lainnya</div>
                      <div className="teks12">
                        {formatRupiah(countBankFee() ?? 0)}
                      </div>
                    </div>
                    <div className="flex items-center justify-between  ">
                      <div className="teks12">Performing Right</div>
                      <div className="teks12">
                        {formatRupiah(countPerformingRight() ?? 0)}
                      </div>
                    </div>
                    <div className="flex items-center justify-between  ">
                      <div className="teks12">Biaya Admin</div>
                      <div className="teks12">
                        {formatRupiah(countAdminFee() ?? 0)}
                      </div>
                    </div>
                    <div className="flex items-center justify-between ">
                      <div className="teks12">Pajak</div>
                      <div className="teks12">
                        {formatRupiah(countTax() ?? 0)}
                      </div>
                    </div>
                  </div>
                  <div className="text-gray-400 text-sm py-2">
                    <input
                      checked={snk}
                      onChange={() => {
                        setSnk((old) => {
                          return !old
                        })
                      }}
                      type="checkbox"
                      id="checkbox-snk"
                      name="checkbox-snk"
                      className="default:ring-2 mr-2 accent-primary "
                    />

                    <label htmlFor="checkbox-snk" className="teks10">
                      Saya menyetujui{" "}
                      <Link
                        to={"/syarat-dan-ketentuan"}
                        className="text-primary"
                        target={"_blank"}
                      >
                        Syarat dan Ketentuan
                      </Link>{" "}
                      serta{" "}
                      <Link
                        to={"/kebijakan-privasi"}
                        className="text-primary"
                        target={"_blank"}
                      >
                        Kebijakan Privasi
                      </Link>
                    </label>
                  </div>
                  <div className="flex items-center justify-between flex-wrap">
                    <div className="flex justify-between items-center flex-1 pr-4">
                      <div>
                        <div>Total</div>
                        <div className="text-primary">
                          {ticket?.items.length} Tiket
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setShowSummary((old) => !old)
                        }}
                        className="flex items-center space-x-1"
                      >
                        <span>
                          {formatRupiah(
                            Math.round(
                              ticket?.totalAmount -
                                ticket?.voucherDiscount -
                                totalDiscount
                            ) +
                              countPerformingRight() +
                              countBankFee() +
                              countAdminFee() +
                              countTax()
                          )}
                        </span>
                      </div>
                    </div>
                    <button
                      style={{ boxShadow: "0 0 100px black" }}
                      onClick={() => paymentHandler()}
                      className={`${
                        ticket?.items?.length < 1 || !snk
                          ? "bg-gray-500"
                          : "bg-primary"
                      } text-black rounded-md p-2 px-5 font-semibold`}
                      disabled={
                        !snk ||
                        detail?.is_corporate ||
                        new Date(detail?.end_date) < Date.now()
                      }
                    >
                      {loading ? "Loading..." : "Beli Tiket"}
                    </button>
                    <div className="text-gray-400 text-sm flex-1 basis-[100%]">
                      <p className="teks10">
                        Harga Termasuk Pajak dan Biaya Admin
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default EventDetail
