import React, { useEffect } from "react"
import { MinusIcon, PlusIcon, ChevronDownIcon } from "@heroicons/react/24/solid"
import { useState } from "react"
import { format } from "date-fns"
import { formatRupiah } from "../utils"
import { id as idID } from "date-fns/locale"
import formatInTimeZone from "date-fns-tz/formatInTimeZone"

function Ticket({
  name,
  date,
  description,
  price,
  start_time,
  qty,
  onAdd,
  onRemove,
  isFullBooked,
  isSoldOut,
  expiredDate,
  availableOn,
  accessibleOn,
}) {
  const [full, setFull] = useState(false)
  const [ticketSoldOut, setTicketSoldOut] = useState(false)

  const dropdownHandler = () => {
    setFull(!full)
  }

  useEffect(() => {
    setTicketSoldOut(
      isSoldOut || new Date() >= new Date(expiredDate ?? Date.now())
    )
  }, [expiredDate, isSoldOut])

  return (
    <div className="tiket rounded-md flex flex-col space-y-2 bg-background overflow-x-hidden">
      <div
        className={`uppercase text-[1.5rem] teks15 font-semibold px-5 pt-3 ${
          ticketSoldOut ? "text-[#5A5A5A]" : ""
        }`}
      >
        {name}
      </div>
      <div
        className={` px-5 flex align-middle teks10 ${
          ticketSoldOut ? "text-[#5A5A5A]" : "text-primary"
        }`}
      >
        {`${format(new Date(date), "d MMMM yyyy", { locale: idID })} `}
        <span className="text-[5px] my-auto px-3">{`  \u2B24 `}</span>{" "}
        {`${formatInTimeZone(start_time, "Asia/Bangkok", "HH:mm")} WIB`}
        {/* {`${start_time.substring(11, 13)}.${start_time.substring(14, 16)} WIB`} */}
      </div>
      <div className="flex px-5">
        <div
          className={`mb-5 teks10 transition-all flex-1 ${
            ticketSoldOut ? "text-[#5A5A5A]" : ""
          }`}
        >
          {full
            ? description || ""
            : description?.substring(0, 100) + "..." || ""}
        </div>
        <div>
          <ChevronDownIcon
            onClick={() => dropdownHandler()}
            className={`${
              full ? "rotate-[180deg]" : "rotate-0"
            } transition-all h-[1.5rem] w-auto cursor-pointer ${
              ticketSoldOut ? "text-[#5A5A5A]" : "text-white"
            }`}
          />
        </div>
      </div>
      <div
        className={`relative flex items-center justify-between p-5 border-t-4 border-dashed ${
          ticketSoldOut ? "border-[#5A5A5A]" : "border-primary"
        } `}
      >
        <div className="h-[30px] w-[30px] bg-gh-black-200 rounded-tr-full rounded-br-full  absolute left-[-2%] top-[-25%]"></div>
        <div className="h-[30px] w-[30px] bg-gh-black-200 rounded-tl-full rounded-bl-full absolute right-[-2%] top-[-25%]"></div>
        <div
          className={`font-semibold teks12 text-[1.25rem] ${
            ticketSoldOut ? "text-[#5A5A5A]" : ""
          }`}
        >
          {formatRupiah(price)}
        </div>
        <div className="flex items-center space-x-5">
          {ticketSoldOut ? (
            <span className="teks12 text-[#ED4040] text-base font-semibold leading-[150%]">
              SOLD OUT
            </span>
          ) : isFullBooked ? (
            <span className="teks12 text-primary text-base font-semibold leading-[150%]">
              FULL BOOKED
            </span>
          ) : new Date(availableOn) > new Date() ? (
            <span className="teks12 text-primary text-base font-semibold leading-[150%]">
              COMING SOON
            </span>
          ) : (
            <>
              <div
                onClick={onRemove}
                className="p-2 padding1mobile rounded-full border-[2px] border-primary cursor-pointer"
              >
                <MinusIcon className="h-[1rem] font-bold w-auto text-primary " />
              </div>
              <div className="teks12">{qty ? qty : 0}</div>
              <div
                onClick={onAdd}
                className="p-2 padding1mobile rounded-full border-[2px] border-primary cursor-pointer"
              >
                <PlusIcon className="h-[1rem] font-bold w-auto text-primary " />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Ticket
