import React from "react";
import Logo from "../assets/logo.png";
import { MapIcon, PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import ListImages from "../assets";
import { useLocation } from "react-router-dom";

function Footer() {
  return (
    <div className="text-white bg-gh-black-300 p-5 pb-24 lg:p-12 lg:px-36 flex justify-center">
      <div className="container">
        <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 justify-between md:space-x-12 pb-6">
          <div className="flex flex-col space-y-3 font-light w-[100%] lg:w-[25%]">
            <img className="w-[10rem] h-auto" src={Logo} alt="" />
            <div className="flex space-x-2">
              <MapIcon className="h-[1.5rem] w-[1.5rem]  min-w-[1.5rem] text-primary" />
              <div>PT GUEHADIR UNTUK INDONESIA, Jakarta Selatan - Indonesia</div>
            </div>
            <div className="flex space-x-2">
              <EnvelopeIcon className="h-[1.5rem] w-auto text-primary" />
              <div>sosmed@guehadir.id</div>
            </div>
          </div>

          <div className="w-[100%] lg:w-[25%]">
            <div className="font-bold">Dukungan</div>
            <div className="flex flex-col space-y-3 mt-3 font-light">
              <a href="/faq">F.A.Q</a>
              <a href="/kebijakan-privasi">Kebijakan Privasi</a>
              <a href="/syarat-dan-ketentuan">Syarat dan Ketentuan</a>
            </div>
          </div>

          <div className="w-[100%] lg:w-[25%]">
            <div className="font-semibold">Perusahaan</div>
            <div className="flex flex-col space-y-3 mt-3 font-light">
              <a href="/tentang-kami">Tentang Kami</a>
              <a href="/news/all">GH News</a>
            </div>
          </div>

          <div className="w-[100%] lg:w-[25%]">
            <div className="font-bold">Ikuti Kami di</div>
            <div className="flex items-center space-x-3 mt-3">
              <a
                href="https://www.instagram.com/guehadir.id"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="" src={ListImages.instagram} />
              </a>
              {/* <a
                href="https://www.tiktok.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="" src={ListImages.tiktok} />
              </a> */}
              <a
                href=" https://www.facebook.com/profile.php?id=100086271894567"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="" src={ListImages.facebook} />
              </a>
              {/* <a
                href="https://wa.me/+6285280540540"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="" src={ListImages.whatsapp} />
              </a> */}
              <a
                href="https://twitter.com/guehadir_id"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="" src={ListImages.twitter} />
              </a>
            </div>
          </div>
        </div>
        <div className="my-6 w-full bg-white h-[1px]"></div>
        <div className="text-center small">
          Copyright {new Date().getFullYear()} © GueHadir.id All Rights Reserved
        </div>
      </div>
    </div>
  );
}

export default Footer;
