export function AllPhotosEvent() {
  return (
    <div className="container flex flex-col py-6 space-y-4 px-4 lg:px-0">
      <div className="animate-pulse w-[44%] h-8 bg-zinc-700 rounded-sm"></div>
      <div className="flex pt-2 flex-row w-full gap-3">
        <div className="animate-pulse w-20 h-11 bg-zinc-700 rounded-md"></div>
        <div className="animate-pulse w-20 h-11 bg-zinc-700 rounded-md"></div>
        <div className="animate-pulse w-20 h-11 bg-zinc-700 rounded-md"></div>
        <div className="animate-pulse w-20 h-11 bg-zinc-700 rounded-md"></div>
        <div className="animate-pulse w-20 h-11 bg-zinc-700 rounded-md"></div>
      </div>
      <div className="grid pt-8 grid-cols-4 gap-2">
        <div className="animate-pulse w-full h-[246px] bg-zinc-700 rounded-md" />
        <div className="animate-pulse w-full h-[246px] bg-zinc-700 rounded-md" />
        <div className="animate-pulse w-full h-[246px] bg-zinc-700 rounded-md" />
        <div className="animate-pulse w-full h-[246px] bg-zinc-700 rounded-md" />
        <div className="animate-pulse w-full h-[246px] bg-zinc-700 rounded-md" />
        <div className="animate-pulse w-full h-[246px] bg-zinc-700 rounded-md" />
        <div className="animate-pulse w-full h-[246px] bg-zinc-700 rounded-md" />
        <div className="animate-pulse w-full h-[246px] bg-zinc-700 rounded-md" />
      </div>
    </div>
  )
}
