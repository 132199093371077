import { useStoreActions, useStoreState } from "easy-peasy";
import { GHButton } from "./GHSystem";
import { XMarkIcon } from "@heroicons/react/24/solid";

const ModalLogin = () => {
  const popover = useStoreState((state) => state.popover);
  const togglePopover = useStoreActions((action) => action.togglePopover);
  return (
    <div
      className={`fixed h-screen w-screen flex justify-center items-center z-[1030] bg-black bg-opacity-80 ${
        popover === "login" ? "" : "hidden"
      }`}
      onClick={(e) => {
        togglePopover(e);
      }}
    >
      <div
        className="bg-gh-black-200 mx-4 rounded-[10px] flex flex-col items-stretch p-4 max-w-[398px] text-secondary"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex justify-between pb-2">
          <p className="font-semibold text-base">Silahkan Login</p>
          <XMarkIcon
            onClick={(e) => {
              togglePopover(e);
            }}
            className="h-6 cursor-pointer"
          ></XMarkIcon>
        </div>
        <p className="pb-10">
          Harap Login terlebih dahulu untuk menikmati fitur collecetion dan
          dapat langsung sekali download semua fotonya
        </p>
        <GHButton
          onClick={(e) => {
            togglePopover(e);
          }}
          data-toggle-popover="masuk"
        >
          Login
        </GHButton>
        <p className="self-center pt-4 text-center">
          Belum memiliki akun GueHadir.id?{" "}
          <span
            onClick={(e) => {
              togglePopover(e);
            }}
            data-toggle-popover="daftar"
            className="self-center text-primary cursor-pointer"
          >
            Daftar disini
          </span>
        </p>
      </div>
    </div>
  );
};

export default ModalLogin;
