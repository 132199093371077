import React, { useState } from "react"
import { MagnifyingGlassIcon, CalendarIcon } from "@heroicons/react/24/solid"
import ListImages from "../assets"
import Slider from "../components/slider"
import { useEffect } from "react"
import axios from "axios"
import EventCard from "../components/eventCard"
import NewsCard from "../components/newsCard"
import { DateRangePicker } from "react-date-range"
import { addDays, subDays } from "date-fns"
import { useLoaderData, useNavigate } from "react-router-dom"
import { GHButton } from "../components/GHSystem"
import { formatRupiah } from "../utils"
import { format } from "date-fns/esm"
import { id as idID } from "date-fns/locale"
import { Helmet } from "react-helmet"
import PhotoSection from "../components/PhotoSection"
import Layout from "../components/layout"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { useStoreState, useStoreActions } from "easy-peasy"
import Popover from "../components/Popover"
import { ShowSkeletonTerbaru } from "../components/partials/Event/ShowSkeletonTerbaru"
import { ShowSkeletonGHNews } from "../components/partials/Event/ShowSkeletonGHNews"
import { ShowSkeletonPopular } from "../components/partials/Event/ShowSkeletonPopular"
import { ShowSkeletonRecomended } from "../components/partials/Event/ShowSkeletonRecomended"

export const LandingPageLoader = async () => {
  const queryParams = {
    page_size: 8,
  }
  const searchParams = new URLSearchParams(queryParams)
  try {
    const photos = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }/apps/photos/all?${searchParams.toString()}`,
      {
        timeout: 2000,
      }
    )
    return { photos: photos.data.data }
  } catch (error) {
    return {
      photos: {
        count: 0,
        row: [],
        next: null,
      },
    }
  }
  // if (event.data.success) {
  //   return event.data.data as IPagination<IEvent>
  // }
  // throw json({}, { status: event.status })
}

function LandingPage() {
  // const [loading, setLoading] = useState(false);
  // const [banners, setBanners] = useState([]);

  const loaderData = useLoaderData()
  const { photos } = loaderData

  // All Events
  const [popularEvent, setPopularEvent] = useState([])
  const [latestEvent, setLatestEvent] = useState([])
  const [recommendedEvent, setRecommendedEvent] = useState([])

  // News
  const [news, setNews] = useState([])

  // Logic Handler
  const [searchEvent, setSearchEvent] = useState()
  const [inputSearch, setInputSearch] = useState("")
  const [inputMobile, setInputMobile] = useState("")
  const [activateDate, setActivateDate] = useState()
  const [showSkeletonPupular, setShowSkeletonPupular] = useState(true)
  const [showSkeletonTerbaru, setShowSkeletonTerbaru] = useState(true)
  const [showSkeletonEventBerbagaiKota, setSkeletonShowEventBerbagaiKota] =
    useState(true)
  const [showSkeletonRecommended, setShowSkeletonRecommended] = useState(true)
  const [showSkeletonGHNews, setShowSkeletonGHNews] = useState(true)
  const navigate = useNavigate()
  const togglePopover = useStoreActions((action) => action.togglePopover)
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: subDays(new Date(), 15),
      endDate: addDays(new Date(), 15),
      key: "selection",
    },
  ])

  const getSearch = async () => {
    if (inputSearch || inputMobile) {
      navigate(
        `event/all?search=${
          inputSearch === "" ||
          inputSearch === null ||
          inputSearch === undefined
            ? inputMobile.trim().toLowerCase()
            : inputSearch.trim().toLowerCase()
        }&start_date=${format(
          new Date(selectedDate[0].startDate),
          "yyyy-MM-dd"
        )}&end_date=${format(new Date(selectedDate[0].endDate), "yyyy-MM-dd")}`
      )
    }
  }

  useEffect(() => {
    // setLoading(true);

    const getPopular = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/apps/events/popular?limit=3`
      )
      setShowSkeletonPupular(false)
      setPopularEvent(response.data.data)
    }
    const getLatest = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/apps/events/latest?limit=3`
      )
      setShowSkeletonTerbaru(false)
      setLatestEvent(response.data.data)
    }
    const getRecommended = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/apps/events/recommended?limit=3`
      )
      setShowSkeletonRecommended(false)
      setRecommendedEvent(response.data.data)
    }
    //TODO: rekomendasi 2 row 3 column
    const getNews = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/apps/news?limit=3`
      )
      setNews(response.data.data)
      setShowSkeletonGHNews(false)
    }
    getPopular()
    getLatest()
    getRecommended()
    getNews()
    // setLoading(false);
  }, [])

  const deskripsi = [
    "Promo khusus hanya di aplikasi",
    "Benefit Event",
    "Penawaran Harga Terbaik",
    "Asuransi Event",
  ]

  return (
    <Layout>
      <div className="py-5 bg-gh-black-100 flex justify-center ">
        <Helmet>
          <title>Ticket Experience System | Gue Hadir</title>
          <meta
            name="description"
            content={`Gue Hadir Ticket Experience System`}
          />
          <meta
            property="og:title"
            content="Ticket Experience System | Gue Hadir"
          />
          <meta
            property="og:description"
            content="GUEHADIR.id memberikan pengalaman berbeda saat melakukan pembelian tiket. Selalu ada keuntungan di setiap pembeliannya"
          />
          <meta
            property="og:image"
            content="https://backend.guehadir.id/images/banner1.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="GUEHADIR" />
          <meta property="og:url" content="https://guehadir.id" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="guehadir.id" />
          <meta property="twitter:url" content="https://guehadir.id" />
          <meta
            name="twitter:title"
            content="Ticket Experience System | GUEHADIR"
          />
          <meta
            name="twitter:description"
            content="GUEHADIR.id memberikan pengalaman berbeda saat melakukan pembelian tiket. Selalu ada keuntungan di setiap pembeliannya"
          />
          <meta
            name="twitter:image"
            content="https://backend.guehadir.id/images/banner1.png"
          />
        </Helmet>
        <div className="container">
          <div className="relative">
            <Slider />
            {/* Search For Tablet & Desktop  */}
            <div
              style={{ boxShadow: "0 0 10px black" }}
              className="hidden p-2 rounded-md bg-[#191919] md:flex items-center space-x-3 absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] w-full lg:w-[80%] z-[998]"
            >
              <div className="flex-1 flex items-center space-x-2">
                <MagnifyingGlassIcon className="h-[1.5rem] w-auto text-white" />
                <input
                  onChange={(e) => setInputSearch(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.code === "Enter") {
                      getSearch()
                    }
                  }}
                  className="flex-1 p-1 bg-transparent outline-none border-none text-white"
                  placeholder="Search by event,name,location and more"
                />
              </div>
              <div className="h-[20px] w-[1px] bg-white"></div>
              <div className="flex flex-1 items-center space-x-2">
                <CalendarIcon
                  data-toggle-popover="search-calendar"
                  onClick={(e) => togglePopover(e)}
                  className="h-[1.5rem] w-auto text-white cursor-pointer"
                />
                <input
                  data-toggle-popover="search-calendar"
                  onClick={(e) => togglePopover(e)}
                  value={`${selectedDate[0].startDate.toDateString()} - ${selectedDate[0].endDate.toDateString()}`}
                  className="flex-1 p-1 bg-transparent outline-none border-none text-white"
                  placeholder="Search Date"
                  readOnly
                />
              </div>
              <GHButton onClick={() => getSearch()}> Search</GHButton>
              <Popover
                tag={"search-calendar"}
                className={"absolute top-[120%] left-[50%] translate-x-[-50%]"}
              >
                <DateRangePicker
                  onChange={(item) => {
                    setSelectedDate([item.selection])
                  }}
                  className={`custom-date`}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectedDate}
                  direction="horizontal"
                  color="#FC8300"
                  rangeColors={["#FC8300"]}
                />
              </Popover>
            </div>
          </div>

          {/* Serch for mobile  */}
          <div className="md:hidden bg-background rounded-md w-full flex items-center p-3 space-x-2">
            <MagnifyingGlassIcon className="text-gray-100 h-[1rem] w-auto" />
            <form
              onSubmit={(e) => {
                e.preventDefault()
                getSearch()
              }}
              className="flex-1 flex"
            >
              <input
                type="search"
                onChange={(e) => setInputMobile(e.target.value)}
                className=" flex-1 bg-transparent outline-none text-white"
                placeholder="Search by event, name, location and more !"
              />
            </form>
          </div>

          {searchEvent && (inputSearch !== "" || inputMobile !== "") ? (
            <div className="mt-5 md:mt-16 text-white">
              <div>
                Event dengan kata{" "}
                <span className="text-primary font-semibold">
                  "{inputSearch || inputMobile}"
                </span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
                {searchEvent?.map((x, index) => {
                  let image = x.event_images.find(
                    (image) => image.image_cat === "banner"
                  )
                  return (
                    <EventCard
                      key={index}
                      image={image?.image ?? x.event_images[0].image}
                      title={x.title}
                      city={x.venue_name}
                      province={x.venue_location}
                      date={x.start_date}
                      id={x.id}
                      description={x.label_description}
                      price={
                        x.ticket_categories.length
                          ? x.ticket_categories[0].price
                          : "999999"
                      }
                      slug={x.slug}
                    />
                  )
                })}
              </div>
            </div>
          ) : (
            <>
              <div className=" mx-3 lg:mx-20 xl:mx-36 mt-5 md:mt-16 text-white space-y-12">
                {/* Event Terpopuler  */}
                {showSkeletonPupular ? (
                  <ShowSkeletonPopular />
                ) : (
                  <div id="terpopuler" className="flex flex-col ">
                    <div className="flex items-center justify-between">
                      <div className="font-montserrat font-semibold text-2xl text-secondary line-clamp-2">
                        Event Terpopuler
                      </div>
                      <div
                        onClick={() => navigate("/event/all")}
                        className="text-primary cursor-pointer whitespace-nowrap"
                      >
                        Lihat Semua Event
                      </div>
                    </div>

                    {/* Content  */}
                    <div className="flex flex-1 overflow-x-scroll py-5 gap-3 scrollbar-none">
                      {popularEvent?.length ? (
                        <EventCardVone event={popularEvent[0]} />
                      ) : (
                        <EventCardOneSkeleton />
                      )}
                      <div className="flex lg:flex-col lg:flex-1 gap-3">
                        {popularEvent?.length ?? 0 > 1
                          ? popularEvent.map((event, index) => {
                              return (
                                <EventCardVtwo
                                  key={index}
                                  event={event}
                                  isFirst={index === 0}
                                />
                              )
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                )}
                {/* Event Terpopuler End  */}

                {/* Event Terbaru  */}
                {showSkeletonTerbaru ? (
                  <ShowSkeletonTerbaru />
                ) : (
                  <div id="terbaru" className=" flex flex-col">
                    <div className="flex items-center justify-between">
                      <div className="font-montserrat font-semibold text-2xl text-secondary line-clamp-2">
                        Event Terbaru
                      </div>
                      <div
                        onClick={() => navigate("/event/all")}
                        className="text-primary cursor-pointer whitespace-nowrap"
                      >
                        Lihat Semua Event
                      </div>
                    </div>
                    {/* Content  */}
                    <div className="flex overflow-x-scroll py-5 gap-3 scrollbar-none">
                      {latestEvent?.map((event, index) => {
                        return <EventCardVthree key={index} event={event} />
                      })}
                    </div>
                  </div>
                )}
                {/* Event Terbaru End  */}
                
                {/* Photo  */}
                {Boolean(photos.count) && (
                  <PhotoSection
                    photos={photos.row}
                    count={photos.count}
                    leftText={"Photo dari Berbagai Event"}
                    rightText={"Lihat Semua Photo"}
                    redirectUrl={"/photos"}
                  />
                )}

                {/* Berita  */}
                {showSkeletonGHNews ? (
                  <ShowSkeletonGHNews />
                ) : (
                  <div id="berita" className=" flex flex-col">
                    <div className="flex items-center justify-between">
                      <div className="font-montserrat font-semibold text-2xl text-secondary line-clamp-2">
                        GH News
                      </div>
                      <div
                        onClick={() => navigate("/news/all")}
                        className="text-primary cursor-pointer"
                      >
                        Lihat Semua GH News
                      </div>
                    </div>
                    <div className="flex overflow-x-scroll md:grid-cols-2 lg:grid-cols-3 gap-5 py-5 scrollbar-none">
                      {news.length
                        ? news?.map((x, index) => {
                            return (
                              <NewsCard
                                key={index}
                                image={x.image}
                                title={x.title}
                                date={x.createdAt}
                                category={x.news_category.name}
                                description={x.description}
                                id={x.slug}
                              />
                            )
                          })
                        : null}
                    </div>
                    {/* Content  */}
                  </div>
                )}

                {/* Berita End  */}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

const EventCardVone = ({ event }) => {
  const navigate = useNavigate()
  const handleNavigation = () => {
    if (event.slug) {
      navigate(`/event/${event.slug}`)
    } else {
      window.location.reload()
    }
  }
  let image = event.event_images.find((image) => image.image_cat === "banner")
  return (
    <div
      onClick={handleNavigation}
      className="hidden lg:flex w-[360px] flex-1 cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex-col"
    >
      <img
        className="flex-3 h-[150px] w-full  object-cover"
        src={image?.image ?? event.event_images[0].image}
        alt=""
      />
      <div className="flex">
        <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase ">
          <p className="text-primary font-normal text-[11px]">
            {format(new Date(event?.start_date), "MMM")}
          </p>
          <p className="font-semibold text-2xl font-montserrat">
            {format(new Date(event?.start_date), "dd")}
          </p>
        </div>
        <div className="flex flex-col flex-4 flex-1 p-3 text-sm">
          <p className="font-semibold text-base line-clamp-3">{event.title}</p>
          <p className="capitalize line-clamp-2">{`${event.venue_name}, ${event.venue_location}`}</p>
          <p>{`${format(new Date(event?.start_date), "dd MMMM yyyy", {
            locale: idID,
          })}`}</p>
          <div className="text-primary border-primary border w-fit rounded-md p-2">
            Event
          </div>
          <div className="line-clamp-2 my-[14px]">
            {event.label_description}
          </div>
          <div className="flex items-center justify-between">
            {/* <div>
              Mulai dari{" "}
              <span className="font-semibold">
                <br />
                {formatRupiah(
                  event.ticket_categories.length
                    ? event.ticket_categories.sort(
                        (a, b) => a.price - b.price
                      )[0].price
                    : 999999
                )}
              </span>{" "}
              / Pax
            </div> */}
            <GHButton onClick={handleNavigation}> Beli Tiket</GHButton>
          </div>
        </div>
      </div>
    </div>
  )
}

const EventCardOneSkeleton = () => {
  return (
    <div className="hidden lg:flex w-[360px] flex-1 cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex-col">
      <div className="animate-pulse flex-3 h-[150px] w-full object-cover bg-zinc-700"></div>
      <div className="flex">
        <div className="flex flex-col align-middle text-center px-[14px] pt-2 space-y-2">
          <div className="animate-pulse h-4 w-8 rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-8 w-8 rounded-md flex bg-zinc-700"></div>
        </div>
        <div className="flex flex-col flex-4 flex-1 p-3 text-sm space-y-1">
          <div className="animate-pulse h-5 w-full rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-4 w-[50%] rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-4 w-[20%] rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-10 w-[12%] rounded-md flex bg-zinc-700"></div>
          <div className="animate-pulse h-4 w-full rounded-full flex bg-zinc-700"></div>
          <div className="animate-pulse h-4 w-[80%] rounded-full flex bg-zinc-700"></div>

          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="animate-pulse h-3 w-10 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-20 mt-1 rounded-full flex bg-zinc-700"></div>
            </div>
            <div className="animate-pulse h-10 w-32 rounded-md flex bg-zinc-700"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EventCardVtwo = ({ event, isFirst }) => {
  const navigate = useNavigate()
  const createdAt = new Date(event.start_date)
  const handleNavigate = () => {
    if (event.slug) {
      navigate(`/event/${event.slug}`)
    } else {
      window.location.reload()
    }
  }
  let image = event.event_images.find((image) => image.image_cat === "banner")
  return (
    <div
      onClick={handleNavigate}
      className={`${
        isFirst ? "flex lg:hidden" : "flex"
      }  w-[375px] lg:w-auto lg:h-[180px] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex-col lg:flex-row`}
    >
      <img
        className="flex-3 h-[150px] lg:h-full lg:flex-none w-full lg:w-[159px] object-cover"
        src={image?.image ?? event.event_images[0].image}
        alt=""
      />
      <div className="flex lg:flex-1 flex-1">
        <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase lg:hidden ">
          <p className="text-primary font-normal text-[11px]">
            {format(createdAt, "MMM")}
          </p>
          <p className="font-semibold text-2xl font-montserrat">
            {format(createdAt, "dd")}
          </p>
        </div>
        <div className="flex flex-col flex-4 flex-1 p-3 text-sm gap-1">
          <p className="font-semibold text-base line-clamp-3 flex-1">
            {event.title}
          </p>
          <p className="line-clamp-2 capitalize">{`${event.venue_name}, ${event.venue_location}`}</p>
          <p>{`${format(createdAt, "dd MMMM yyyy", {
            locale: idID,
          })}`}</p>
          <div className="text-primary border-primary border w-fit rounded-md p-2">
            Event
          </div>
          <div className="line-clamp-2 lg:hidden my-[14px]">
            {event.label_description}
          </div>
          <div className="flex items-center justify-between">
            {/* <div>
              Mulai dari{" "}
              <span className="font-semibold">
                <br />
                {formatRupiah(
                  event.ticket_categories.length
                    ? event.ticket_categories.sort(
                        (a, b) => a.price - b.price
                      )[0].price
                    : 999999
                )}
              </span>{" "}
              / Pax
            </div> */}
            <GHButton onClick={handleNavigate}> Beli Tiket</GHButton>
          </div>
        </div>
      </div>
    </div>
  )
}

const EventCardVtwoSkeleton = ({ isFirst }) => {
  return (
    <>
      <div
        className={`${
          isFirst ? "flex lg:hidden" : "flex"
        }  w-[375px] lg:w-auto lg:h-[180px] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex-col lg:flex-row`}
      >
        <div className="animate-pulse flex-3 h-[150px] lg:h-full lg:flex-none w-full lg:w-[159px] object-cover bg-zinc-700"></div>
        <div className="flex lg:flex-1 flex-1">
          <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase lg:hidden ">
            <div className="animate-pulse h-4 w-8 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-8 w-8 mt-1 rounded-md flex bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-4 flex-1 p-3 text-sm gap-1">
            <div className="animate-pulse w-[90%] h-4 bg-zinc-700 rounded-full"></div>
            <div className="animate-pulse w-[70%] h-4 bg-zinc-700 rounded-full"></div>

            <div className="animate-pulse w-[30%] h-4 bg-zinc-700 rounded-full"></div>
            <div className="animate-pulse h-9 w-[22%] rounded-md flex bg-zinc-700"></div>

            <div className="flex items-center mt-3 justify-between">
              <div>
                <div className="animate-pulse h-3 w-10 rounded-full flex bg-zinc-700"></div>
                <div className="animate-pulse h-4 w-20 mt-1 rounded-full flex bg-zinc-700"></div>
              </div>
              <div className="animate-pulse h-10 w-32 rounded-md flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          isFirst ? "flex lg:hidden" : "flex"
        }  w-[375px] lg:w-auto lg:h-[180px] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex-col lg:flex-row`}
      >
        <div className="animate-pulse flex-3 h-[150px] lg:h-full lg:flex-none w-full lg:w-[159px] object-cover bg-zinc-700"></div>
        <div className="flex lg:flex-1 flex-1">
          <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase lg:hidden ">
            <div className="animate-pulse h-4 w-8 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-8 w-8 mt-1 rounded-md flex bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-4 flex-1 p-3 text-sm gap-1">
            <div className="animate-pulse w-[90%] h-4 bg-zinc-700 rounded-full"></div>
            <div className="animate-pulse w-[70%] h-4 bg-zinc-700 rounded-full"></div>

            <div className="animate-pulse w-[30%] h-4 bg-zinc-700 rounded-full"></div>
            <div className="animate-pulse h-9 w-[22%] rounded-md flex bg-zinc-700"></div>

            <div className="flex items-center mt-3 justify-between">
              <div>
                <div className="animate-pulse h-3 w-10 rounded-full flex bg-zinc-700"></div>
                <div className="animate-pulse h-4 w-20 mt-1 rounded-full flex bg-zinc-700"></div>
              </div>
              <div className="animate-pulse h-10 w-32 rounded-md flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EventCardVthree = ({ event }) => {
  const navigate = useNavigate()
  const handleNavigation = () => {
    if (event.slug) {
      navigate(`/event/${event.slug}`)
    } else {
      window.location.reload()
    }
  }
  let image = event.event_images.find((image) => image.image_cat === "banner")

  return (
    <div
      onClick={handleNavigation}
      className="min-w-[360px] w-[360px] max-w-[33%] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex flex-col flex-1 "
    >
      <img
        className="max-h-[150px] h-[150px] w-full object-cover"
        src={image?.image ?? event.event_images[0].image}
        alt=""
      />
      <div className="flex flex-1 items-stretch">
        <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase ">
          <p className="text-primary font-normal text-[11px]">
            {format(new Date(event?.start_date), "MMM")}
          </p>
          <p className="font-semibold text-2xl font-montserrat">
            {format(new Date(event?.start_date), "dd")}
          </p>
        </div>
        <div className="flex flex-col flex-1 p-3 text-sm gap-1 overflow-hidden">
          <p className="font-semibold text-base line-clamp-3 flex-1">
            {event.title}
          </p>
          <p className="capitalize line-clamp-2">{`${event.venue_name}, ${event.venue_location}`}</p>
          <p>{`${format(new Date(event?.start_date), "dd MMMM yyyy", {
            locale: idID,
          })}`}</p>
          <div className="text-primary border-primary border w-fit rounded-md p-2">
            Event
          </div>
          <div className="flex items-center justify-between">
            {/* <div>
              Mulai dari{" "}
              <span className="font-semibold">
                <br />
                {formatRupiah(
                  event.ticket_categories.length
                    ? event.ticket_categories.sort(
                        (a, b) => a.price - b.price
                      )[0].price
                    : 999999
                )}
              </span>{" "}
              / Pax
            </div> */}
            <GHButton onClick={handleNavigation}> Beli Tiket</GHButton>
          </div>
        </div>
      </div>
    </div>
  )
}

const EventCardVthreeSkeleton = () => {
  return (
    <>
      <div className="min-w-[360px] w-[360px] max-w-[33%] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex flex-col flex-1 ">
        <div className="animate-pulse h-[150px] w-full bg-zinc-700 object-cover"></div>
        <div className="flex flex-1 items-stretch">
          <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase ">
            <div className="animate-pulse h-4 w-8 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-8 w-8 mt-1 rounded-md flex bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-1 p-3  text-sm gap-1 overflow-hidden">
            <div className="animate-pulse h-4 w-full rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-4 w-[50%] mb-1 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-10 w-[20%] rounded-md mb-1 flex bg-zinc-700"></div>
            <div className="animate-pulse h-4 w-[80%] rounded-full flex bg-zinc-700"></div>
            <div className="flex items-center mt-3 justify-between">
              <div>
                <div className="animate-pulse h-3 w-10 rounded-full flex bg-zinc-700"></div>
                <div className="animate-pulse h-4 w-20 mt-1 rounded-full flex bg-zinc-700"></div>
              </div>
              <div className="animate-pulse h-10 w-32 rounded-md flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="min-w-[360px] w-[360px] max-w-[33%] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex flex-col flex-1 ">
        <div className="animate-pulse h-[150px] w-full bg-zinc-700 object-cover"></div>
        <div className="flex flex-1 items-stretch">
          <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase ">
            <div className="animate-pulse h-4 w-8 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-8 w-8 mt-1 rounded-md flex bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-1 p-3  text-sm gap-1 overflow-hidden">
            <div className="animate-pulse h-4 w-full rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-4 w-[50%] mb-1 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-10 w-[20%] rounded-md mb-1 flex bg-zinc-700"></div>
            <div className="animate-pulse h-4 w-[80%] rounded-full flex bg-zinc-700"></div>
            <div className="flex items-center mt-3 justify-between">
              <div>
                <div className="animate-pulse h-3 w-10 rounded-full flex bg-zinc-700"></div>
                <div className="animate-pulse h-4 w-20 mt-1 rounded-full flex bg-zinc-700"></div>
              </div>
              <div className="animate-pulse h-10 w-32 rounded-md flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="min-w-[360px] w-[360px] max-w-[33%] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex flex-col flex-1 ">
        <div className="animate-pulse h-[150px] w-full bg-zinc-700 object-cover"></div>
        <div className="flex flex-1 items-stretch">
          <div className="flex flex-col align-middle text-center px-[14px] pt-2 uppercase ">
            <div className="animate-pulse h-4 w-8 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-8 w-8 mt-1 rounded-md flex bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-1 p-3  text-sm gap-1 overflow-hidden">
            <div className="animate-pulse h-4 w-full rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-4 w-[50%] mb-1 rounded-full flex bg-zinc-700"></div>
            <div className="animate-pulse h-10 w-[20%] rounded-md mb-1 flex bg-zinc-700"></div>
            <div className="animate-pulse h-4 w-[80%] rounded-full flex bg-zinc-700"></div>
            <div className="flex items-center mt-3 justify-between">
              <div>
                <div className="animate-pulse h-3 w-10 rounded-full flex bg-zinc-700"></div>
                <div className="animate-pulse h-4 w-20 mt-1 rounded-full flex bg-zinc-700"></div>
              </div>
              <div className="animate-pulse h-10 w-32 rounded-md flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LandingPage
