import { useStoreActions, useStoreState } from "easy-peasy";
import { CheckIcon } from "@heroicons/react/24/outline";
import { ArrowDown } from "iconsax-react";
import { Link } from "react-router-dom";

function PhotoGalleryThumbnail({ isLast, photo, count, listPageUrl }) {
  const { url_thumbnail, category } = photo;
  const selectedPhoto = useStoreState((state) => state.selectedPhoto);
  const toggleSelectedPhoto = useStoreActions(
    (action) => action.toggleSelectedPhoto
  );
  const downloadPhoto = useStoreActions((action) => action.downloadPhoto);
  return (
    <Link
      to={`/photos/${photo.id}/details`}
      className="relative overflow-hidden rounded-md"
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className="absolute z-10 h-full w-full">
        <div className="flex flex-col w-full h-full p-2">
          <div
            className="flex space-x-4"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <span className="p-1 bg-black bg-opacity-70 rounded-md min-h-[24px] min-w-[24px] cursor-pointer">
              <span
                title="Select this photo"
                onClick={() => {
                  toggleSelectedPhoto(photo.id);
                }}
              >
                <CheckIcon
                  className={`h-6 w-6 text-primary ${
                    selectedPhoto.indexOf(photo.id) !== -1 ? "" : "opacity-0"
                  }`}
                  title="Remove from Collection"
                />
              </span>
            </span>
            <span
              className="p-1 cursor-pointer  bg-black bg-opacity-70 rounded-md min-h-[24px] min-w-[24px]"
              onClick={async () => {
                await downloadPhoto(photo.id);
              }}
            >
              <span className="text-secondary text-[12px] flex items-center space-x-1 h-6">
                <p>Download</p>
                <ArrowDown
                  className={`h-3 w-3 text-secondary`}
                  title="Download this photo "
                />
              </span>
            </span>
          </div>
        </div>
      </div>
      <img
        src={url_thumbnail}
        alt={`Event ${category}`}
        className="max-h-[246px] min-h-[246px] w-full object-cover object-center"
      />
    </Link>
  );
}

export default PhotoGalleryThumbnail;
