export function DetailPhotoSection3() {
  return (
    <div
      className="mt-[36px] 
                xl:mx-0
                lg:mx-0
                md:mx-0 md:flex-row md:space-y-0 md:space-x-4
                sm:mx-4
                mx-4 space-y-4 flex xl:flex-row  flex-col "
    >
      <div className="flex flex-col gap-0 lg:gap-[4px]">
        <div className="w-14 bg-zinc-700 h-5 animate-pulse rounded-sm" />
        <div className="w-16 bg-zinc-700 h-5 animate-pulse rounded-sm" />
      </div>
      <div className="flex flex-col gap-0 lg:gap-[4px]">
        <div className="w-14 bg-zinc-700 h-5 animate-pulse rounded-sm" />
        <div className="w-16 bg-zinc-700 h-5 animate-pulse rounded-sm" />
      </div>
      <div className="flex flex-col gap-0 lg:gap-[4px]">
        <div className="w-14 bg-zinc-700 h-5 animate-pulse rounded-sm" />
        <div className="w-16 bg-zinc-700 h-5 animate-pulse rounded-sm" />
      </div>
      <div className="flex flex-col gap-0 lg:gap-[4px]">
        <div className="w-14 bg-zinc-700 h-5 animate-pulse rounded-sm" />
        <div className="w-16 bg-zinc-700 h-5 animate-pulse rounded-sm" />
      </div>
      <div className="flex flex-col gap-0 lg:gap-[4px]">
        <div className="w-14 bg-zinc-700 h-5 animate-pulse rounded-sm" />
        <div className="w-16 bg-zinc-700 h-5 animate-pulse rounded-sm" />
      </div>
    </div>
  )
}
