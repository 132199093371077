export function DetailPhotoSection4() {
  return (
    <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4 mx-4 md:mx-0">
      <div className="w-full h-56 bg-zinc-700 rounded-md animate-pulse" />
      <div className="w-full h-56 bg-zinc-700 rounded-md animate-pulse" />
      <div className="w-full h-56 bg-zinc-700 rounded-md animate-pulse" />
      <div className="w-full h-56 bg-zinc-700 rounded-md animate-pulse" />
    </div>
  )
}
