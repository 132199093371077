import { Add } from "iconsax-react"
import { compactNumber, formatRupiah } from "../utils"
import { useStoreActions, useStoreState } from "easy-peasy"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"

function PhotoThumbnail({ isLast, photo, count, listPageUrl }) {
  const { url_thumbnail, category, name, price } = photo
  const photos = useStoreState((state) => state.photoCart)
  const savePhoto = useStoreActions((actions) => actions.savePhoto)
  const navigate = useNavigate()
  return (
    <a
      onClick={(e) => {
        if (!isLast) {
          e.preventDefault()
          navigate(`/photos/${photo.id}/details`)
        } else {
          navigate(listPageUrl)
        }
      }}
      href={!isLast ? `/photos/${photo.id}/details` : listPageUrl}
      className="relative overflow-hidden rounded-md"
      target="_blank"
      rel="noreferrer noopener"
    >
      {!isLast ? (
        <div className="absolute z-10 lg:opacity-0 hover:opacity-100 h-full w-full transition-all flex ">
          <div className="flex flex-col justify-between w-full h-full">
            <div className="flex justify-end p-2 space-x-2">
              {/* <span className="p-2 bg-black rounded-lg">Beli Photo</span>{" "} */}
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  savePhoto({ id: photo.id, photo: photo })
                }}
                className="p-1 bg-black bg-opacity-70 rounded-lg"
              >
                {/* {photos[photo.id] ? (
                  <CheckIcon
                    className="h-[32px] w-auto text-primary"
                    title="Remove from Collection"
                  />
                ) : (
                  <span title="Add to collection">
                    <Add size="32" className="text-secondary" />
                  </span>
                )} */}
                <span title="Add to collection">
                  <Add
                    size="32"
                    className={`${
                      photos[photo.id] ? "text-primary" : "text-secondary"
                    }`}
                  />
                </span>
              </span>
            </div>
            <div className="flex flex-col justify-end min-h-[200px] p-3 space-y-2 shadow-[inset_0px_-70px_40px_-20px_rgba(0,0,0,.8)]">
              <p className="line-clamp-1 text-[12px] text-secondary">{name}</p>
              <p className="line-clamp-1 text-[12px] text-secondary">
                {formatRupiah(price)}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <a
          onClick={(e) => {
            e.preventDefault()
          }}
          href={listPageUrl}
        >
          <div className="absolute z-10 h-full w-full flex justify-center items-center bg-black bg-opacity-80">
            <p className="line-clamp-3  text-secondary text-[18px] font-semibold">{`See all ${compactNumber(
              count
            )} photo`}</p>
          </div>
        </a>
      )}
      <img
        src={url_thumbnail}
        alt={`Event ${category}`}
        className="max-h-[246px] min-h-[246px] w-full object-cover object-center"
      />
    </a>
  )
}

export default PhotoThumbnail
