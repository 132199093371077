import PhotoThumbnail from "./PhotoThumbnail";

function PhotoGrid({ className, photos }) {
  return (
    <div
      className={`grid 
grid-cols-2 lg:grid-cols-4 
gap-4 ${className}`}
    >
      {photos.map((photo, index) => {
        return <PhotoThumbnail key={index} photo={photo} />;
      })}
    </div>
  );
}

export default PhotoGrid;
