export function AllNews() {
  return (
    <div className="grid grid-cols-3 gap-5">
      {/* card1 */}
      <div className="flex w-full flex-col">
        <div className="animate-pulse w-full rounded-tr-md rounded-tl-md h-64 bg-zinc-600" />
        <div className="w-full rounded-br-md rounded-bl-md px-5 h-56 bg-zinc-800 flex flex-col">
          <div className="animate-pulse mt-5 w-full bg-zinc-600 h-6 rounded-md"></div>
          <div className="animate-pulse mt-2 w-48 bg-zinc-600 h-6 rounded-md"></div>
          <div className="animate-pulse mt-2 w-20 bg-zinc-600 h-8 rounded-md"></div>
          <div className="animate-pulse mt-5 w-full bg-zinc-600 h-6 rounded-md"></div>
          <div className="animate-pulse mt-2 w-48 bg-zinc-600 h-6 rounded-md"></div>
        </div>
      </div>
      {/* endcard1 */}
      {/* card1 */}
      <div className="flex flex-col">
        <div className="animate-pulse w-full rounded-tr-md rounded-tl-md h-64 bg-zinc-600" />
        <div className="w-full rounded-br-md rounded-bl-md px-5 h-56 bg-zinc-800 flex flex-col">
          <div className="animate-pulse mt-5 w-full bg-zinc-600 h-6 rounded-md"></div>
          <div className="animate-pulse mt-2 w-48 bg-zinc-600 h-6 rounded-md"></div>
          <div className="animate-pulse mt-2 w-20 bg-zinc-600 h-8 rounded-md"></div>
          <div className="animate-pulse mt-5 w-full bg-zinc-600 h-6 rounded-md"></div>
          <div className="animate-pulse mt-2 w-48 bg-zinc-600 h-6 rounded-md"></div>
        </div>
      </div>
      {/* endcard1 */}
      {/* card1 */}
      <div className="flex flex-col">
        <div className="animate-pulse w-full rounded-tr-md rounded-tl-md h-64 bg-zinc-600" />
        <div className="w-full rounded-br-md rounded-bl-md px-5 h-56 bg-zinc-800 flex flex-col">
          <div className="animate-pulse mt-5 w-full bg-zinc-600 h-6 rounded-md"></div>
          <div className="animate-pulse mt-2 w-48 bg-zinc-600 h-6 rounded-md"></div>
          <div className="animate-pulse mt-2 w-20 bg-zinc-600 h-8 rounded-md"></div>
          <div className="animate-pulse mt-5 w-full bg-zinc-600 h-6 rounded-md"></div>
          <div className="animate-pulse mt-2 w-48 bg-zinc-600 h-6 rounded-md"></div>
        </div>
      </div>
      {/* endcard1 */}
    </div>
  )
}
