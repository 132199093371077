import { Helmet } from "react-helmet";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useState } from "react";
import Layout from "../components/layout";
import { useStoreActions, useStoreState } from "easy-peasy";
import axios from "axios";
import { GHButton } from "../components/GHSystem";
import { CheckIcon } from "@heroicons/react/24/solid";
import PhotoCollectionThumbnail from "../components/PhotoCollectionThumbnail";
import { useEffect } from "react";
import { ArrowDown2, ArrowUp2, Image } from "iconsax-react";
import { formatRupiah } from "../utils";
import getPaymentMethodImage from "../utils/getPaymentMethodImage";
import { toast } from "react-toastify";

export const PhotoCollectionLoader = async ({ request, params }) => {
  try {
    const reqArray = [
      axios.get(`${process.env.REACT_APP_BASE_URL}/apps/payment-methods`),
    ];
    const response = await Promise.all(reqArray);
    return {
      paymentMethods: response[0]?.data?.payment_methods ?? [],
    };
  } catch (error) {
    return {
      paymentMethods: [],
    };
  }
};

export const PhotoCollectionPage = () => {
  const loaderData = useLoaderData();
  const { paymentMethods } = loaderData;
  const photoCart = useStoreState((state) => state.photoCart);

  const checkoutArray = useStoreState((state) => state.checkoutArray);
  const [selectAll, setSelectAll] = useState(false);

  const selectAllCheckout = useStoreActions(
    (actions) => actions.selectAllCheckout
  );
  const resetCheckout = useStoreActions((actions) => actions.resetCheckout);

  useEffect(() => {
    if (checkoutArray.length === Object.keys(photoCart).length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checkoutArray, photoCart]);

  return (
    <Layout showTopButton>
      {/* top level element */}
      <MobileNavigation paymentMethods={paymentMethods} />
      <div className="h-full w-full flex flex-col items-center text-secondary">
        <Helmet>
          <title>Photos | Gue Hadir</title>
          <meta name="description" content={`Gue Hadir Photos`} />
          <meta property="og:title" content="Photos | Gue Hadir" />
          <meta
            property="og:description"
            content="guehadir.id memberikan pengalaman berbeda saat melakukan pembelian tiket. Selalu ada keuntungan di setiap pembeliannya"
          />
          <meta
            property="og:image"
            content="https://backend.guehadir.id/images/banner1.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Gue Hadir" />
          <meta property="og:url" content="https://guehadir.id" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="guehadir.id" />
          <meta property="twitter:url" content="https://guehadir.id" />
          <meta name="twitter:title" content="Photos | Gue Hadir" />
          <meta
            name="twitter:description"
            content="guehadir.id memberikan pengalaman berbeda saat melakukan pembelian tiket. Selalu ada keuntungan di setiap pembeliannya"
          />
          <meta
            name="twitter:image"
            content="https://backend.guehadir.id/images/banner1.png"
          />
        </Helmet>
        <div className="container flex flex-col py-6 space-y-4 px-4 lg:px-0">
          <p className="line-clamp-2 font-montserrat font-semibold text-2xl">
            {`Your Collection`}
          </p>
          <div className="flex space-x-8">
            {/* filter */}
            <div className="flex lg:basis-3/5 xl:basis-4/6 items-center justify-end">
              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  if (!selectAll) {
                    selectAllCheckout();
                  } else {
                    resetCheckout();
                  }
                }}
              >
                <div className="h-5 w-5 bg-black bg-opacity-70 mr-2 rounded-[2px]">
                  {selectAll && (
                    <CheckIcon
                      className="h-full w-auto text-primary"
                      title="Remove from Collection"
                    />
                  )}
                </div>
                <p>Pilih Semua Photo</p>
              </div>
            </div>
            {/* end filter  */}
            {/* hidden element for layouting */}
            <div className="opacity-0 lg:basis-2/5 xl:basis-2/6"></div>
          </div>
          <div className="flex space-x-8">
            {/* photo grid  */}
            <div className="basis-full lg:basis-3/5 xl:basis-4/6">
              {Object.keys(photoCart).length ? (
                <div
                  className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:pt-0 pb-8`}
                >
                  {Object.keys(photoCart).map((photoId, index) => {
                    let photo = photoCart[photoId];
                    return photo ? (
                      <PhotoCollectionThumbnail
                        key={index}
                        photo={photo.photo}
                      />
                    ) : null;
                  })}
                </div>
              ) : (
                <div className="flex justify-center py-4">
                  <p>Tidak ada photo</p>
                </div>
              )}
            </div>
            {/* end photo grid */}
            {/* payment panel  */}
            <div className="hidden lg:block lg:basis-2/5 xl:basis-2/6 text-sm">
              <PaymentPanel paymentMethods={paymentMethods} />
            </div>
            {/* end payment panel  */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

//TODO: no photo gmn?
// TODO: no login gmn?
//TODO: handle gopay
//TODO: handle credit card
//TODO: count bank fee

const PaymentPanel = ({ paymentMethods }) => {
  return (
    <div
      className="py-[15px] px-5 lg:px-[20px]
       bg-gh-black-200 rounded-[10px] space-y-3 
    lg:shadow-event-card overflow-y-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <p className="pb-3 text-base border-primary border-b-[1px] border-dashed">
        Beli Photo
      </p>
      <SectionSeparator>Pilih Metode Pembayaran</SectionSeparator>
      <BankList paymentMethods={paymentMethods} />
      <Divider />
      <SectionSeparator>Order Summary</SectionSeparator>
      <OrderSummaryList />
    </div>
  );
};

const BankList = ({ paymentMethods }) => {
  return (
    <div className="space-y-4">
      <BankAccordion
        paymentMethods={paymentMethods.filter(
          (item) => item.bank_code === "GOPAY"
        )}
        text={"E-Wallet"}
      />
      <BankAccordion
        paymentMethods={paymentMethods.filter(
          (item) => !["GOPAY", "CREDITCARD"].includes(item.bank_code)
        )}
        text={"Virtual Account"}
      />
      <BankAccordion
        paymentMethods={paymentMethods.filter(
          (item) => item.bank_code === "CREDITCARD"
        )}
        text={"Credit Card"}
      />
    </div>
  );
};

const BankAccordion = ({ paymentMethods, text }) => {
  const [isShow, setIsShow] = useState(false);
  const toggleIsShow = () => setIsShow((old) => !old);
  return paymentMethods.length ? (
    <div>
      <span
        className="flex justify-between cursor-pointer"
        onClick={toggleIsShow}
      >
        <p className="font-bold text-sm">{text}</p>
        <ArrowDown2
          className={`h-5 transition-all ${isShow ? "rotate-180" : ""}`}
        />
      </span>
      <div
        className={`transition-all overflow-hidden ${
          isShow ? "max-h-[380px]" : "max-h-[0px]"
        }`}
      >
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 justify-items-center items-center gap-1 py-4">
          {paymentMethods.map((item, index) => (
            <BankImage key={index} bank={item} />
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

const BankImage = ({ bank }) => {
  const checkoutArray = useStoreState((state) => state.checkoutArray);
  const paymentMethod = useStoreState((state) => state.paymentMethod);
  const setPaymentMethod = useStoreActions((action) => action.setPaymentMethod);
  return (
    <img
      className={`h-[3rem] w-[8rem] object-cover cursor-pointer rounded-lg overflow-hidden box-border ${
        paymentMethod.bank_code === bank.bank_code
          ? "border-4 border-primary"
          : ""
      }`}
      id={bank.name}
      src={getPaymentMethodImage(bank.bank_code)}
      alt={bank.name}
      onClick={() => {
        if (checkoutArray.length !== 0) {
          setPaymentMethod(bank);
        } else {
          toast("Pilih photo terlebih dahulu");
        }
      }}
    />
  );
};

const MobileNavigation = ({ paymentMethods }) => {
  const navigate = useNavigate();
  const checkoutArray = useStoreState((state) => state.checkoutArray);
  const photoCart = useStoreState((state) => state.photoCart);
  const [expand, setExpand] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const checkoutPhoto = useStoreActions((state) => state.checkoutPhoto);
  const paymentMethod = useStoreState((state) => state.paymentMethod);
  const [subtotal, setSubtotal] = useState(0);
  const [adminFee, setAdminFee] = useState(0);
  const [bankFee, setBankFee] = useState(0);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    setSubtotal(
      checkoutArray.reduce((prev, curr) => {
        return prev + photoCart[curr].photo.price;
      }, 0)
    );
    setBankFee(() => {
      if (paymentMethod.bank_code === "GOPAY") {
        let feeBank =
          Math.round(paymentMethod.admin_fee + subtotal * 0.02) || 0;

        return feeBank + feeBank * 0.11 || 0;
      } else {
        return paymentMethod.admin_fee || 0;
      }
    });
    // TODO: calculate admin fee
    // TODO: calculate discount
  }, [checkoutArray, photoCart, paymentMethod]);

  const checkout = async () => {
    setIsSubmitting(true);
    const res = await checkoutPhoto()
      .catch((err) => err)
      .finally(() => {
        setIsSubmitting(false);
      });
    if (res instanceof Error) {
      return toast(res.message);
    }
    navigate("/payment/" + res.data.payment_id);
    toast("Checkout berhasil");
  };
  return (
    <div
      onClick={() => setExpand((old) => !old)}
      className={`text-secondary
       fixed z-[1010] bottom-0 left-0 right-0  w-full
       lg:hidden 
       flex flex-col justify-end
       ${expand ? "min-h-[100vh]" : "min-h-[72px]"} 
       `}
    >
      <div
        className={`absolute z-[1010] inset-0 w-full h-full bg-black transition-all duration-500
      ${expand ? "bg-opacity-70" : "bg-opacity-0"}`}
      ></div>
      {/* sheet */}
      <div
        className={`absolute w-full z-[1020] bottom-0 flex flex-col items-stretch justify-end rounded-b-none 
        bg-gh-black-100 lg:py-4 transition-all overflow-hidden
         ${expand ? "max-h-[80vh] rounded-[10px]" : "max-h-[72px] "}`}
      >
        {/* open */}
        <PaymentPanel paymentMethods={paymentMethods} />

        {/* closed  */}
        <div className="flex px-5 items-center justify-between min-h-[72px]">
          <div className="flex flex-col">
            <p className="font-semibold text-base">Total</p>
            <p className="text-primary font-normal">
              {checkoutArray.length} Photo
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <span className="flex items-center space-x-2">
              <p className="font-semibold text-base">
                {formatRupiah(Number(subtotal + bankFee) || 0)}
              </p>
              {checkoutArray.length > 0 && (
                <>
                  <ArrowUp2
                    className={`h-4 transition-all ${
                      expand ? "rotate-180" : ""
                    }`}
                  />
                </>
              )}
            </span>
            <GHButton
              disabled={isSubmitting}
              onClick={(e) => {
                e.stopPropagation();
                checkout();
              }}
            >
              Beli Photo
            </GHButton>
          </div>
        </div>
        {/* end closed */}
      </div>
      {/* end sheet */}
    </div>
  );
};

const OrderSummaryList = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const checkoutPhoto = useStoreActions((state) => state.checkoutPhoto);
  const navigate = useNavigate();

  const checkoutArray = useStoreState((state) => state.checkoutArray);
  const photoCart = useStoreState((state) => state.photoCart);
  const paymentMethod = useStoreState((state) => state.paymentMethod);
  const [subtotal, setSubtotal] = useState(0);
  const [adminFee, setAdminFee] = useState(0);
  const [bankFee, setBankFee] = useState(0);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    setSubtotal(
      checkoutArray.reduce((prev, curr) => {
        return prev + photoCart[curr].photo.price;
      }, 0)
    );
    setBankFee(() => {
      if (paymentMethod.bank_code === "GOPAY") {
        let feeBank =
          Math.round(paymentMethod.admin_fee + subtotal * 0.02) || 0;
        return feeBank + feeBank * 0.11 || 0;
      } else {
        return paymentMethod.admin_fee || 0;
      }
    });
    // TODO: calculate admin fee
    // TODO: calculate discount
  }, [checkoutArray, photoCart, paymentMethod]);

  const checkout = async () => {
    setIsSubmitting(true);
    const res = await checkoutPhoto()
      .catch((err) => err)
      .finally(() => {
        setIsSubmitting(false);
      });
    if (res instanceof Error) {
      return toast(res.message);
    }
    navigate("/payment/" + res.data.payment_id);
    toast("Checkout berhasil");
  };
  return (
    <>
      {checkoutArray.map((id, index) => {
        let curr = photoCart[id];
        return curr ? (
          <span key={index} className="flex items-center space-x-3">
            <Image className="text-primary h-5 w-5 border-[1px] border-primary rounded-[2px]" />
            <p className="flex-1 line-clamp-3">{curr.photo.name}</p>
            <p className="font-semibold">
              {formatRupiah(curr.photo.price ?? 99999)}
            </p>
          </span>
        ) : null;
      })}
      <Divider />
      <div className="space-y-4">
        <div className="flex justify-between">
          <p>
            Subtotal{" "}
            <span className="text-primary">{checkoutArray.length} Tiket</span>
          </p>
          <p className="font-semibold">{formatRupiah(Number(subtotal) ?? 0)}</p>
        </div>
        <div className="flex justify-between">
          <p>Biaya Lainnya</p>
          <p className="font-semibold">{formatRupiah(Number(bankFee) ?? 0)}</p>
        </div>
      </div>
      <Divider />
      <div className="text-base font-semibold justify-between hidden lg:flex">
        <p>Total Diskon</p>
        <p>
          {formatRupiah(Number(subtotal + bankFee + adminFee + discount) ?? 0)}
        </p>
      </div>
      <GHButton
        disabled={isSubmitting}
        onClick={checkout}
        className="w-full hidden lg:block"
      >
        Beli Photo
      </GHButton>
    </>
  );
};

const SectionSeparator = ({ children }) => {
  return (
    <span className="flex space-x-4">
      <span className="w-1 h-full rounded-lg bg-primary">
        <p className="opacity-0">.</p>
      </span>
      <p className="font-semibold text-base"> {children}</p>
    </span>
  );
};

const Divider = () => {
  return (
    <div className="h-[1px] w-full bg-lighter-black my-4">
      <span className="opacity-0">.</span>
    </div>
  );
};
