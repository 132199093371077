import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { formatRupiah } from "../utils"
import { GHButton } from "../components/GHSystem"
import { format } from "date-fns"
import { Base64 } from "js-base64"

function EventCard({
  image,
  title,
  city,
  province,
  price,
  date,
  description,
  id,
  slug,
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const slicedDate = date.slice(5, 7)

  const handleNavigation = () => {
    if (slug) {
      navigate(`/event/${slug}`)
    } else {
      window.location.reload()
    }
  }

  return (
    <div
      key={id}
      onClick={handleNavigation}
      className={`bg-[#191919] rounded-md flex flex-col overflow-hidden cursor-pointer ${
        location.pathname === "/" ? "min-w-[350px]" : "min-w-[250px]"
      }`}
      style={{ boxShadow: "0 0 10px black" }}
    >
      <img className="w-full h-[150px] object-cover" src={image} alt="" />
      <div className="flex flex-1 space-x-3 px-4 py-3">
        <div className="flex flex-col items-center uppercase px-[14px] ">
          <div className="text-primary font-normal text-[11px]">
            {format(new Date(date), "MMM")}
          </div>
          <div className="font-semibold text-2xl font-montserrat">
            {format(new Date(date), "dd")}
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-2">
          <div className="font-semibold text-base line-clamp-3 flex-1">
            {title}
          </div>
          <div className="capitalize line-clamp-2">
            {city}, {province}
          </div>
          <div className="text-primary border-primary border w-fit rounded-md p-2">
            Event
          </div>
          <div className="line-clamp-1">{description}</div>
          <div className="flex items-center justify-between">
            {/* <div>
              Mulai dari{" "}
              <span className="font-semibold">
                <br />
                {formatRupiah(price)}
              </span>{" "}
              / Pax
            </div> */}
            <GHButton onClick={handleNavigation}> Beli Tiket</GHButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventCard
