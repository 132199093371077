import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

function TentangKami() {
  return (
    <Layout>
      <div className="min-h-full h-full min-w-full bg-semiblack py-5">
        <Helmet>
          <title>{`Tentang Kami | Gue Hadir`}</title>
          <meta
            name="description"
            content={`Gue Hadir Ticket Experience System`}
          />
        </Helmet>
        <div className="container mx-auto bg-semiblack">
          <div className="p-5 lg:p-12 bg-semiblack text-white">
            <div className="text-[1.5rem] font-semibold">Tentang Kami</div>
            <div className="mt-2">
              GUEHADIR adalah platform Ticketing Management Service (TMS) yang
              mendukung seluruh penyelenggara event mulai dari distribusi &
              manajemen tiket, hingga penyediaan laporan analisa event dan
              audiens di akhir acara.
            </div>
            <div className="mt-2">
              Beberapa servis yang kami sediakan siap untuk memfasilitasi
              penyelenggara event dalam setiap tahap persiapan yang meliputi:
            </div>
            <div className="flex flex-col space-y-1 mt-2">
              <li>Penjualan dan distribusi tiket terlengkap</li>
              <li>
                Sistem pembayaran yang beragam dan aman untuk memberikan
                kemudahan kepada calon pembeli
              </li>
              <li>
                Gate management yang paling aman, nyaman dengan teknologi
                terbaru untuk akses saat event berlangsung.
              </li>
              <li>
                Sistem analisis data yang lengkap dan komprehensif setelah acara
                berlangsung untuk memudahkan penyelenggara event dalam
                menentukan strategi event selanjutnya.
              </li>
              <li>
                Algoritma data penyelenggara & pembeli yang akurat, sehingga
                dapat mempertemukan antara penyelenggara dengan pembeli dengan
                lebih terukur
              </li>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TentangKami;
