export const StatusPembelian = {
    all: 99,
    unpaid: 0,
    paid: 1,
    expired: 2,
    cancelled: 3,
}

export const TipePembelian = {
    ticket: 0,
    venue: 1,
    booth: 2,
    photo: 3,
}

export const VoucherItemType = {
    ticket: 0,
    photo: 1,
}

export const ReverseTipePembelian = {
    '0': 'Ticket',
    '1': 'Venue',
    '2': 'Booth',
    '3': 'Photo'
}

export const ReverseStatusPembelian = {
    '0': 'Belum Bayar',
    '1': 'Selesai',
    '2': 'Expired',
    '3': 'Dibatalkan'
}