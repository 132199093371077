import { createContext, useState } from "react";

export const GeneralContext = createContext({
  language: "id",
  setLanguage: () => {},
  paymentId: "",
  setPaymentId: () => {},
  paymentMethod: "undefined",
  setPaymentMethod: () => {},
  user: false,
  setUser: () => {},
});

export function ContextHandler({ children }) {
  const [language, setLanguage] = useState("id");
  const [paymentId, setPaymentId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("undefined");
  const [user, setUser] = useState(false);
  const value = {
    language,
    setLanguage,
    paymentId,
    setPaymentId,
    paymentMethod,
    setPaymentMethod,
    user,
    setUser,
  };
  return (
    <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>
  );
}
