import { format } from "date-fns/esm";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { id as idID } from "date-fns/locale";

function NewsCard({ image, title, date, category, description, id }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div
      key={id}
      onClick={() => window.location.replace(`/news/${id}`)}
      className={`overflow-hidden rounded-md cursor-pointer bg-gh-black-200 shadow-event-card 
      flex flex-col min-w-[280px] w-full
      md:max-w-[330px] 
      lg:max-w-[330px] 
      xl:max-w-[330px]
      2xl:max-w-none `}
    >
      <img className="" src={image} alt="" />
      <div className="p-4 flex flex-col space-y-3 justify-end flex-1">
        <div className="text-[1.25rem] font-semibold flex-1 line-clamp-3">
          {title}
        </div>
        <div>
          {format(new Date(date), "dd MMM yyyy", {
            locale: idID,
          })}
        </div>
        <div className="border border-primary p-2 text-primary w-fit rounded-md capitalize ">
          {category}
        </div>
        <div className="line-clamp-3">{description}</div>
      </div>
    </div>
  );
}

export default NewsCard;
