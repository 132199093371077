export function ShowSkeletonGHNews() {
  return (
    <div id="terbaru" className=" flex flex-col">
      <div className="flex items-center justify-between">
        <div className="animate-pulse w-[10%] h-6 bg-zinc-700 rounded-full"></div>
        <div className="animate-pulse w-[12%] h-4 bg-zinc-700 rounded-full"></div>
      </div>
      {/* Content  */}
      <div className="flex overflow-x-scroll py-5 gap-3 scrollbar-none">
        {/* card gh news */}
        <div className="min-w-[360px] w-[360px] max-w-[33%] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex flex-col flex-1 ">
          <div className="animate-pulse h-[230px] w-full bg-zinc-700 object-cover"></div>
          <div className="flex flex-1 items-stretch">
            <div className="flex flex-col flex-1 p-3  text-sm gap-1 overflow-hidden">
              <div className="animate-pulse h-4 w-full rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[50%] mb-1 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-10 w-[20%] rounded-md mb-1 flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[100%] mt-1 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[80%] mt-1 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[60%] mt-1 rounded-full flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
        <div className="min-w-[360px] w-[360px] max-w-[33%] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex flex-col flex-1 ">
          <div className="animate-pulse h-[230px] w-full bg-zinc-700 object-cover"></div>
          <div className="flex flex-1 items-stretch">
            <div className="flex flex-col flex-1 p-3  text-sm gap-1 overflow-hidden">
              <div className="animate-pulse h-4 w-full rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[50%] mb-1 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-10 w-[20%] rounded-md mb-1 flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[100%] mt-1 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[80%] mt-1 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[60%] mt-1 rounded-full flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
        <div className="min-w-[360px] w-[360px] max-w-[33%] cursor-pointer rounded-md overflow-hidden bg-gh-black-200 shadow-event-card flex flex-col flex-1 ">
          <div className="animate-pulse h-[230px] w-full bg-zinc-700 object-cover"></div>
          <div className="flex flex-1 items-stretch">
            <div className="flex flex-col flex-1 p-3  text-sm gap-1 overflow-hidden">
              <div className="animate-pulse h-4 w-full rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[50%] mb-1 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-10 w-[20%] rounded-md mb-1 flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[100%] mt-1 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[80%] mt-1 rounded-full flex bg-zinc-700"></div>
              <div className="animate-pulse h-4 w-[60%] mt-1 rounded-full flex bg-zinc-700"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
