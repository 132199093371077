import { Add } from "iconsax-react";
import { compactNumber, formatRupiah } from "../utils";
import { useStoreActions, useStoreState } from "easy-peasy";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router";

function PhotoThumbnail({ photo }) {
  const { url_thumbnail, category, name, price } = photo;
  const photos = useStoreState((state) => state.photoCart);
  const checkoutArray = useStoreState((state) => state.checkoutArray);
  const savePhoto = useStoreActions((actions) => actions.savePhoto);
  const addCheckoutArray = useStoreActions((actions) => actions.toggleCheckout);
  return (
    <a
      href={`/photos/${photo.id}/details`}
      className="relative overflow-hidden rounded-md"
      target="_blank"
      rel="noreferrer noopener"
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          addCheckoutArray(photo.id);
        }}
        className={`absolute z-20 top-0 left-0 flex p-2 `}
      >
        <span className="p-1 bg-black bg-opacity-70 rounded-lg min-h-[40px] min-w-[40px] ">
          <span title="Add to collection">
            <CheckIcon
              className={`h-[32px] w-auto text-primary ${
                checkoutArray.indexOf(photo.id) !== -1 ? "" : "hidden"
              }`}
              title="Remove from Collection"
            />
          </span>
        </span>
      </div>
      <div className="absolute z-10 lg:opacity-0 hover:opacity-100 h-full w-full transition-all flex ">
        <div className="flex flex-col justify-between w-full h-full">
          <div className="flex justify-end p-2 space-x-2">
            {/* <span className="p-2 bg-black rounded-lg">Beli Photo</span>{" "} */}
            <span
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                savePhoto({ id: photo.id, photo: photo });
              }}
              className="p-1 bg-black bg-opacity-70 rounded-lg"
            >
              <span title="Add to collection">
                <Add
                  size="32"
                  className={`${
                    photos[photo.id] ? "text-primary" : "text-secondary"
                  }`}
                />
              </span>
            </span>
          </div>
          <div className="flex flex-col justify-end min-h-[200px] p-3 space-y-2 shadow-[inset_0px_-70px_40px_-20px_rgba(0,0,0,.8)]">
            <p className="line-clamp-1 text-[12px] text-secondary">{name}</p>
            <p className="line-clamp-1 text-[12px] text-secondary">
              {formatRupiah(price)}
            </p>
          </div>
        </div>
      </div>
      <img
        src={url_thumbnail}
        alt={`Event ${category}`}
        className="max-h-[246px] min-h-[246px] w-full object-cover object-center"
      />
    </a>
  );
}

export default PhotoThumbnail;
