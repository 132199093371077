import axios from "axios"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import EventCard from "../components/eventCard"
import Layout from "../components/layout"
import {
  useLoaderData,
  useRevalidator,
  useSearchParams,
} from "react-router-dom"
import {
  CalendarIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid"
import { GHButton } from "../components/GHSystem"
import Popover from "../components/Popover"
import { DateRangePicker } from "react-date-range"
import { addDays, format, subDays } from "date-fns"
import { useStoreActions } from "easy-peasy"
import { CloseCircle } from "iconsax-react"
import { SkeletonAllEvent } from "../components/partials"

export const EventListLoader = async ({ request }) => {
  const reqUrl = new URL(request.url)
  // const page = reqUrl.searchParams.get('page')
  // const pageSize = reqUrl.searchParams.get('pageSize')
  try {
    const event = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }/apps/events?${reqUrl.searchParams.toString()}`,
      {
        timeout: 2000,
      }
    )
    return { eventData: event.data.data }
  } catch (error) {
    return { eventData: [] }
  }
  // if (event.data.success) {
  //   return event.data.data as IPagination<IEvent>
  // }
  // throw json({}, { status: event.status })
}

function EventList() {
  const loaderData = useLoaderData()
  const { eventData } = loaderData
  const revalidate = useRevalidator().revalidate
  const togglePopover = useStoreActions((action) => action.togglePopover)
  let [searchParams, setSearchParams] = useSearchParams()
  const [currentFilter, setCurrentFilter] = useState("")
  const [searchString, setSearchString] = useState(null)
  const [currentSearch, setCurrentSearch] = useState(null)
  const [isSkeleton, setIsSkeleton] = useState(true)
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: subDays(new Date(), 15),
      endDate: addDays(new Date(), 15),
      key: "selection",
    },
  ])

  useEffect(() => {
    if (eventData.length) {
      setIsSkeleton(false)
    }
  }, [eventData])

  useEffect(() => {
    setCurrentSearch(searchParams.get("search"))
    setSearchString(searchParams.get("search"))
  }, [searchParams])

  const onSearch = () => {
    let params = new URLSearchParams({
      search: searchString.trim().toLowerCase(),
      start_date: format(new Date(selectedDate[0].startDate), "yyyy-MM-dd"),
      end_date: format(new Date(selectedDate[0].endDate), "yyyy-MM-dd"),
    })
    setSearchParams(params)
    revalidate()
  }
  const clearSearch = () => {
    let params = new URLSearchParams({})
    setSearchParams(params)
    revalidate()
  }

  return (
    <Layout>
      <div className="bg-semiblack flex justify-center w-full h-full">
        <div className="p-5 md:p-12 container text-white ">
          <div
            style={{ boxShadow: "0 0 10px black" }}
            className="hidden p-2 rounded-md bg-[#191919] relative md:flex items-center space-x-3 w-full mb-8"
          >
            <div className="flex-1 flex items-center space-x-2">
              <MagnifyingGlassIcon className="h-[1.5rem] w-auto text-white" />
              <input
                onChange={(e) => setSearchString(e.target.value)}
                onKeyUp={(e) => {
                  if (e.code === "Enter") {
                    onSearch()
                  }
                }}
                value={searchString}
                className="flex-1 p-1 bg-transparent outline-none border-none text-white"
                placeholder="Search by event,name,location and more"
              />
              {searchString && (
                <div onClick={clearSearch}>
                  <XMarkIcon className="text-gray-100 h-4" />
                </div>
              )}
            </div>
            <div className="h-[20px] w-[1px] bg-white"></div>
            <div className="flex flex-1 items-center space-x-2">
              <CalendarIcon
                data-toggle-popover="search-calendar"
                onClick={(e) => togglePopover(e)}
                className="h-[1.5rem] w-auto text-white cursor-pointer"
              />
              <input
                data-toggle-popover="search-calendar"
                onClick={(e) => togglePopover(e)}
                value={`${selectedDate[0].startDate.toDateString()} - ${selectedDate[0].endDate.toDateString()}`}
                className="flex-1 p-1 bg-transparent outline-none border-none text-white"
                placeholder="Search Date"
                readOnly
              />
            </div>
            <GHButton onClick={onSearch}> Search</GHButton>
            <Popover
              tag={"search-calendar"}
              className={"absolute top-[100%] left-[50%] translate-x-[-50%]"}
            >
              <DateRangePicker
                onChange={(item) => {
                  setSelectedDate([item.selection])
                }}
                className={`custom-date`}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={selectedDate}
                direction="horizontal"
                color="#FC8300"
                rangeColors={["#FC8300"]}
              />
            </Popover>
          </div>
          <div className="flex md:hidden">
            <div className=" bg-background rounded-md w-full flex items-center p-3 mb-3 space-x-2">
              <MagnifyingGlassIcon className="text-gray-100 h-[1rem] min-w-[1rem] w-auto" />
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  onSearch()
                }}
                className="flex-1 flex"
              >
                <input
                  type="search"
                  onChange={(e) => setSearchString(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.code === "Enter") {
                      onSearch()
                    }
                  }}
                  value={searchString}
                  className="flex-1 bg-transparent outline-none text-white"
                  placeholder="Search by event, name, location and more !"
                />
              </form>
            </div>
            {/* TODO: filter */}
          </div>

          {currentSearch && (
            <div className="flex flex-col items-start md:flex-row md:items-center md:space-x-4">
              <div className="line-clamp-1">
                Event dengan kata{" "}
                <span className="text-primary font-semibold">
                  "{currentSearch}"
                </span>
              </div>
              <span
                onClick={clearSearch}
                className="flex border-[1px] border-primary rounded-full cursor-pointer items-center"
              >
                <CloseCircle size="32" className="text-primary" />
                <p className="text-secondary pr-2">Clear search</p>
              </span>
            </div>
          )}
          {isSkeleton ? (
            <SkeletonAllEvent />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
              {/* TODO filter event  */}
              {eventData.length ? (
                eventData.map((x) => {
                  let image = x.event_images.find(
                    (image) => image.image_cat === "banner"
                  )
                  return (
                    //TODO Ganti Card
                    <EventCard
                      image={image?.image ?? x.event_images[0].image}
                      title={x.title}
                      city={x.venue_name}
                      province={x.venue_location}
                      date={x.start_date}
                      id={x.id}
                      description={x.label_description}
                      price={
                        x.ticket_categories.length
                          ? x.ticket_categories.sort(
                              (a, b) => a.price - b.price
                            )[0].price
                          : "99999"
                      }
                      slug={x.slug}
                    />
                  )
                })
              ) : (
                <div>Tidak ada event</div>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default EventList
