// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import ListImages from "../assets"
import { useEffect } from "react"
import { useState } from "react"
import axios from "axios"

export default function Slider() {
  const [banners, setBanners] = useState([])
  const [showSkeleton, setShowSkeleton] = useState(true)
  useEffect(() => {
    const getBanner = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/apps/banner`
      )
      setShowSkeleton(false)
      setBanners(response.data.data)
    }

    getBanner()
  }, [])

  return (
    <>
      {showSkeleton ? (
        <div class="max-h-[140px] overflow-hidden md:max-h-fit shadow rounded-md p-4 w-full pb-12 md:pb-0 px-6 lg:px-20">
          <div class="animate-pulse h-96 flex space-x-4 bg-zinc-700 rounded-md"></div>
        </div>
      ) : (
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={80}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
          }}
          className="pb-12 md:pb-0 px-6 lg:px-20 "
        >
          {/* TODO: swiper navigation  */}
          {banners?.map((banner) => {
            return (
              <SwiperSlide>
                <img
                  onClick={() => window.open(banner.redirect_url, "_blank")}
                  className="w-full max-h-[11rem] lg:h-auto lg:max-h-full rounded-md cursor-pointer"
                  src={banner.image}
                  alt=""
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </>
  )
}
