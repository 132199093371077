import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

function KebijakanPrivasi() {
  const [dropdownList, setDropdownList] = useState({});

  const dropdownHandler = (e) => {
    setDropdownList((prevState) => {
      return {
        ...prevState,
        [e.target.id]: !prevState[e.target.id],
      };
    });
  };
  return (
    <Layout>
      <div className="min-h-full min-w-full bg-semiblack">
        <Helmet>
          <title>{`Kebijakan dan Privasi | Gue Hadir`}</title>
          <meta
            name="description"
            content={`Gue Hadir Ticket Experience System`}
          />
        </Helmet>
        <div className="container mx-auto bg-semiblack">
          <div className="p-5 lg:p-12 bg-semiblack text-white">
            <div className="text-[1.5rem] font-semibold">Kebijakan Privasi</div>
            {/* Pendahuluan  */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">Pendahuluan</div>
                </div>
                <ChevronDownIcon
                  id="pendahuluan"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.pendahuluan ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.pendahuluan
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2">
                  Kebijakan Privasi berikut ini menjelaskan bagaimana Kami, (PT
                  xxx untuk selanjutnya disebut "Kami") mengumpulkan, menyimpan,
                  menggunakan, mengolah, menguasai, mentransfer, mengungkapkan
                  dan melindungi Informasi Pribadi Anda. Kebijakan Privasi ini
                  berlaku bagi setiap pelanggan dan/atau pengguna (“Pelanggan”)
                  dan penyedia tiket sebagai pemilik tiket dan/atau yang
                  menyediakan tiket (“Penyedia Tiket”) pada situs Guehadir
                  (https://www.guehadir.id) ("Platform"), kecuali diatur pada
                  kebijakan privasi yang terpisah. Mohon baca Kebijakan Privasi
                  ini dengan seksama untuk memastikan bahwa Anda memahami
                  bagaimana proses pengumpulan, penggunaan dan pengolahan data
                  Kami. Kecuali didefinisikan lain, semua istilah dengan huruf
                  kapital yang digunakan dalam Kebijakan Privasi ini memiliki
                  arti yang sama dengan yang tercantum dalam Ketentuan
                  Penggunaan Platform.
                </div>
                <div className="mt-2">
                  Kebijakan Privasi ini mencakup beberapa hal, sebagai berikut:
                </div>
                <div className="mt-2 flex flex-col space-y-1">
                  <div>1. Pengumpulan Informasi Pribadi</div>
                  <div>2. Penggunaan Informasi Pribadi</div>
                  <div>3. Pengungkapan Informasi Pribadi</div>
                  <div>4. Penyimpanan Informasi Pribadi</div>
                  <div>5. Akses dan koreksi Informasi Pribadi</div>
                  <div>6. Keamanan Informasi Pribadi</div>
                  <div>7. Perubahan atas Kebijakan Privasi</div>
                  <div>8. Pengakuan dan Persetujuan</div>
                  <div>9. Materi Pemasaran dan Promosi</div>
                  <div>10. Platform Pihak Ketiga</div>
                </div>
              </div>
            </div>
            {/* Pendahuluan End  */}

            {/* Pengumpulan Informasi Pribadi  */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Pengumpulan Informasi Pribadi
                  </div>
                </div>
                <ChevronDownIcon
                  id="pengumpulan"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.pengumpulan ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.pengumpulan
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2">
                  Dalam Kebijakan Privasi ini Kami sertakan penjelasan mengenai
                  tata cara Kami mengumpulkan, menggunakan, mengungkapkan,
                  memproses, menganalisa, merubah, menghapus, menyimpan dan
                  melindungi informasi dan data pribadi Anda yang
                  mengidentifikasikan atau dapat digunakan untuk
                  mengidentifikasi Anda baik yang diberikan oleh Anda dan/atau
                  yang Kami kumpulkan dari Anda maupun pihak ketiga (“Data
                  Pribadi”).
                </div>
                <div className="mt-2">
                  Pada saat Anda mengakses Layanan Online, membuat akun pribadi
                  dan/atau melakukan pemesanan, Anda harus memberikan kepada
                  Kami Data Pribadi untuk Kami catat dan simpan sebagai berikut:
                </div>
                <div className="mt-2 flex flex-col space-y-1">
                  <div>
                    a. Kami akan meminta Anda untuk mengisi data-data pribadi
                    Anda secara benar, jelas, lengkap, akurat dan tidak
                    menyesatkan, seperti nama, alamat email, nomor telepon,
                    alamat lengkap, Kartu Tanda Pengenal (KTP), Paspor, rincian
                    pemesanan Anda (seperti nama hotel, lokasi hotel dan lama
                    menginap, nama maskapai, tanggal keberangkatan dan
                    kedatangan), dan informasi lainnya yang dapat
                    mengidentifikasi Pengguna;
                  </div>
                  <div>
                    b.Kami akan meminta Anda untuk mengisi data-data pembayaran
                    pada saat Anda melakukan aktivitas transaksi pembayaran
                    melalui Layanan Online, termasuk namun tidak terbatas pada
                    data rekening bank, kartu kredit, virtual account, instant
                    payment, internet banking, gerai ritel, paylater;
                  </div>
                  <div>
                    c.Pada saat Anda melakukan pembayaran dengan Kartu Kredit,
                    Anda akan diminta untuk mengisi data-data Kartu Kredit Anda
                    secara benar, jelas, lengkap, akurat dan tidak menyesatkan,
                    seperti data Tipe Kartu (Visa/MasterCard), Nama Pemegang
                    Kartu, Nama Bank Penerbit Kartu Kredit, Nomor Kartu (Hanya 4
                    digit terakhir yang tampil), dan Tanggal Kartu Expired.
                    Setiap data yang Anda berikan akan tersimpan secara otomatis
                    ke sistem database payment gateway yang Kami gunakan
                    (guehadir.id tidak secara langsung menyimpan database Kartu
                    Kredit Anda, namun tersimpan ke sistem database payment
                    gateway yang Kami gunakan) agar untuk proses pembayaran
                    transaksi selanjutnya dapat lebih mudah dan cepat, karena
                    seluruh database Anda telah tersimpan dengan lengkap, aman
                    dan tidak dibutuhkan lagi mengulang proses penyimpanan untuk
                    data Kartu Kredit yang sama.
                  </div>
                  <div>
                    d. Apabila dibutuhkan, Kami akan meminta akses terhadap
                    kamera, kontak pengguna yang terdapat di dalam telepon
                    genggam milik Anda, lokasi, gambar, arsip dan dokumen.
                  </div>
                  <div>
                    e. Apabila dibutuhkan, Kami akan meminta ulasan/ penilaian
                    sehubungan dengan Layanan Online dan/atau produk Kami
                  </div>
                  <div>
                    f. Ketika Anda menghubungi guehadir.id melalui seluruh
                    layanan pelanggan Kami, Kami akan mengumpulkan dan menyimpan
                    informasi terkait dengan interaksi Anda dengan Customer
                    Service Kami.
                  </div>
                  <div>
                    g. Anda dapat memberikan kepada kami Data Pribadi yang
                    berkaitan dengan individu pihak ketiga lainnya (termasuk
                    saat Anda menambahkan data individu pihak ketiga sebagai
                    penumpang penerbangan atau pengunjung hotel). Dalam hal ini
                    Anda berjanji dan menjamin kami bahwa Anda telah memperoleh
                    persetujuan yang didokumentasikan dengan benar dari individu
                    pihak ketiga yang relevan dan dengan ini menyetujui atas
                    nama individu tersebut untuk pengumpulan, penggunaan,
                    pengungkapan, dan pemrosesan Data Pribadinya oleh kami
                  </div>
                </div>
                <div className="mt-2">
                  <div>
                    Informasi yang dikumpulkan setiap kali Anda menggunakan
                    Layanan Online atau mengunjungi situs web kami :
                  </div>
                  <div className="mt-2">
                    a. Kami akan mengumpulkan informasi mengenai komputer
                    ataupun media apapun yang Anda gunakan, termasuk IP address,
                    sistem operasi, browser yang digunakan, URL, halaman, lokasi
                    geografis dan waktu akses serta data lainnya terkait dengan
                    penggunaan komputer Anda dan yang terekam pada saat Anda
                    mempergunakan Layanan Online Kami (“Detail IP”).
                  </div>
                  <div className="mt-1">
                    b. Cookie adalah file teks yang mengandung sejumlah kecil
                    informasi yang diunduh ke perangkat Anda saat Anda
                    mengunjungi sebuah website. Kami menggunakan cookie untuk
                    berbagai alasan, seperti untuk melacak data Anda jika
                    dibutuhkan untuk digunakan, pelacakan transaksi Anda dengan
                    mitra atau rekanan Kami, mengingat preferensi Anda dan pada
                    umumnya meningkatkan pengalaman Anda saat menggunakan sebuah
                    website. Anda dapat mengatur browser Anda untuk menerima
                    atau tidak menerima suatu cookie
                  </div>
                </div>
              </div>
            </div>
            {/* Pengumpulan Informasi Pribadi End  */}

            {/* Penggunaan Informasi Pribadi Anda  */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Penggunaan Informasi Pribadi Anda
                  </div>
                </div>
                <ChevronDownIcon
                  id="penggunaan"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.penggunaan ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.penggunaan
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2">
                  Pada prinsipnya, setiap data yang Anda berikan dari waktu ke
                  waktu akan Kami simpan dan hanya Kami gunakan untuk
                  kepentingan penyediaan produk dan layanan Kami kepada Anda,
                  yaitu antara lain untuk keperluan tokenisasi, akuntansi,
                  tagihan, audit, verifikasi kredit atau pembayaran, newsletter,
                  serta keperluan keamanan, administrasi dan hukum, bonus poin
                  atau bentuk sejenisnya, pengujian, pemeliharaan dan
                  pengembangan sistem, hubungan pelanggan, promosi dan tindakan
                  lain yang Kami anggap perlu untuk membantu Kami di kemudian
                  hari dalam memberikan pelayanan kepada Anda, antara lain:
                </div>

                <div className="mt-2 flex flex-col space-y-1">
                  <div>
                    a. Kami menggunakan informasi tersebut untuk keperluan
                    verifikasi data, membuka akses untuk Anda dapat mengunggah
                    atau membagikan informasi yang diperlukan melalui telepon
                    genggam Anda serta untuk meningkatkan kelancaran Anda dalam
                    menggunakan Layanan Online yang meliputi namun tidak
                    terbatas pada proses pengembalian dana, klaim asuransi,
                    proses pemesanan dan pembatalan.
                  </div>
                  <div>
                    b. Kami menggunakan Data Pribadi Anda dengan tujuan untuk
                    memproses transaksi Anda, mengelola dan memperlancar proses
                    penggunaan Layanan Online, serta tujuan-tujuan lainnya
                    selama diizinkan oleh peraturan perundang-undangan yang
                    berlaku.
                  </div>
                  <div>
                    c. Kami dapat memantau dan menganalisis aktivitas Anda, data
                    demografis, perilaku, permintaan pencarian, waktu respons
                    untuk berbagai layanan yang disediakan di Situs, dan Data
                    Pribadi apa pun yang Anda berikan ke Situs untuk mendukung
                    dan meningkatkan kinerja, pengalaman pengguna, pertumbuhan,
                    dan fungsi dari Situs.
                  </div>
                  <div>
                    d. Kami dapat menggunakan Data Pribadi Anda dan informasi
                    lainnya yang dikumpulkan dengan tujuan pemasaran Media
                    Sosial dengan menggunakan teknik grafik langsung dan terbuka
                    dan untuk tujuan pemasaran digital konvensional, seperti
                    mengirimkan Anda newsletter atau push notification, secara
                    otomatis, termasuk namun tidak terbatas melalui surat
                    elektronik, WhatsApp, serta melalui media sosial (seperti
                    YouTube, Instagram, Facebook, Twitter, Tiktok, Line, Tumblr,
                    Telegram, Reddit, Snapchat, LinkedIn, dan lain-lain) untuk
                    memberitahukan informasi produk baru, penawaran khusus,
                    program promosi, campaign atau informasi lainnya yang
                    menurut Kami akan menarik bagi Anda;
                  </div>
                  <div>
                    e. Segala informasi yang Kami terima dapat Kami gunakan
                    untuk melindungi diri Kami terhadap segala tuntutan dan
                    hukum yang berlaku terkait dengan penggunaan layanan dan
                    pelanggaran yang Anda lakukan pada website Kami atas segala
                    ketentuan sebagaimana diatur dalam persyaratan layanan
                    guehadir.id dan pedoman penggunaan produk dan layanan Kami,
                    termasuk dan tidak terbatas apabila dibutuhkan atas perintah
                    Pengadilan dalam proses hukum;
                  </div>
                  <div>
                    f. Kami dapat menggunakan Data Pribadi Anda untuk melakukan
                    investigasi terhadap transaksi-transaksi mencurigakan atau
                    transaksi yang terindikasi mengandung unsur kecurangan atau
                    pelanggaran terhadap persyaratan layanan guehadir.id dan
                    pedoman penggunaan produk dan layanan Kami maupun ketentuan
                    hukum yang berlaku, serta melakukan Tindakan-tindakan yang
                    perlu untuk menanggulanginya.
                  </div>
                  <div>
                    g. Segala informasi yang Kami terima dapat Kami gunakan pada
                    saat Kami melakukan transaksi penggabungan, penjualan aset
                    perusahaan, konsolidasi atau restrukturisasi, pembiayaan
                    atau akuisisi untuk perusahaan Kami.
                  </div>
                  <div>
                    h. Kami dapat mempublikasikan ulasan Anda tentang Layanan
                    Online dan/ atau produk Kami.
                  </div>
                  <div>
                    i. untuk tujuan lain apa pun yang kami beri tahu Anda saat
                    kami mengumpulkan Data Pribadi.
                  </div>
                </div>
              </div>
            </div>
            {/* Penggunaan Informasi Pribadi Anda End  */}

            {/* Pengungkapan Informasi Pribadi   */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Pengungkapan Informasi Pribadi
                  </div>
                </div>
                <ChevronDownIcon
                  id="pengungkapan"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.pengungkapan ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.pengungkapan
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2">
                  a. Kami dapat mengungkapkan atau membagikan Informasi Pribadi
                  Anda dengan afiliasi dan pihak lain untuk tujuan sebagai
                  berikut ini serta untuk tujuan lain yang diizinkan oleh
                  peraturan perundang-undangan yang berlaku:
                </div>
                <div className="mt-2 flex flex-col space-y-1">
                  <li>
                    o Jika Anda adalah Pengguna, untuk memungkinkan Penyedia
                    Tiket untuk memberikan layanan kepada Anda;
                  </li>
                  <li>
                    o Jika Anda adalah Penyedia Tiket, untuk memungkinkan
                    Pengguna menerima layanan dari Anda;
                  </li>
                  <li>
                    o Untuk tujuan pemrosesan pembayaran, Kami dapat
                    mengungkapkan Informasi Pribadi Anda seperti nomor kartu
                    kredit, nama yang tertera dalam kartu kredit Anda atau nomor
                    rekening yang Anda gunakan untuk pembayaran transaksi Anda
                    melalui Platform, kepada bank terkait atau bank penerbit
                    kartu kredit Anda;
                  </li>
                  <li>
                    o Jika disyaratkan atau diotorisasikan oleh peraturan
                    perundang-undangan yang berlaku (termasuk namun tidak
                    terbatas pada menanggapi pertanyaan dari badan atau otoritas
                    terkait regulasi, penyelidikan atau pedoman, atau mematuhi
                    persyaratan atau ketentuan pengarsipan dan pelaporan
                    berdasarkan undang-undang), untuk tujuan yang ditentukan
                    dalam peraturan perundang-undangan yang berlaku;
                  </li>
                  <li>
                    o Jika terdapat proses hukum dalam bentuk apapun antara Anda
                    dengan Kami, atau antara Anda dengan pihak lain, sehubungan
                    dengan, atau terkait dengan layanan, untuk keperluan proses
                    hukum tersebut;
                  </li>
                  <li>
                    o Sehubungan dengan segala proses verifikasi yang menurut
                    Kami atau Penyedia Tiket perlu sebelum Kami mendaftarkan
                    Anda sebagai Pengguna, termasuk proses Mengenal Pelanggan
                    (Know Your Customer);
                  </li>
                  <li>
                    o Dalam keadaan darurat terkait kesehatan dan/atau
                    keselamatan Anda (baik Anda adalah Pengguna atau Penyedia
                    Tiket) untuk keperluan menangani keadaan darurat tersebut;
                  </li>
                  <li>
                    o sehubungan dengan, penggabungan, penjualan aset
                    perusahaan, konsolidasi atau restrukturisasi, pembiayaan
                    atau akuisisi semua atau sebagian dari bisnis Kami oleh atau
                    ke perusahaan lain, untuk keperluan transaksi tersebut
                    (bahkan jika kemudian transaksi tidak dilanjutkan);
                  </li>
                  <li>
                    o Kepada pihak ketiga (termasuk agen, vendor, pemasok,
                    kontraktor, mitra, dan pihak lain yang memberikan layanan
                    kepada Kami atau Anda, melakukan tugas atas nama Kami, atau
                    pihak dengan siapa Kami mengadakan kerja sama komersial),
                    untuk atau sehubungan dengan tujuan di mana pihak ketiga
                    tersebut terlibat atau tujuan kerja sama Kami dengan pihak
                    ketiga tersebut (tergantung keadaannya), yang dapat mencakup
                    diperbolehkannya pihak ketiga tersebut untuk memperkenalkan
                    atau menawarkan produk atau layanan kepada Anda, atau
                    melakukan kegiatan lain termasuk pemasaran, penelitian,
                    analisis dan pengembangan produk;{" "}
                  </li>
                  <li>
                    o Dalam hal Kami berbagi Informasi Pribadi dengan afiliasi,
                    Kami akan melakukannya dengan maksud agar mereka membantu
                    Kami dalam menyediakan Platform, untuk mengoperasikan bisnis
                    Kami (termasuk, ketika Anda berlangganan milis Kami, untuk
                    tujuan pemasaran langsung), atau untuk tujuan pengolahan
                    data atas nama Kami. Misalnya, sebuah afiliasi Kami di
                    negara lain dapat mengolah dan/atau menyimpan Informasi
                    Pribadi Anda atas nama perusahaan grup Kami di negara Anda.
                    Semua afiliasi Kami berkomitmen untuk mengolah Informasi
                    Pribadi yang mereka terima dari Kami sesuai dengan Kebijakan
                    Privasi dan peraturan perundang-undangan yang berlaku ini.
                  </li>
                </div>
                <div className="mt-2">
                  b. Ketika Informasi Pribadi tidak perlu dikaitkan dengan Anda,
                  Kami akan melakukan upaya yang wajar untuk menghapus
                  dikaitkannya Informasi Pribadi tersebut dengan Anda sebagai
                  individu sebelum mengungkapkan atau berbagi informasi
                  tersebut.
                </div>
                <div className="mt-2">
                  c. Kami tidak akan menjual atau menyewakan Informasi Pribadi
                  Anda.
                </div>
                <div className="mt-2">
                  d. Selain sebagaimana diatur dalam Kebijakan Privasi ini, Kami
                  dapat mengungkapkan dan membagikan Informasi Pribadi Anda jika
                  Kami telah memberitahu Anda dan telah memperoleh persetujuan
                  Anda untuk pengungkapan atau pembagian tersebut.
                </div>
              </div>
            </div>
            {/* Pengungkapan Informasi Pribadi End  */}

            {/* Penyimpanan Informasi Pribadi   */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Penyimpanan Informasi Pribadi
                  </div>
                </div>
                <ChevronDownIcon
                  id="penyimpanan"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.penyimpanan ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.penyimpanan
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2">
                  a. Informasi Pribadi Anda hanya akan disimpan selama
                  diperlukan untuk memenuhi tujuan dari pengumpulannya, atau
                  selama penyimpanan tersebut diperlukan atau diperbolehkan oleh
                  peraturan perundang-undangan yang berlaku. Kami akan berhenti
                  menyimpan Informasi Pribadi, atau menghapus maksud dari
                  dikaitkannya Informasi Pribadi tersebut dengan Anda sebagai
                  individu, segera setelah dianggap bahwa tujuan pengumpulan
                  Informasi Pribadi tersebut tidak lagi dibutuhkan dengan
                  menyimpan Informasi Pribadi dan penyimpanan tidak lagi
                  diperlukan untuk tujuan bisnis atau secara hukum.
                </div>
                <div className="mt-2">
                  b. Mohon diperhatikan bahwa masih ada kemungkinan bahwa
                  beberapa Informasi Pribadi Anda disimpan oleh pihak lain
                  (termasuk oleh Penyedia Tiket jika Anda adalah Pengguna; atau
                  oleh Pengguna, jika Anda adalah Penyedia Tiket) dengan cara
                  tertentu. Informasi yang disampaikan melalui komunikasi antara
                  Pengguna dan Penyedia Tiket yang dilakukan selain melalui
                  penggunaan Platform (seperti melalui panggilan telepon, SMS,
                  pesan seluler atau cara komunikasi lainnya) juga dapat
                  disimpan dengan beberapa cara. Kami tidak mengizinkan dan
                  mendorong penyimpanan Informasi Pribadi dengan cara demikian
                  dan Kami tidak bertanggung jawab kepada Anda untuk hal
                  tersebut. Kami tidak akan bertanggung jawab atas penyimpanan
                  Informasi Pribadi Anda. Anda setuju untuk mengganti rugi,
                  membela, dan membebaskan Kami, pejabat, direktur, karyawan,
                  agen, mitra, pemasok, kontraktor, dan afiliasi Kami dari dan
                  terhadap setiap dan segala klaim, kerugian, kewajiban, biaya,
                  kerusakan, dan ongkos (termasuk tetapi tidak terbatas pada
                  biaya hukum dan pengeluaran biaya ganti rugi penuh) yang
                  dihasilkan secara langsung atau tidak langsung dari setiap
                  penyimpanan yang tidak sah atas Informasi Pribadi Anda.
                </div>
              </div>
            </div>
            {/* Penyimpaan Informasi Pribadi End  */}

            {/* Akses dan Kores Informasi Pribadi   */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Akses dan Koresi Informasi Pribadi
                  </div>
                </div>
                <ChevronDownIcon
                  id="akses"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.akses ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.akses
                    ? "max-h-[1000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2 flex flex-col space-y-2">
                  <div>
                    a. Tunduk pada peraturan perundang-undangan yang berlaku,
                    Anda dapat memperbarui Informasi Pribadi Anda kapan saja
                    dengan mengakses akun dalam Platform. Jika Anda tidak
                    memiliki akun dengan Kami, maka Anda dapat menghubungi Kami
                    pada alamat email di bawah.
                  </div>
                  <div>
                    b. Kami berhak mengambil langkah-langkah untuk berbagi
                    pembaruan Informasi Pribadi Anda dengan pihak ketiga dan
                    afiliasi Kami jika Informasi Pribadi Anda masih diperlukan
                    untuk tujuan tersebut di atas.
                  </div>
                  <div>
                    c. Jika Anda ingin melihat Informasi Pribadi Anda yang Kami
                    miliki atau menanyakan tentang Informasi Pribadi Anda yang
                    telah atau mungkin akan digunakan atau diungkapkan oleh Kami
                    dalam satu tahun terakhir, silahkan hubungi Kami di alamat
                    e-mail Kami di bawah. Kami berhak untuk membebankan biaya
                    administrasi yang wajar atas keperluan ini.
                  </div>
                  <div>
                    d. Jika Anda memiliki akun Platform, Anda dapat mengakses
                    rincian pesanan Anda dengan cara masuk ke akun Anda di
                    Platform. Di sini Anda dapat melihat rincian pesanan Anda
                    yang telah selesai, rincian yang masih terbuka, rincian yang
                    segera akan dikirim, dan pengelolaan alamat lengkap Anda,
                    rincian bank, dan setiap newsletter yang mungkin telah
                    berlangganan dengan Anda.
                  </div>
                </div>
              </div>
            </div>
            {/* Akses dan Koresi Informasi Pribadi End  */}

            {/* Keamanan Informasi Pribadi   */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Keamanan Informasi Pribadi
                  </div>
                </div>
                <ChevronDownIcon
                  id="keamanan"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.keamanan ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.keamanan
                    ? "max-h-[1000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2 flex flex-col space-y-2">
                  <div>
                    a. Kerahasiaan Informasi Pribadi Anda adalah hal yang
                    terpenting bagi Kami. Kami akan memberlakukan upaya terbaik
                    untuk melindungi dan mengamankan data dan Informasi Pribadi
                    Anda dari akses, pengumpulan, penggunaan atau pengungkapan
                    oleh orang-orang yang tidak berwenang dan dari pengolahan
                    yang bertentangan dengan hukum, kehilangan yang tidak
                    disengaja, pemusnahan dan kerusakan atau risiko serupa.
                    Namun, pengiriman informasi melalui internet tidak
                    sepenuhnya aman. Walau Kami akan berusaha sebaik mungkin
                    untuk melindungi Informasi Pribadi Anda, Anda mengakui bahwa
                    Kami tidak dapat menjamin keutuhan dan keakuratan Informasi
                    Pribadi apa pun yang Anda kirimkan melalui Internet, atau
                    menjamin bahwa Informasi Pribadi tersebut tidak akan
                    dicegat, diakses, diungkapkan, diubah atau dihancurkan oleh
                    pihak ketiga yang tidak berwenang, karena faktor-faktor di
                    luar kendali Kami. Anda bertanggung jawab untuk menjaga
                    rincian akun Anda termasuk username, password, rincian
                    pesanan Anda dan tidak memberikannya kepada pihak ketiga
                    yang tidak berwenang. Kami tidak memiliki kewajiban
                    menanggung penyalahgunaan username, password, dan rincian
                    pesanan Anda kecuali sebagaimana yang tercantum dalam
                    masing-masing Syarat & Ketentuan Platform. Anda juga harus
                    selalu menjaga dan bertanggung jawab atas keamanan perangkat
                    yang Anda gunakan.
                  </div>
                  <div>
                    b. Kami memastikan bahwa seluruh informasi yang dikumpulkan
                    tersimpan dengan aman. Kami menjaga Informasi Pribadi Anda
                    dengan cara:
                  </div>
                  <div className="mt-2 flex flex-col space-y-1">
                    <li>Membatasi akses ke informasi pribadi</li>
                    <li>
                      Mengikuti kemajuan teknologi pengamanan untuk mencegah
                      akses komputer tidak sah
                    </li>
                    <li>
                      Dengan aman menghilangkan Informasi Pribadi Anda ketika
                      tidak lagi digunakan untuk keperluan hukum atau bisnis.
                    </li>
                  </div>
                  <div>
                    c. Platform Kami menggunakan teknologi enkripsi 256-bit dan
                    2048-bit SSL (secure socket layer) saat memproses rincian
                    keuangan Anda. Teknologi enkripsi yang Kami gunakan
                    merupakan standar kemanan dalam industri.
                  </div>
                  <div>
                    d. Password Anda adalah kunci untuk masuk akun Anda. Silakan
                    gunakan nomor unik, huruf dan karakter khusus, dan jangan
                    berbagi password akun Anda kepada siapa pun. Jika Anda
                    berbagi password Anda dengan orang lain, Anda akan
                    bertanggung jawab untuk semua tindakan dan konsekuensi yang
                    diambil atas nama akun Anda. Jika Anda kehilangan kontrol
                    password Anda, Anda mungkin kehilangan kontrol atas
                    informasi pribadi Anda dan informasi lainnya yang
                    disampaikan dalam Platform. Anda juga bisa dikenakan
                    tindakan yang mengikat secara hukum yang diambil atas nama
                    Anda. Oleh karena itu, jika password Anda terganggu untuk
                    alasan apapun atau jika Anda memiliki alasan yang dapat
                    dipercaya bahwa password Anda telah terganggu, Anda harus
                    segera menghubungi Kami dan mengganti password Anda. Anda
                    diingatkan untuk selalu keluar (log off) dari akun Anda dan
                    menutup browser ketika selesai menggunakan komputer bersama.
                  </div>
                  <div>
                    e. Spam, spyware, atau virus tidak diperbolehkan dalam
                    Platform. Harap mengatur dan menjaga preferensi komunikasi
                    Anda sehingga Kami dapat mengirimkan komunikasi seperti yang
                    Anda inginkan. Anda tidak memiliki izin atau tidak diizinkan
                    untuk menambahkan Pengguna lain (bahkan pengguna yang telah
                    membeli item dari Anda) ke milis Anda (email atau surat
                    fisik) tanpa persetujuan mereka. Anda tidak boleh mengirim
                    pesan yang mengandung spam, spyware atau virus melalui
                    Platform. Jika Anda ingin melaporkan pesan yang
                    mencurigakan, silahkan hubungi Kami di alamat email Kami di
                    bawah.
                  </div>
                </div>
              </div>
            </div>
            {/* Keamanan Informasi Pribadi End  */}

            {/* Perubahan Kebijakan Privasi   */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Perubahan Kebijakan Privasi
                  </div>
                </div>
                <ChevronDownIcon
                  id="perubahan"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.perubahan ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.perubahan
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2 flex flex-col space-y-2">
                  <div>
                    Kami dapat secara berkala meninjau dan mengubah Kebijakan
                    Privasi ini dari waktu ke waktu atas kebijakan Kami sendiri
                    dari waktu ke waktu, untuk memastikan bahwa Kebijakan
                    Privasi ini konsisten dengan perkembangan Kami di masa
                    depan, dan/atau perubahan persyaratan hukum atau peraturan.
                    Setiap perubahan kebijakan ini akan dipublikasikan pada
                    Platform atau melalui alamat e-mail yang tercantum dalam
                    akun Anda. Anda setuju bahwa Anda bertanggung jawab untuk
                    meninjau Kebijakan Privasi ini secara teratur untuk
                    informasi terbaru tentang pengolahan data dan praktik
                    perlindungan data Kami, dan bahwa dengan Anda terus
                    menggunakan Platform atau situs web Kami, berkomunikasi
                    dengan Kami, atau mengakses dan menggunakan layanan setelah
                    adanya perubahan apapun terhadap Kebijakan Privasi ini akan
                    dianggap sebagai persetujuan Anda terhadap Kebijakan Privasi
                    ini dan segala perubahannya.
                  </div>
                </div>
              </div>
            </div>
            {/* Perubahan Kebijakan Privasi End  */}

            {/* Pengakuan dan Persetujuan   */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Pengakuan dan Persetujuan
                  </div>
                </div>
                <ChevronDownIcon
                  id="persetujuan"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.persetujuan ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.persetujuan
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2 flex flex-col space-y-2">
                  <div>
                    a. Dengan menyetujui Kebijakan Privasi, Anda mengakui bahwa
                    Anda telah membaca dan memahami Kebijakan Privasi ini dan
                    menyetujui segala ketentuannya. Secara khusus, Anda setuju
                    dan memberikan persetujuan kepada Kami untuk mengumpulkan,
                    menggunakan, membagikan, mengungkapkan, menyimpan,
                    mentransfer, dan/atau mengolah Informasi Pribadi Anda sesuai
                    dengan Kebijakan Privasi ini.
                  </div>
                  <div>
                    b. Dalam keadaan di mana Anda memberikan kepada Kami
                    Informasi Pribadi yang berkaitan dengan individu lain
                    (seperti Informasi Pribadi yang berkaitan dengan pasangan
                    Anda, anggota keluarga, teman, atau pihak lain), Anda
                    menyatakan dan menjamin bahwa Anda telah memperoleh
                    persetujuan dari individu tersebut untuk, dan dengan ini
                    menyetujui atas nama individu tersebut untuk, pengumpulan,
                    penggunaan, pengungkapan dan pengolahan Informasi Pribadi
                    mereka oleh Kami.
                  </div>
                  <div>
                    c. Anda dapat menarik persetujuan Anda untuk setiap atau
                    segala pengumpulan, penggunaan atau pengungkapan Informasi
                    Pribadi Anda kapan saja dengan memberikan kepada Kami
                    pemberitahuan yang wajar secara tertulis menggunakan rincian
                    kontak yang disebutkan di bawah ini. Anda juga dapat menarik
                    persetujuan pengiriman komunikasi dan informasi tertentu
                    dari Kami melalui fasilitas "opt-out" atau pilihan "berhenti
                    berlangganan" yang tersedia dalam pesan Kami kepada Anda.
                    Tergantung pada keadaan dan sifat persetujuan yang Anda
                    tarik, Anda harus memahami dan mengakui bahwa setelah
                    penarikan persetujuan tersebut, Anda mungkin tidak lagi
                    dapat menggunakan Platform atau layanan. Penarikan
                    persetujuan Anda dapat mengakibatkan penghentian Akun Anda
                    atau hubungan kontraktual Anda dengan Kami, dengan semua hak
                    dan kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah
                    menerima pemberitahuan untuk menarik persetujuan untuk
                    pengumpulan, penggunaan atau pengungkapan Informasi Pribadi
                    Anda, Kami akan menginformasikan Anda tentang konsekuensi
                    yang mungkin terjadi dari penarikan tersebut sehingga Anda
                    dapat memutuskan apakah Anda tetap ingin menarik
                    persetujuan.
                  </div>
                </div>
              </div>
            </div>
            {/* Perubahan Kebijakan Privasi End  */}

            {/* Materi Pemasaran dan Promosi   */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Materi Pemasaran dan Promosi
                  </div>
                </div>
                <ChevronDownIcon
                  id="materi"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.materi ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.materi
                    ? "max-h-[1000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2 flex flex-col space-y-2">
                  <div>
                    Anda memberikan persetujuan kepada Kami dan afiliasi atau
                    pihak yang bekerja sama dengan Kami untuk dapat mengirimkan
                    Anda pemasaran langsung, iklan, dan komunikasi promosi
                    melalui aplikasi push-notification, pesan melalui Platform,
                    pos, panggilan telepon, layanan pesan singkat (SMS), dan
                    email (“Materi Pemasaran”). Anda dapat memilih untuk tidak
                    menerima komunikasi pemasaran tersebut kapan saja dengan
                    mengklik pilihan “berhenti berlangganan” yang ada dalam
                    pesan yang bersangkutan, atau menghubungi Kami melalui
                    detail kontak yang tercantum di bawah ini. Mohon perhatikan
                    bahwa jika Anda memilih untuk keluar, Kami masih dapat
                    mengirimi Anda pesan-pesan non-promosi, seperti tanda terima
                    atau informasi tentang Akun Anda.
                  </div>
                </div>
              </div>
            </div>
            {/* Materi End  */}

            {/* Platform Pihak Ketiga  */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Platform Pihak Ketiga
                  </div>
                </div>
                <ChevronDownIcon
                  id="platform"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.platform ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.platform
                    ? "max-h-[1000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2 flex flex-col space-y-2">
                  <div>
                    Platform, situs web, dan Materi Pemasaran dapat berisi
                    tautan ke situs web yang dioperasikan oleh pihak ketiga.
                    Kami tidak mengendalikan atau menerima pertanggungjawaban
                    atau tanggung jawab untuk situs web ini dan untuk
                    pengumpulan, penggunaan, pemeliharaan, berbagi, atau
                    pengungkapan data dan informasi oleh pihak ketiga tersebut.
                    Silakan baca syarat dan ketentuan dan kebijakan privasi dari
                    situs web pihak ketiga tersebut untuk mengetahui bagaimana
                    mereka mengumpulkan dan menggunakan Informasi Pribadi Anda.
                    Iklan yang terdapat pada Platform dan Materi Pemasaran Kami
                    berfungsi sebagai tautan ke situs web pengiklan dan dengan
                    demikian segala informasi yang mereka kumpulkan berdasarkan
                    klik Anda pada tautan itu akan dikumpulkan dan digunakan
                    oleh pengiklan yang relevan sesuai dengan kebijakan privasi
                    pengiklan tersebut.
                  </div>
                </div>
              </div>
            </div>
            {/* Materi End  */}

            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Pengajuan Penghapusan Akun
                  </div>
                </div>
                <ChevronDownIcon
                  id="pengajuanhapusakun"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.pengajuanhapusakun ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>
              <div
                className={`${
                  dropdownList.pengajuanhapusakun
                    ? "max-h-[1000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC]`}
              >
                <div className="mt-2 flex flex-col space-y-2">
                  <div>
                    1. Informasi pribadi yang tersimpan di GueHadir dapat dihapus dengan menghubungi kami melalui email <a href="mailto:cs@guehadir.id?Subject=Pengajuan Penghapusan Akun" target="_blank"><u className="text-blue-600">cs@guehadir.id</u></a>.<br></br>
                    2. Ketika mengajukan permintaan penghapusan akun, tim GueHadir akan melakukan pemeriksaan untuk memastikan apakah kustomer memiliki tiket untuk acara yang belum berlangsung. Jika ada, kustomer diminta untuk menunda penghapusan akun dan menghubungi kami beberapa hari setelah acara selesai.<br></br>
                    3. Saat mengajukan permintaan penghapusan akun, tim GueHadir akan memeriksa apakah kustomer memiliki foto-foto yang telah dibeli tetapi belum diunduh. Jika ada, kustomer diminta untuk mengunduh semua foto yang telah dibeli sebelum melanjutkan proses penghapusan.<br></br>
                    4. Saat mengajukan permintaan penghapusan akun, tim GueHadir akan melakukan validasi identitas kustomer untuk memastikan kesesuaian dengan identitas yang terdaftar di GueHadir.<br></br>
                    5. Penghapusan informasi pribadi melalui tim GueHadir adalah salah satu langkah yang kami lakukan untuk memastikan bahwa permintaan datang dari orang yang berwenang serta untuk memastikan bahwa semua acara yang telah dibeli oleh kustomer telah ditonton dan semua foto yang telah dibeli telah diunduh. Harap diketahui bahwa proses penghapusan informasi pribadi melalui tim GueHadir dirancang untuk menjaga privasi dan keamanan data kustomer. Kami berkomitmen untuk memenuhi kewajiban kami dalam melindungi informasi pribadi kustomer sesuai dengan hukum yang berlaku.
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
}

export default KebijakanPrivasi;
