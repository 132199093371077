export function DetailNews() {
  return (
    <div className="p-5 lg:p-12 bg-semiblack text-white flex flex-col">
      <div className="hidden lg:block lg:line-clamp-1 capitalize">
        <div className="animate-pulse w-32 h-5 rounded-sm bg-zinc-600"></div>
      </div>
      <div className="animate-pulse w-[55%] h-8 rounded-sm mt-3 bg-zinc-600"></div>
      <div className="flex items-center space-x-4 mt-5">
        <div className="animate-pulse w-44 h-5 rounded-sm bg-zinc-600"></div>
      </div>
      <div className="mt-5">
        <div className="animate-pulse w-full h-[630px] bg-zinc-600 rounded-md"></div>
      </div>
      <div className=" mt-5 animate-pulse w-32 h-5 rounded-sm bg-zinc-600"></div>
      <div className=" mt-5 animate-pulse w-full h-5 rounded-sm bg-zinc-600"></div>
      <div className="mt-3 animate-pulse w-80 h-5 rounded-sm bg-zinc-600"></div>
    </div>
  )
}
