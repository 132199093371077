import NewsDetail from "../pages/newsDetail"
import EventList, { EventListLoader } from "../pages/eventList"
import NewsList from "../pages/newsList"
import Profile from "../pages/profile"
import Faq from "../pages/faq"
import SyaratDanKetentuan from "../pages/syaratDanKetentuan"
import KebijakanPrivasi from "../pages/kebijakanPrivasi"
import TentangKami from "../pages/tentangKami"
// import Dummy from "../pages/Dummy";
import ResetPassword from "../pages/ResetPassword"
import { PhotoListPage, PhotoListLoader } from "../pages/PhotoListPage"
import LandingPage, { LandingPageLoader } from "../pages/landingPage"
import EventDetail from "../pages/eventDetail"
import Payment from "../pages/payment"
import PaymentStatus from "../pages/paymentStatus"
import ErrorPage from "../pages/ErrorPage"
import { createBrowserRouter } from "react-router-dom"
import { EventPhotoLoader, EventPhotoPage } from "../pages/EventPhotoPage"
import { DetailPhoto, DetailPhotoLoader } from "../pages/DetailPhoto"
import PhotoError from "../pages/errors/PhotoError"

import FormPartaiPage from "../pages/formPartai"

import {
  PhotoCollectionLoader,
  PhotoCollectionPage,
} from "../pages/PhotoCollection"

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    loader: LandingPageLoader,
  },
  {
    path: "/event/:id/",
    element: <EventDetail />,
  },
  {
    path: "/event/:id/:eventName",
    element: <EventDetail />,
  },
  {
    path: "/payment/:paymentid",
    element: <Payment />,
  },
  {
    path: "/event/:id/payment/:paymentid/status",
    element: <PaymentStatus />,
  },
  {
    path: "/news/:id",
    element: <NewsDetail />,
  },
  {
    path: "event/all",
    element: <EventList />,
    loader: EventListLoader,
  },
  {
    path: "news/all",
    element: <NewsList />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/profile/:section",
    element: <Profile />,
  },
  {
    path: "/profile/:section/:subsection",
    element: <Profile />,
  },
  {
    path: "photos",
    element: <PhotoListPage />,
    loader: PhotoListLoader,
  },
  {
    path: "photos/:id/details",
    element: <DetailPhoto />,
    loader: DetailPhotoLoader,
    errorElement: <PhotoError />,
  },
  {
    path: "photos/your-collection",
    element: <PhotoCollectionPage />,
    loader: PhotoCollectionLoader,
    errorElement: <PhotoError />,
  },
  {
    path: "photos/:eventSlug/event",
    element: <EventPhotoPage />,
    loader: EventPhotoLoader,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/syarat-dan-ketentuan",
    element: <SyaratDanKetentuan />,
  },
  {
    path: "/kebijakan-privasi",
    element: <KebijakanPrivasi />,
  },
  {
    path: "/tentang-kami",
    element: <TentangKami />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/hutknpi50tahun",
    element: <FormPartaiPage />,
  },
])

export default router
