import React from "react"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { RouterProvider } from "react-router-dom"
import router from "./router"
import { StoreProvider, useStoreActions } from "easy-peasy"
import store from "./store"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import ErrorPage from "./pages/ErrorPage"

function App() {
  const hydrateLogin = useStoreActions((action) => action.hydrateLogin)

  React.useEffect(() => {
    hydrateLogin()
  }, [hydrateLogin])
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LesDSIkAAAAAM2lOe3vxsyaw9tjQxeBT1gJKsqj"
      container={{
        parameters: {
          badge: "bottomleft", // optional, default undefined
          theme: "dark", // optional, default undefined
        },
      }}
    >
      <StoreProvider store={store}>
        <ErrorPage />
        <RouterProvider router={router} />
      </StoreProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App
