export function DetailPhotoSection2() {
  return (
    <div className="flex justify-between xl:mx-0 lg:mx-0 md:mx-0 sm:mx-4 mx-4 xl:flex-row lg:flex-row md:flex-row sm:flex-row flex-col lg:items-center">
      <div className="flex flex-col">
        <div className="w-56 bg-zinc-700 h-5 animate-pulse rounded-sm" />
        <div className="w-40 mt-2 bg-zinc-700 h-5 animate-pulse rounded-sm" />
        <div className="w-28 mt-2 bg-zinc-700 h-5 animate-pulse rounded-sm" />
      </div>
      <div className="flex flex-row gap-[8px] items-center xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0 mt-5">
        <div className="w-28 bg-zinc-700 h-10 animate-pulse rounded-sm" />
        <div className="w-10 bg-zinc-700 h-10 animate-pulse rounded-sm" />
      </div>
    </div>
  )
}
