export const GHButton = (props) => (
  <button
    {...props}
    className={`${props.className} px-[10px] xl:px-[30px] py-[3px] md:py-[5px] bg-primary hover:bg-primary-hover 
    active:bg-primary-pressed border-4 border-opacity-0 border-transparent focus:border-opacity-100 focus:border-4 
    focus:border-primary-border focus:box-border box-border text-black font-semibold rounded-[5px] text-sm leading-relaxed
    disabled:bg-background disabled:text-t-primary-disabled disabled:border-opacity-100 disabled:border-2 disabled:border-b-primary-disabled`}
  >
    {props.children}
  </button>
)
