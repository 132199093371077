import { Helmet } from "react-helmet"
import { useLoaderData, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import Layout from "../components/layout"
import PhotoGrid from "../components/PhotoGrid"
import { useStoreState } from "easy-peasy"
import axios from "axios"
import { GHButton } from "../components/GHSystem"
import { AllPhotosEvent } from "../components/partials"

export const EventPhotoLoader = async ({ request, params }) => {
  const eventSlug = params.eventSlug
  try {
    const reqArray = [
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/apps/photos/event/${eventSlug}?limit=20`,
        {
          timeout: 2000,
        }
      ),
    ]
    const result = await Promise.all(reqArray)
    let tagsSet = new Set()
    tagsSet.add("")
    result[0].data.data.row.forEach((value, index) => {
      if (value.photo_tags.length) {
        for (let i = 0; i < value.photo_tags.length; i++) {
          const element = value.photo_tags[i]
          tagsSet.add(element.tag.name)
        }
      }
    })
    return {
      eventTitle: result[0].data.data.row[0].event.title,
      photos: result[0].data.data.row,
      next: result[0].data.data.next,
      eventSlug,
      tags: Array.from(tagsSet),
    }
  } catch (error) {
    return {
      eventTitle: "",
      photos: [],
      next: null,
      eventSlug,
      tags: [""],
    }
  }
}

export const EventPhotoPage = () => {
  const loaderData = useLoaderData()
  const { eventTitle, photos, next, eventSlug, tags } = loaderData
  const [hasMore, setHasMore] = useState(next)
  const [infinitePhoto, setInfinitePhoto] = useState(photos)
  const [filter, setFilter] = useState("")
  const [isSkeleton, setIsSkeleton] = useState(true)

  const fetchNext = async () => {
    const photos = await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/apps/photos/event/${eventSlug}${hasMore}`,
        {
          timeout: 2000,
        }
      )
      .catch((err) => err)
    if (photos.data) {
      setInfinitePhoto((old) => [...old, ...photos.data.data.row])
      setHasMore(photos.data.data.next)
    } else {
      setHasMore(false)
    }
  }

  useEffect(() => {
    if (photos.length) {
      setIsSkeleton(false)
    }
  })

  return (
    <Layout showTopButton>
      <div className="h-full w-full flex flex-col items-center">
        <Helmet>
          <title>Photos | Gue Hadir</title>
          <meta name="description" content={`Gue Hadir Photos`} />
          <meta property="og:title" content="Photos | Gue Hadir" />
          <meta
            property="og:description"
            content="guehadir.id memberikan pengalaman berbeda saat melakukan pembelian tiket. Selalu ada keuntungan di setiap pembeliannya"
          />
          <meta
            property="og:image"
            content="https://backend.guehadir.id/images/banner1.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Gue Hadir" />
          <meta property="og:url" content="https://guehadir.id" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="guehadir.id" />
          <meta property="twitter:url" content="https://guehadir.id" />
          <meta name="twitter:title" content="Photos | Gue Hadir" />
          <meta
            name="twitter:description"
            content="guehadir.id memberikan pengalaman berbeda saat melakukan pembelian tiket. Selalu ada keuntungan di setiap pembeliannya"
          />
          <meta
            name="twitter:image"
            content="https://backend.guehadir.id/images/banner1.png"
          />
        </Helmet>
        {isSkeleton ? (
          <AllPhotosEvent />
        ) : (
          <div className="container flex flex-col py-6 space-y-4 px-4 lg:px-0">
            <p className="line-clamp-2 font-montserrat font-semibold text-2xl text-secondary">
              {`Photo ${eventTitle}`}
            </p>
            <div className="py-2 flex space-x-4">
              {tags.map((tag) => {
                if (tag === "") {
                  return (
                    <RenderTag
                      onClick={() => setFilter(tag)}
                      isActive={filter === tag}
                    >
                      Semua
                    </RenderTag>
                  )
                }
                return (
                  <RenderTag
                    onClick={() => setFilter(tag)}
                    isActive={filter === tag}
                  >
                    {tag}
                  </RenderTag>
                )
              })}
            </div>
            <PhotoGrid
              className="lg:pt-8 pb-8"
              photos={infinitePhoto.filter((item) => {
                if (filter === "") {
                  return true
                }
                return item.photo_tags.some((tags) => {
                  return tags.tag.name.includes(filter)
                })
              })}
            />
            {hasMore && (
              <div className="flex justify-center">
                <GHButton onClick={fetchNext}>LOAD MORE</GHButton>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

const RenderTag = ({ children, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={` ${
        isActive ? "border-primary" : "border-b-primary-disabled"
      } border-2 cursor-pointer p-2 text-secondary rounded-[4px] capitalize`}
    >
      {children}
    </div>
  )
}
