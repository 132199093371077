import { CalendarDaysIcon, TicketIcon } from "@heroicons/react/24/outline";
import React from "react";

function UnpaidTicket({ code, title, date, ticket, expiredDate, price }) {
  return (
    <div className="bg-background">
      <div className="relative flex items-center justify-between border-b border-dashed-4 border-primary">
        <div>Kode Pesanan</div>
        <div>{code}</div>
        <div className="rounded-full h-[2rem] w-[2rem] bg-black absolute left-0 z-[999] bottom-[-20%]"></div>
        <div className="rounded-full h-[2rem] w-[2rem] bg-black absolute left-0 z-[999] right-[-20%]"></div>
      </div>
      <div className="text-[1.15rem] font-semibold mt-3">{title}</div>
      <div className="flex items-center space-x-2 text-primary mt-3">
        <CalendarDaysIcon className="h-[1rem] w-auto" />
        <div>{date}</div>
        <div className="text-[#202020]">|</div>
        <TicketIcon className="h-[1rem] w-auto" />
        <div>{ticket} Tiket</div>
      </div>
      <div className="mt-3 bg-[#202020] h-[1px] w-full"></div>
      <div className="my-3">
        <div>
          Please complete this payment before <span>{expiredDate}</span>
        </div>
      </div>
      <div className="bg-[#202020] h-[1px] w-full"></div>
      <div className="flex items-center justify-between">
        <div>{price}</div>
        <div className="bg-primary p-2 px-5 rounded-md text-black font-semibold">
          Bayar
        </div>
      </div>
    </div>
  );
}

export default UnpaidTicket;
