export const ModalDetail = ({ title, children }) => {
  return (
    <div className="fixed h-full w-full z-50 flex justify-center items-center backdrop-blur-sm bg-black bg-opacity-25 ">
      <div
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        className="py-32 max-h-full overflow-y-scroll rounded-lg right-0 left-0 top-0 bottom-0 z-50  scrollbar-none "
      >
        <div className="flex w-[742px] pointer-events-none">
          <div
            style={{ boxShadow: "3px 8px 19px rgba(0, 0, 0, 0.44)" }}
            className="modal-content gap-[17px] px-[24px] py-[24px] slide-in-up transition-transform xl:w-full md:w-full lg:w-screen sm:w-screen w-screen max-h-fit border-none shadow-lg relative flex flex-col pointer-events-auto bg-[#191919] bg-clip-padding rounded-md outline-none text-current"
          >
            <div className="flex flex-col border-b-[1px] border-[#434343] pb-[17px] gap-[17px]">
              <h5 className="text-[18px] font-[600] leading-normal font-nunito text-[#DCDCDC]">
                {title}
              </h5>
            </div>

            {/* start order summary*/}
            <div className="flex flex-col gap-[17px]">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
