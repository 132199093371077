import React, { useState } from "react"
import ListImages from "../assets"
import axios from "axios"
import { GHButton } from "../components/GHSystem"
import Layout from "../components/layout"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { toast } from "react-toastify"

function FormPartaiPage() {
  const [name, setName] = useState("")
  const [noWA, setNoWA] = useState()
  const [LokasiOPL, setLokasiOPL] = useState("")
  const [isViewSukses, setIsViewSukses] = useState(false)

  const handleSubmitForm = () => {
    const formData = new FormData()

    if (name && noWA && LokasiOPL) {
      const handlePostRequest = async () => {
        formData.append("typerequest", "senddb")
        formData.append("nama", name)
        formData.append("nohp", noWA)
        formData.append("lokasiopl", LokasiOPL)

        try {
          const response = await axios.post(
            "https://www.nle.co.id/fetchguehadir",
            formData
          )

          if (response.data === "Sukses") {
            setIsViewSukses(true)
          } else if (response.data === "Double") {
            toast.error(
              "Mohon maaf, nama atau nomor hp yang anda input telah terdaftar!"
            )
          } else {
            toast.error(response.data + "daftar")
          }
        } catch (error) {
          toast.error(error)
        }
      }

      return handlePostRequest()
    } else {
      toast.error("Data yang diisi harus lengkap")
    }
  }

  return (
    <Layout>
      {isViewSukses ? (
        <div className="py-5 bg-gh-black-100 flex justify-center items-center">
          <div className="container">
            <div className="flex items-center justify-center">
              <img
                className="w-[500px] rounded-md"
                src={ListImages.BannerPartaiSukses}
                alt=""
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="py-5 bg-gh-black-100 flex justify-center ">
          <div className="container">
            <div className="relative">
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={80}
                slidesPerView={1}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 3000,
                }}
                className="pb-12 md:pb-0 px-6 lg:px-20 "
              >
                {/* TODO: swiper navigation  */}

                <SwiperSlide>
                  <img
                    className="w-full max-h-[20rem] lg:h-auto lg:max-h-full rounded-md"
                    src={ListImages.BannerPartai}
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
              {/* Search For Tablet & Desktop  */}

              <div className="font-bold text-2xl flex text-white xl:mt-10 lg:mt-10 md:mt-5 sm:mt-0 mt-0 px-10">
                Silahkan Registrasi
              </div>
              <div className="grid text-white grid-cols-1 md:grid-cols-2 gap-5 mt-12 w-full px-10">
                <div className="flex flex-col space-y-3">
                  <label>Nama Lengkap</label>
                  <input
                    onChange={(e) => setName(e.currentTarget.value)}
                    type="text"
                    className="bg-background p-3 rounded-md w-full outline-none"
                    value={name}
                  />
                </div>
                <div className="flex flex-col space-y-3">
                  <label>Nomor Whatsapp</label>
                  <input
                    onChange={(e) => setNoWA(e.currentTarget.value)}
                    type="number"
                    inputMode="numeric"
                    className="bg-background p-3 rounded-md w-full outline-none"
                    value={noWA}
                  />
                </div>
              </div>
              <div className="flex px-10 text-white mt-7 flex-col space-y-3">
                <label>Asal OKP</label>
                <input
                  onChange={(e) => setLokasiOPL(e.currentTarget.value)}
                  type="text"
                  className="bg-background p-3 rounded-md w-full outline-none"
                  value={LokasiOPL}
                />
              </div>
              <div className="flex justify-end mt-10 px-10">
                <GHButton
                  onClick={handleSubmitForm}
                  data-toggle-popover="masuk"
                >
                  Submit Form
                </GHButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default FormPartaiPage
