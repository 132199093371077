export function SkeletonAllEvent() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
      {/* card1 */}
      <div
        className={`bg-[#191919] rounded-md flex flex-col overflow-hidden cursor-pointer`}
        style={{ boxShadow: "0 0 10px black" }}
      >
        <div className="animate-pulse w-full h-[150px] rounded-sm bg-zinc-700"></div>
        <div className="flex flex-1 space-x-3 px-4 py-3">
          <div className="flex flex-col items-center uppercase px-[14px] ">
            <div className="animate-pulse w-5 h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-7 mt-1 h-7 rounded-sm bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-1 space-y-2">
            <div className="animate-pulse w-full h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-32 h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-20 h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-16 h-10 rounded-sm bg-zinc-700"></div>
            <div className="flex items-center justify-between">
              <div>
                <div className="animate-pulse w-20 h-5 rounded-sm bg-zinc-700"></div>
                <span className="font-semibold">
                  <div className="animate-pulse w-7 mt-1 h-5 rounded-sm bg-zinc-700"></div>
                </span>{" "}
              </div>
              <div className="animate-pulse w-28 h-14 bg-zinc-700 rounded-md" />
            </div>
          </div>
        </div>
      </div>
      {/* endcard1 */}
      {/* card1 */}
      <div
        className={`bg-[#191919] rounded-md flex flex-col overflow-hidden cursor-pointer`}
        style={{ boxShadow: "0 0 10px black" }}
      >
        <div className="animate-pulse w-full h-[150px] rounded-sm bg-zinc-700"></div>
        <div className="flex flex-1 space-x-3 px-4 py-3">
          <div className="flex flex-col items-center uppercase px-[14px] ">
            <div className="animate-pulse w-5 h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-7 mt-1 h-7 rounded-sm bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-1 space-y-2">
            <div className="animate-pulse w-full h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-32 h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-20 h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-16 h-10 rounded-sm bg-zinc-700"></div>
            <div className="flex items-center justify-between">
              <div>
                <div className="animate-pulse w-20 h-5 rounded-sm bg-zinc-700"></div>
                <span className="font-semibold">
                  <div className="animate-pulse w-7 mt-1 h-5 rounded-sm bg-zinc-700"></div>
                </span>{" "}
              </div>
              <div className="animate-pulse w-28 h-14 bg-zinc-700 rounded-md" />
            </div>
          </div>
        </div>
      </div>
      {/* endcard1 */}
      {/* card1 */}
      <div
        className={`bg-[#191919] rounded-md flex flex-col overflow-hidden cursor-pointer`}
        style={{ boxShadow: "0 0 10px black" }}
      >
        <div className="animate-pulse w-full h-[150px] rounded-sm bg-zinc-700"></div>
        <div className="flex flex-1 space-x-3 px-4 py-3">
          <div className="flex flex-col items-center uppercase px-[14px] ">
            <div className="animate-pulse w-5 h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-7 mt-1 h-7 rounded-sm bg-zinc-700"></div>
          </div>
          <div className="flex flex-col flex-1 space-y-2">
            <div className="animate-pulse w-full h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-32 h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-20 h-5 rounded-sm bg-zinc-700"></div>
            <div className="animate-pulse w-16 h-10 rounded-sm bg-zinc-700"></div>
            <div className="flex items-center justify-between">
              <div>
                <div className="animate-pulse w-20 h-5 rounded-sm bg-zinc-700"></div>
                <span className="font-semibold">
                  <div className="animate-pulse w-7 mt-1 h-5 rounded-sm bg-zinc-700"></div>
                </span>{" "}
              </div>
              <div className="animate-pulse w-28 h-14 bg-zinc-700 rounded-md" />
            </div>
          </div>
        </div>
      </div>
      {/* endcard1 */}
    </div>
  )
}
