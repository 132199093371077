import { useState } from "react";
import { toast } from "react-toastify";
import Background from "../assets/background-1.jpg";
import Icon from "../assets/vuesaxIcon";
import { GHButton } from "../components/GHSystem";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { catchAxiosError, getAxiosConfig } from "../utils";
import ListImages from "../assets";
import Layout from "../components/layout";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const togglePasswordVisibility = () => setShowPassword((oldVal) => !oldVal);
  const onSubmit = () => {
    if (!password || !confirmPassword) {
      return toast("Harap lengkapi form");
    }
    if (password.length < 8) {
      return toast("Password harus terdiri dari 8 karakter atau lebih");
    }
    if (password !== confirmPassword) {
      return toast("Password tidak sama");
    }
    setIsSubmitting(true);
    const data = {
      email: searchParams.get("email"),
      challenge: searchParams.get("challenge"),
      new_password: password,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/apps/reset-password`,
        data,
        getAxiosConfig()
      )
      .then((res) => {
        if (res.data) {
          if (res.data.success) {
            toast(res.data.message);
            setSuccess(true);
          }
        }
      })
      .catch(catchAxiosError)
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <Layout>
      <div className="min-h-[500px] overflow-hidden w-full relative">
        <div className=" h-full absolute z-10 w-full flex justify-center">
          <div className="container mt-[7%] flex flex-col items-center">
            <div className="text-[#DCDCDC] absolute p-6 bg-gh-black-200 rounded-[10px] flex flex-col gap-6 max-w-[406px]">
              {success ? (
                <>
                  <div>
                    <p className="font-[montserrat] font-semibold text-[20px] p-0 m-0 pb-2">
                      Kata Sandi Berhasil Dirubah
                    </p>
                  </div>
                  <img
                    src={ListImages.Illustration3}
                    alt=""
                    className="max-w-[225px] self-center"
                  />
                  <GHButton onClick={() => navigate("/")}>Masuk</GHButton>
                </>
              ) : (
                <>
                  <div>
                    <p className="font-[montserrat] font-semibold text-[20px] p-0 m-0 pb-2">
                      Buat Kata Sandi Baru
                    </p>
                    <p className="text-[14px] p-0 m-0">
                      Kata sandi barumu harus berbeda dari kata sandi sebelumnya
                      ya!
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <div className="relative flex justify-end items-center mb-[6.5px]">
                      {showPassword ? (
                        <Icon.EyeSlash
                          stroke="#FFF"
                          onClick={togglePasswordVisibility}
                          className="absolute [&>*]:stroke-[#707070] z-10 mr-2 stroke cursor-pointer "
                        />
                      ) : (
                        <Icon.Eye
                          stroke="#FFF"
                          onClick={togglePasswordVisibility}
                          className="absolute [&>*]:stroke-[#707070] z-10 mr-2 stroke cursor-pointer "
                        />
                      )}
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        placeholder="Kata Sandi"
                        className="p-2 rounded-[4px] flex-1 "
                      />
                    </div>
                    <p className="text-[12px]">
                      *Kata sandi harus terdiri dari 10 karakter
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <div className="relative flex justify-end items-center mb-[6.5px]">
                      {showPassword ? (
                        <Icon.EyeSlash
                          stroke="#FFF"
                          onClick={togglePasswordVisibility}
                          className="absolute [&>*]:stroke-[#707070] z-10 mr-2 stroke cursor-pointer "
                        />
                      ) : (
                        <Icon.Eye
                          stroke="#FFF"
                          onClick={togglePasswordVisibility}
                          className="absolute [&>*]:stroke-[#707070] z-10 mr-2 stroke cursor-pointer "
                        />
                      )}
                      <input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        placeholder="Konfirmasi Kata Sandi"
                        className="p-2 rounded-[4px] flex-1 "
                      />
                    </div>
                    <p className="text-[12px]">*Kedua kata sandi harus sama</p>
                  </div>
                  <GHButton disabled={isSubmitting} onClick={onSubmit}>
                    Atur Ulang Sandi
                  </GHButton>
                </>
              )}
            </div>
          </div>
        </div>
        <img
          className=" min-h-[500px] w-full z-0 object-cover object-center"
          src={Background}
          alt=""
          srcset=""
        />
      </div>
    </Layout>
  );
};
export default ResetPassword;
